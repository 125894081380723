export const BannerType = {
  BUNDLE: "BUNDLE",
  CYBER_MONDAY: "CYBER_MONDAY",
  NEW_YEARS: "NEW_YEARS",
  GIFT: "GIFT",
  IDME: "IDME",
  PODCAST: "PODCAST",
  WELCOME: "WELCOME",
  BUNDLE_EXISTING: "BUNDLE_EXISTING",
};
