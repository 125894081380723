// Store
import { getStore } from "../store/createStore";
import planSelectors from "../store/plan/selectors";
import productSelectors from "../store/product/selectors";
import staticDataSelectors from "../store/staticData/selectors";

// Utils
import NameMap from "./nameMap";

export function getPlanForId(planId) {
  const state = getStore().getState();
  return planSelectors.planForId(state, { id: planId });
}

export function getProductSkuForPlanId(planId) {
  const state = getStore().getState();
  const product = planSelectors.productForPlanId(state, { id: planId });
  return product ? product.sku : "";
}

export function getProductIdForSku(sku) {
  const state = getStore().getState();
  const product = productSelectors.productForSku(state, {
    sku,
  });
  return product ? product.id : "";
}

export function getPlanForProductSku(sku) {
  const state = getStore().getState();
  const plan = planSelectors.planForProductSku(state, {
    sku,
  });
  return plan || null;
}

export function getPlanIdForProductSku(sku) {
  const plan = getPlanForProductSku(sku);
  return plan ? plan.id : null;
}

export function getContentfulProductForPlan(planId) {
  const state = getStore().getState();
  const sku = getProductSkuForPlanId(planId);
  return staticDataSelectors
    .contentfulProducts(state)
    .find(product => product.sku === sku);
}

export function getProductAttributes(planId, quantity = 1) {
  const state = getStore().getState();

  const productSku = getProductSkuForPlanId(planId);
  const productId = getProductIdForSku(productSku);
  const plan = planSelectors.planForId(state, { id: planId });

  const contentfulProduct = staticDataSelectors
    .contentfulProducts(state)
    .find(product => product.sku === productSku);

  if (!contentfulProduct) return null;

  let { sku, name, slug, socialImage } = contentfulProduct;
  let productAttributes = {
    id: productId,
    variant: planId,
    variant_contents: plan.variantContents,
    variant_description: plan.variantDescription,
    sku: sku,
    name: NameMap(name).plain,
    currency: "USD",
    price: plan.amount / 100,
    quantity,
    slug: slug,
    url: `${process.env.GATSBY_URL}/products/${slug}`,
    image_url: `https:${socialImage.file.url}`,
  };
  return productAttributes;
}
