import React from "react";
import styled from "styled-components";
import countdown from "countdown";

// Utils
import { rem, responsive, Color } from "../../utils/style";
import { variation } from "../../utils/launchDarkly";
import { Icons } from "../../utils/svg";
import intl from "../../services/intl";

// Components
import MessageBanner from "../MessageBanner";
import PromotionDetailsModal from "./PromotionDetailsModal";

// Cyber Mondayy Countdown starts at 9:00PM PST on Sun 11/29/2020.
const COUNTDOWN_START_MS = 1606712400000;
// Cyber Monday ends at midnight PST on Tue 12/01/2020.
const CYBER_MONDAY_END_MS = 1606809600000;

const BannerContent = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: center;

  ${responsive.sm`
    padding: 17px 0;
  `}

  ${responsive.md`
    justify-content: center;
  `}
`;

const BannerText = styled.div`
  display: inline;

  p {
    display: inline;
    font-weight: 500;
    font-size: ${rem(16)};
    line-height: ${rem(22)};
    margin-bottom: 0;

    span {
      display: inline-block;
      text-align: left;
      font-weight: 500;
      font-size: ${rem(16)};
      line-height: ${rem(22)};
      min-width: 105px;
      width: 105px;
    }
  }

  ${responsive.md`
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: ${rem(18)};

      span {
        font-size: ${rem(18)};
        min-width: 116px;
        width: 116px;
      }
    }
  `}
`;

const TooltipButton = styled.button`
  background: transparent;
  border: none;
  opacity: 0.56;
  height: 16px;
  width: 16px;
  display: inline;
  position: relative;
  padding: 0;
  margin-left 8px;
  vertical-align: text-bottom;

  &:hover {
    opacity: 1;
  }

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  svg {
    position: absolute;
    height: 16px;
    width: 16px;
    margin-left: 1px;
    top: 0;
    left: 0;

    path {
      stroke: ${Color.white};

      // Style the dot on the i.
      &.icon-info-i-dot {
        stroke: none;
        fill: ${Color.white};
      }
    }
  }
`;

export default class CyberMondayBanner extends React.Component {
  constructor() {
    super();

    this.state = {
      count: "",
      blackFridayEnabled: false,
      modalOpen: false,
    };

    this.titleText = intl.t("banners.cyber-monday.title", "Promo Details");
    this.bodyText = intl.t(
      "banners.cyber-monday.body",
      `This offer expires on 12/01/2020 at 11:59AM PDT and cannot be combined with other offers, discounts, or applied to prior purchases. Discount amount limited to {amount} per subscription.`,
      { amount: intl.formatCurrency(30, { round: true }) },
    );
  }

  componentDidMount() {
    const blackFridayEnabled = variation("black-friday-2020");

    this.setState({
      blackFridayEnabled,
    });

    if (this.shouldShowCountdownTimer()) {
      this.timerId = this.startCountdown();
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.timerId);
  }

  shouldShowBanner() {
    const blackFridayEnabled = this.state.blackFridayEnabled;
    return blackFridayEnabled;
  }

  shouldShowCountdownTimer() {
    if (this.props.hideCountdown) return false;

    let todaysDate = Date.now();
    return todaysDate > COUNTDOWN_START_MS && todaysDate < CYBER_MONDAY_END_MS;
  }

  startCountdown() {
    return countdown(
      ts => {
        // If the banner should not be displayed, we should end the countdown
        // and hide the banner.
        if (!this.shouldShowCountdownTimer()) {
          this.endCountdown();
          return;
        }

        this.setState({
          count: this.formatTS(ts),
        });
      },
      CYBER_MONDAY_END_MS,
      countdown.HOURS | countdown.MINUTES | countdown.SECONDS,
    );
  }

  endCountdown() {
    window.clearInterval(this.timerId);
    this.setState({
      count: "",
    });
  }

  formatTS(ts) {
    const hours = ts.hours < 10 ? `0${ts.hours}` : ts.hours;
    const minutes = ts.minutes < 10 ? `0${ts.minutes}` : ts.minutes;
    const seconds = ts.seconds < 10 ? `0${ts.seconds}` : ts.seconds;

    return `${hours}h ${minutes}m ${seconds}s`;
  }

  openModal() {
    this.setState({
      modalOpen: true,
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    const { modalOpen } = this.state;

    if (!this.shouldShowBanner()) return null;

    const bannerText = this.shouldShowCountdownTimer()
      ? intl.t(
          "banners.cyber-monday.banner-text-timer",
          "Our biggest sale of the year is here. Get 40% off auto-applied at checkout. Offer ends in {count}",
          { count: `<span>${this.state.count}.</span>` },
        )
      : intl.t(
          "banners.cyber-monday.banner-text-",
          "Our biggest sale of the year is here. Get 40% off auto-applied at checkout.",
        );

    return (
      <MessageBanner {...this.props}>
        <BannerContent>
          <BannerText>
            <p dangerouslySetInnerHTML={{ __html: bannerText }} />
            <TooltipButton onClick={this.openModal.bind(this)}>
              <Icons.Info />
            </TooltipButton>
          </BannerText>
        </BannerContent>

        <PromotionDetailsModal
          isOpen={modalOpen}
          onRequestClose={this.closeModal.bind(this)}
          title={this.titleText}
          body={this.bodyText}
        />
      </MessageBanner>
    );
  }
}
