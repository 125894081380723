module.exports = {
  siteTitle: "Ritual",
  siteTitleAlt: "Ritual", // This allows an alternative site title for SEO schema.
  publisher: "Ritual", // Organization name used for SEO schema
  siteDescription:
    "Committed to creating the purest, most effective vitamin formulations, and sharing every ingredient, source & scientist that made our vitamin a reality.",
  siteUrl: "https://ritual.com", // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: "Ritual", // Author for RSS author segment and SEO schema
  authorUrl: "https://ritual.com", // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: "@ritual", // Change for Twitter Cards
  fbPageId: "706979406105651",
  shortTitle: "Ritual", // Used for App manifest e.g. Mobile Home Screen
  shareImage: "/logos/share.jpg", // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 1200, // Change to the width of your default share image
  shareImageHeight: 630, // Change to the height of your default share image
  siteLogo: "/logos/logo-512.png", // Logo used for SEO, RSS, and App manifest
  backgroundColor: "#e9e9e9", // Used for Offline Manifest
  themeColor: "#121212", // Used for Offline Manifest
  copyright: "Copyright © 2019 Ritual", // Copyright string for the RSS feed,
  contentHubArticlesUrl: "articles",
};
