import { removeCouponFromCart, addCouponToCart } from "../cart/actions";
import { addPendingCode, clearPendingCode } from "../pending-code/actions";

export function updateInput(code) {
  return dispatch => {
    // If the user is explicitly updating a code, clear any pending codes
    // (such as code from podcast banners).
    dispatch(clearPendingCode(code));
    dispatch(_updateInput(code));
  };
}

export function removeInput() {
  return dispatch => {
    // If the user is explicitly removing a code, clear any pending codes
    // (such as code from podcast banners).
    dispatch(clearPendingCode());
    return dispatch(removeCouponFromCart());
  };
}

export function clearInput() {
  return _updateInput();
}

export function reserveInput() {
  return (dispatch, getState) => {
    const applyCode = getState().applyCode;

    if (!applyCode.code || applyCode.hasBeenApplied) {
      return Promise.resolve();
    }
    // Reserving the code will reject if the reservation is invalid. We swallow
    // the errors here since the reserve action should already update in the
    // input to the `isValid: false` state.
    return dispatch(reserve(applyCode.code)).catch(() => {});
  };
}

export function reserve(code) {
  return dispatch => {
    return dispatch(addCouponToCart(code)).catch(error => {
      dispatch(_updateInput(code, false, true));
      throw error;
    });
  };
}

export function addAppliedCode(code) {
  return dispatch => {
    // Update the pending code to ensure that applied code is preserved even
    // if a product is removed.
    dispatch(addPendingCode(code));
    dispatch(_updateInput(code, true, true));
  };
}

function _updateInput(code = "", isValid = false, hasBeenApplied = false) {
  code = code.toUpperCase();

  return {
    type: "UPDATE_APPLY_CODE_INPUT",
    payload: {
      code,
      isValid,
      hasBeenApplied,
    },
  };
}
