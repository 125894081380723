import Guest, { ModelType } from "./model";
import guestSelectors from "./selectors";

import normalizedActions from "../utils/normalized/actions";
import fetch from "../utils/normalized/fetch";

export function fetchGuest() {
  return function(dispatch) {
    return fetch(
      "guests/me",
      {
        credentials: "include",
      },
      dispatch,
      ModelType,
    ).catch(error => {
      console.error(error);
    });
  };
}

export function setGuestAnonymousId(anonymousId) {
  return (dispatch, getState) => {
    const state = getState();

    const activeGuest = guestSelectors.activeGuest(state);
    if (!activeGuest || activeGuest.segmentAnonymousId) {
      return Promise.resolve();
    }

    const payload = { segment_anonymous_id: anonymousId };
    return fetch(
      `guests/${activeGuest.id}`,
      {
        method: "PATCH",
        body: JSON.stringify(payload),
        credentials: "include",
      },
      dispatch,
      ModelType,
    ).catch(error => {
      console.error(error);
    });
  };
}

export function addNormalizedGuest(data, associations) {
  return function(dispatch) {
    let guest = new Guest();
    guest.deserialize(data, associations);
    dispatch(addGuest(guest));
  };
}

export function addGuest(data, associations) {
  return function(dispatch, getState) {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().guests,
      ModelType,
      data,
      associations,
    );
  };
}
