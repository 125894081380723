export function safeParse(json) {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
}

export function safeStringify(json) {
  try {
    return JSON.stringify(json);
  } catch (e) {
    return null;
  }
}

export function supportsLocalStorage(val) {
  try {
    localStorage.setItem(val, val);
    localStorage.removeItem(val);
    return true;
  } catch (e) {
    return false;
  }
}

export const emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
