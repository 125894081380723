// Redux
import { getStore } from "../store/createStore";
import i18nSelectors from "../store/i18n/selectors";

// Helpers
import flatten from "flat";
import { createIntl, createIntlCache } from "react-intl";
import { domainRegex } from "../../config/domain";
import locales from "../../config/i18n";

// Translations
import enUS from "../data/translations/en-US.json";
import enCA from "../data/translations/en-CA.json";

// This is optional but highly recommended since it prevents memory leak.
const cache = createIntlCache();

const defaultLocale = "en-US";

class IntlService {
  constructor() {
    this.translationData = {
      // TO-DO: consider a pre-build phase to avoid flattening in the client runtime
      "en-US": flatten(enUS, {
        safe: true, // 'safe' preserves arrays
      }),
      "en-CA": flatten(enCA, {
        safe: true,
      }),
    };

    this.updateIntl(defaultLocale);
  }

  get locale() {
    return i18nSelectors.locale(getStore().getState());
  }

  get siteLang() {
    return i18nSelectors.siteLang(getStore().getState());
  }

  get ogLocale() {
    return i18nSelectors.ogLocale(getStore().getState());
  }

  get currency() {
    return i18nSelectors.currency(getStore().getState());
  }

  get path() {
    return i18nSelectors.path(getStore().getState());
  }

  get activeLocales() {
    return i18nSelectors
      .activeLocales(getStore().getState())
      .map(locale => locales[locale]);
  }

  get defaultLocale() {
    return locales[
      Object.keys(locales).find(locale => locales[locale].default)
    ];
  }

  updateIntl(locale) {
    this.intl = createIntl(
      {
        locale,
        defaultLocale,
        messages: this.translationData[locale],
      },
      cache,
    );
  }

  // The cf (Contentful Filter) function. This function takes an array of
  // Contentful nodes and filters them so that node's 'node_locale' matches the
  // current Redux locale.
  cf(nodes, singleNode = false) {
    // Allow nodes to be undefined, for testing.
    if (!nodes) return;

    const locale = i18nSelectors.locale(getStore().getState());
    if (!Array.isArray(nodes)) {
      throw new Error(`intl.cf expected an array of nodes`);
    }

    const filteredNodes = nodes.filter(node => {
      if (!node.node_locale) {
        throw new Error(`intl.cf node is missing a node_locale`);
      }
      return node.node_locale === locale;
    });

    // If we're expecting a single node (e.g. filtering for a single localized
    // asset), return the first element in the filtered array.
    if (singleNode) {
      if (filteredNodes.length === 1) {
        return filteredNodes[0];
      }
      throw new Error(`intl.cf expected to return a single node`);
    }

    // Otherwise return all the filtered nodes.
    return filteredNodes;
  }

  t(id, defaultMessage, values) {
    return this.intl.formatMessage({ id, defaultMessage }, values);
  }

  formatCurrency(number, options = {}) {
    const prefix = options.prefix ? this.currency.prefix : "";

    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
    // for the full list of API parameters
    const formattedNumber = this.intl.formatNumber(number, {
      style: "currency",
      currency: this.currency.type,
      minimumFractionDigits: options.round ? 0 : 2,
      currencyDisplay: "symbol",
      ...options,
    });
    
    // Temporary workaround to strip prefixes
    // in Node until full-icu is incorporated
    const normalizePrefix = n =>
      this.currency.prefix && n.startsWith(this.currency.prefix)
        ? n.substr(this.currency.prefix.length)
        : n;

    return prefix + normalizePrefix(formattedNumber);
  }

  // Returns the raw value from the translations file for the active locale
  // where we don't necessarily want it automatically formatted
  // and would prefer to handle it independently.
  unformattedTranslation(id) {
    return this.translationData[this.locale][id];
  }

  localizePath(input) {
    if (!this.path) return input;
    const match = input.split(domainRegex);
    const domainlessPath = match[1] || match[0];
    return !domainlessPath.startsWith("http") &&
      !domainlessPath.startsWith("mailto:") &&
      !domainlessPath.startsWith("#")
      ? `/${this.path}/${this.sanitizePath(domainlessPath)}`
      : domainlessPath;
  }

  // Removes localization paths and leading slashes
  sanitizePath(input) {
    const path = input.replace(/^\/|\/$/g, "");
    if (!this.path) return path;

    if (path.replace(/\/+$/, "") === this.path) return "";
    return path.startsWith(`${this.path}/`)
      ? path.split(`${this.path}/`)[1]
      : path;
  }
}

const intlService = new IntlService();
export default intlService;
export { IntlService };
