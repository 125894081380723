import React from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Components
import Text from "../Text";

// Utils
import { Font, Color, rem } from "../../utils/style";
import { emailRegex } from "../../utils/helpers";
import { iterablePost } from "../../utils/iterable";
import metrics from "../../utils/metrics";

// Styled Elements
const NewsletterFormContainer = styled.div.attrs({
  id: "newsletter-form",
  className: "newsletter-form",
})`
  position: relative;
`;

const NewsletterFormWrapper = styled.form.attrs({
  id: "newsletter-form_form",
})`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  background: #f2f1f5;
  display: flex;
  justify-items: space-between;
  align-items: center;
  align-content: stretch;
  height: 60px;
  padding: 0 24px;
`;

const FormInputWrapper = styled.div.attrs({
  id: "newsletter-form_form_input-wrapper",
  className: "klaviyo_field_group",
})`
  flex: 1 1 auto;
  padding-right: 8px;

  label {
    display: none;
  }

  input {
    ${Font.circular} width: 100%;
    color: ${Color.ritualBlue};
    border: 0;
    padding: 0;
    background: transparent;
    font-weight: 300;
    font-size: ${rem(16)};
    line-height: 1;
    height: ${rem(27)};
    overflow: visible;

    &::placeholder {
      ${Font.circular};
      font-size: ${rem(14)};
      line-height: ${rem(20)};
      font-weight: 300;
      color: rgba(20, 43, 111, 0.56);
    }

    [data-whatintent="mouse"] &:focus,
    [data-whatintent="touch"] &:focus {
      outline: none;
    }

    &::selection {
      background-color: rgba(${Color.ritualYellow}, 0.5);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:hover {
      box-shadow: 0 0 0 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    &:-webkit-autofill:focus {
      box-shadow: 1px 1px 4px 0 transparent, 0 0 0 1000px transparent inset;
    }
  }
`;

const FormButtonWrapper = styled.div.attrs({
  id: "newsletter-form_form_button-wrapper",
  className: "klaviyo_form_actions",
})`
  flex: 0 0 65px;

  button {
    ${Font.circular}
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    border: 0;
    padding: 0;
    background: transparent;
    color: ${Color.ritualBlue};
    cursor: pointer;
    border-bottom: 2px solid ${Color.ritualBlue};
    transition: opacity 200ms ease-in-out;

    &:hover {
      opacity: 0.56;
    }

    [data-whatintent="mouse"] &:focus,
    [data-whatintent="touch"] &:focus {
      outline: none;
    }
  }
`;

const FormResponse = styled.div.attrs({
  id: "newsletter-form_response",
})`
  position: relative;
  background-color: #f2f1f5;
  display: flex;
  justify-items: space-between;
  align-items: center;
  height: 60px;
  padding: 0 24px;

  &:before {
    position: absolute;
    content: "";
    top: 30px;
    right: 29px;
    width: 2px;
    height: 4px;
    background-color: #4c840d;
    transform: rotate(-45deg);
  }

  &:after {
    position: absolute;
    content: "";
    top: 24px;
    right: 24px;
    width: 2px;
    height: 12px;
    background-color: #4c840d;
    transform: rotate(45deg);
  }
`;

const ResponseContent = styled.p.attrs({
  id: "newsletter-form_response_content",
})`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  font-weight: 500;
  margin: 0;
  padding: 0 !important;
`;

const ErrorMessage = styled.p.attrs({
  id: "newsletter-form_error-message",
})`
  ${Font.circular}
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  font-weight: 300;
  color: ${Color.ritualAccessibleRed};
  position: absolute;
  top: 100%;
  left: 0;
`;

export default class NewsletterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      sent: false,
      error: false,
    };
    // Refs
    this.newsletterInput = null;
    // Bindings
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleChange(e) {
    this.setState({
      value: e.target.value.toLowerCase(),
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const publicIdString = process.env.GATSBY_ITERABLE_SIGNUP_ID;
    let { value } = this.state;

    if (!emailRegex.test(value)) {
      this.setState({
        error: true,
      });
    } else {
      this.setState({
        error: false,
      });

      iterablePost(publicIdString, value).then(() => {
        this.setState({
          sent: true,
          error: false,
        });

        metrics.track("Email Signup", {
          location: "footer",
          nonInteraction: false,
          email: value,
        });
      });
    }
  }

  handleClick() {
    this.newsletterInput.focus();
  }

  renderForm() {
    let { error } = this.state;
    return (
      <NewsletterFormWrapper
        onSubmit={this.handleSubmit}
        onClick={this.handleClick}
      >
        <FormInputWrapper error={error}>
          <label htmlFor="email">
            <Text id="newsletter.email-label" defaultMessage="Email" />
          </label>
          <input
            ref={r => (this.newsletterInput = r)}
            type="email"
            id="email"
            value={this.state.value}
            onChange={this.handleChange}
            placeholder={intl.t(
              "newsletter.placeholder-email",
              "Enter your email",
            )}
            required
            autoComplete="email"
            aria-describedby="email-error email-success"
          />
        </FormInputWrapper>
        <FormButtonWrapper>
          <button type="submit" className="klaviyo_submit_button">
            <Text id="newsletter.button-subscribe" defaultMessage="Subscribe" />
          </button>
        </FormButtonWrapper>
      </NewsletterFormWrapper>
    );
  }

  renderConditional() {
    let sent = this.state.sent;
    if (!sent) {
      return this.renderForm();
    } else {
      return (
        <FormResponse>
          <ResponseContent id="email-success" aria-live="assertive">
            <Text
              id="newsletter.success"
              defaultMessage="Thank you for subscribing!"
            />
          </ResponseContent>
        </FormResponse>
      );
    }
  }

  render() {
    let { error } = this.state;
    return (
      <NewsletterFormContainer>
        {this.renderConditional()}
        {error && (
          <ErrorMessage id="email-error" aria-live="assertive">
            <Text
              id="newsletter.error"
              defaultMessage="There was an error subscribing. Please check your email address and
              try again."
            />
          </ErrorMessage>
        )}
      </NewsletterFormContainer>
    );
  }
}
