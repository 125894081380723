import React from "react";
import styled from "styled-components";
import debounce from "lodash/debounce";
import classNames from "classnames/bind";
import * as focusTrap from "focus-trap";

// Redux
import { connect } from "react-redux";
import {
  updateNavigation,
  openNavPanel,
  closeNavPanel,
} from "../../store/navigation/actions";
import navigationSelectors from "../../store/navigation/selectors";
import userSelectors from "../../store/user/selectors";

// Components
import SkipLink from "./navigation_v3/SkipLink";
import TopNav from "./navigation_v3/TopNav";
import Overlay from "./navigation_v3/Overlay";
import Panel from "./navigation_v3/Panel";

// Utils
import { getBreakpoint, Variables, media } from "../../utils/style";
import metrics from "../../utils/metrics";
import navigation from "../../services/navigation";
import { lockScroll } from "../../utils/lockScroll";

// Style Variables
const ATMS = Variables.navAnimationTime;
const MATMS = Variables.navAnimationTimeMobile;
const CATMS = Variables.navContentAnimationTime;
const MobileNavHeight = Variables.navHeightSmall;

// Styled Elements
const NavigationContainer = styled.header``;

const PanelWrapper = styled.div`
  // gives the panels their own stacking context above Overlay
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: transparent;
  opacity: 1;
  transition: z-index 1ms linear ${ATMS};

  &.is-open {
    z-index: 10000;
    transition: none;
  }

  ${media.mobile`
    top: calc(${MobileNavHeight} + ${p => p.offset || 0}px); // TopNav Offset
    width: 100vw;
    opacity: 0;
    background-color: white;
    transition: opacity ${CATMS} linear ${CATMS}, z-index 1ms linear ${MATMS};

    &.is-open {
      z-index: 10000;
      opacity: 1;
      transition: opacity ${MATMS} linear, z-index 1ms linear;
    }
  `}
`;

export class NavigationComponent extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavigation = this.toggleNavigation.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNavClick = this.handleNavClick.bind(this);

    this.handleEscDown = this.handleEscDown.bind(this);
    this.debouncedHandleResize = debounce(this.handleResize.bind(this), 250);

    this.openNavPanel = this.openNavPanel.bind(this);
    this.closeNavPanel = this.closeNavPanel.bind(this);

    navigation.initialize();

    this.state = {
      navigationData: navigation.Navigation,
    };

    this.navRef = React.createRef();
    this.panelsRef = React.createRef();

    this.focusTrap = null;
    this.navFocusTrap = null;
    this.panelsFocusTrap = null;
    this.focusTrapTimeout = null;
  }

  componentDidMount() {
    const trapOptions = { allowOutsideClick: true };

    this.navFocusTrap = focusTrap.createFocusTrap(
      this.navRef.current,
      trapOptions,
    );
    this.panelsFocusTrap = focusTrap.createFocusTrap(
      this.panelsRef.current,
      trapOptions,
    );

    window.addEventListener("keydown", this.handleEscDown);
    window.addEventListener("resize", this.debouncedHandleResize);

    this.debouncedHandleResize();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props;
    const { breakpoint } = this.state;
    if (prevProps.isOpen !== isOpen || prevState.breakpoint !== breakpoint) {
      this.checkFocusTrap(isOpen, breakpoint);
    }

    if (prevProps.isOpen !== isOpen) {
      lockScroll(isOpen, this.navRef.current);
    }
  }

  checkFocusTrap(isOpen, breakpoint) {
    if (this.focusTrapTimeout) {
      clearTimeout(this.focusTrapTimeout);
    }

    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }

    if (isOpen) {
      // If we're on the XS breakpoint, the entire nav (including the top) bar
      // should be part of the focus trap. This ensures that the user is able
      // to navigate to the back, close and cart buttons when cycling through
      // focusable elements.
      this.focusTrap =
        breakpoint === "xs" ? this.navFocusTrap : this.panelsFocusTrap;

      // Extract the transition time number from the string ("500ms" -> 500).
      const transitionTime = Number(ATMS.match(/\d+/)[0]);

      // We use a timeout to set the focus, which allows for visiblity
      // transitions to complete.
      this.focusTrapTimeout = setTimeout(() => {
        this.focusTrap.activate();
      }, transitionTime);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEscDown);
    window.removeEventListener("resize", this.debouncedHandleResize);
    this.debouncedHandleResize.cancel();
    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }
  }

  handleEscDown(e) {
    if (e.keyCode === 27 && this.props.isOpen) {
      this.toggleNavigation();
    }
  }

  handleResize() {
    const breakpoint = getBreakpoint();
    this.setState({
      breakpoint,
    });

    // Show the two-paneled nav on the S breakpoint.
    if (breakpoint === "sm") {
      // If the desired state has changed, update to the new state and close the
      // existing navigation.
      if (this.state.navigationData !== navigation.NavigationSmall) {
        this.setState({
          navigationData: navigation.NavigationSmall,
        });
        this.closeNavPanel(0);
      }
    } else if (this.state.navigationData !== navigation.Navigation) {
      // Show the three-paneled nav on the XS, M and L breakpoints.
      this.setState({
        navigationData: navigation.Navigation,
      });
      this.closeNavPanel(0);
    }
  }

  openNavPanel(depth, key) {
    this.props.dispatchOpenPanel(depth, key);
  }

  closeNavPanel(depth) {
    this.props.dispatchClosePanel(depth);
  }

  toggleNavigation(e) {
    const navOpen = this.props.isOpen;

    if (navOpen) {
      this.closeNavPanel(0);
    } else {
      this.openNavPanel(0, "top");
    }
  }

  handleClose() {
    this.props.dispatchClosePanel(0);
  }

  handleNavClick({ title, url }) {
    metrics.track("Nav Item Clicked", {
      title: title,
      location: this.props.isOpen ? "Open Nav" : "Closed Nav",
      url: url,
    });

    this.props.dispatchClosePanel(0);
  }

  render() {
    const { isOpen, panels } = this.props;
    const { navigationData } = this.state;

    const panelData = navigationData.panel;
    const panelCount = navigationData.panelCount;
    const offset = navigation.offset;

    return (
      <NavigationContainer id="navigation">
        <SkipLink />
        <div ref={this.navRef}>
          <TopNav
            isOpen={isOpen}
            panels={panels}
            pathname={this.props.pathname}
            toggleNavigation={this.toggleNavigation}
            handleNavClick={this.handleNavClick}
            closeNavPanel={this.closeNavPanel}
          >
            {/* Banner Elements */}
            {this.props.children}
          </TopNav>
          <Overlay navOpen={isOpen} onClick={this.handleClose} />
          <PanelWrapper
            ref={this.panelsRef}
            className={classNames({ "is-open": isOpen })}
            offset={offset}
          >
            <Panel
              panelData={panelData}
              panelCount={panelCount}
              depth={0}
              offset={offset}
              panels={panels}
              handleNavClick={this.handleNavClick}
            />
          </PanelWrapper>
        </div>
      </NavigationContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  navigation: state.navigation,
  isOpen: navigationSelectors.isOpen(state),
  panels: navigationSelectors.panels(state),
  isLoggedIn: userSelectors.isLoggedIn(state),
  ...ownProps,
});

export default connect(mapStateToProps, {
  updateNavigationAction: updateNavigation,
  dispatchOpenPanel: openNavPanel,
  dispatchClosePanel: closeNavPanel,
})(NavigationComponent);
