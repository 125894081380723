import React from "react";
import styled from "styled-components";

// Components
import Text from "../../Text";

// Utils
import { Color } from "../../../utils/style";

const SkipNavLink = styled.a`
  display: block;
  position: absolute;
  top: -10000px;
  left: 12px;
  z-index: 1;
  padding: 0;
  margin: 0;
  opacity: 0;
  color: ${Color.ritualBlue};
  background-color: ${Color.white};

  &:focus {
    top: 16px;
    z-index: 111;
    opacity: 1;
  }
`;

export default class SkipLink extends React.Component {
  render() {
    return (
      <SkipNavLink href="#main">
        <Text id="nav.skip-link" defaultMessage="Skip Navigation" />
      </SkipNavLink>
    );
  }
}
