import React from "react";
import styled from "styled-components";
import { responsive } from "../../utils/style";
import { RitualLogos } from "../../utils/svg";
import MagicLink from "../MagicLink";

const RitualLogo = styled(MagicLink)``;

export default class CloseButton extends React.Component {
  render() {
    let { handleNavigateClick, title = "Ritual", to = "/" } = this.props;
    return (
      <RitualLogo to={to} title={title} onClick={handleNavigateClick}>
        <RitualLogos.RitualLogo />
      </RitualLogo>
    );
  }
}
