import React from "react";
import styled from "styled-components";

// Utils
import { Color, rem } from "../../../utils/style";

// Components
import Highlighter from "../Highlighter";
import MagicLink from "../../MagicLink";

const SlideOutLinkContainer = styled.div`
  margin-bottom: 8px;
`;

const ProductLink = styled(MagicLink)`
  color: ${Color.ritualBlue};
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  font-weight: 500;
  letter-spacing: -0.2px;
  margin: 0 0 8px;

  &:last-of-type {
    margin: 0;
  }
`;

export default class SlideOutLink extends React.Component {
  render() {
    const { title, label, url, handleNavClick, className } = this.props;

    return (
      <SlideOutLinkContainer>
        <Highlighter height={8} noFade={true}>
          <ProductLink
            to={url}
            className={className}
            onClick={handleNavClick.bind(this, {
              title: label,
              url: url,
            })}
          >
            {title}
          </ProductLink>
        </Highlighter>
      </SlideOutLinkContainer>
    );
  }
}
