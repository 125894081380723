import Promotion, { ModelType } from "./model";
import normalizedActions from "../utils/normalized/actions";

export function removePromotion(id) {
  return dispatch => {
    return normalizedActions.removeModel(dispatch, ModelType, id);
  };
}

export function addPromotionFromNormalizedData(data, associations) {
  return dispatch => {
    const promotion = new Promotion();
    promotion.deserialize(data);
    dispatch(addPromotion(promotion, associations));
  };
}

export function addPromotion(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().promotion,
      ModelType,
      data,
      associations,
    );
  };
}
