import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import app from "./app/reducer";
import applyCode from "./apply-code/reducer";
import banners from "./banners/reducer";
import i18n from "./i18n/reducer";
import navigation from "./navigation/reducer";
import outOfStock from "./out-of-stock/reducer";
import pendingCode from "./pending-code/reducer";
import redirects from "./redirects/reducer";
import staticData from "./staticData/reducer";

// Normalized Store Reducers
import carts from "./cart/reducer";
import cartProducts from "./cart-product/reducer";
import coupons from "./coupon/reducer";
import promotion from "./promotion/reducer";
import guests from "./guest/reducer";
import plans from "./plan/reducer";
import products from "./product/reducer";
import subscriptions from "./subscription/reducer";
import users from "./user/reducer";

const rootReducer = combineReducers({
  app,
  applyCode,
  banners,
  i18n,
  navigation,
  outOfStock,
  pendingCode,
  redirects,
  staticData,
  // Normalized Stores
  carts,
  cartProducts,
  coupons,
  promotion,
  guests,
  plans,
  products,
  subscriptions,
  users,
});

export function create(initialState = {}) {
  return createStore(rootReducer, initialState, applyMiddleware(thunk));
}

const store = create({});

export function getStore() {
  return store;
}
