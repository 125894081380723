// Action Types
export const UPDATE_REDIRECTS = "UPDATE_REDIRECTS";

export function updateRedirects(redirects) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_REDIRECTS,
      redirects,
    });
  };
}
