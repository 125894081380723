// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-bundle-landing-page-js": () => import("./../../../src/templates/bundleLandingPage.js" /* webpackChunkName: "component---src-templates-bundle-landing-page-js" */),
  "component---src-templates-bundling-incentive-page-js": () => import("./../../../src/templates/bundlingIncentivePage.js" /* webpackChunkName: "component---src-templates-bundling-incentive-page-js" */),
  "component---src-templates-contentful-freeform-article-js": () => import("./../../../src/templates/contentfulFreeformArticle.js" /* webpackChunkName: "component---src-templates-contentful-freeform-article-js" */),
  "component---src-templates-contentful-policies-page-js": () => import("./../../../src/templates/contentfulPoliciesPage.js" /* webpackChunkName: "component---src-templates-contentful-policies-page-js" */),
  "component---src-templates-discount-page-js": () => import("./../../../src/templates/discountPage.js" /* webpackChunkName: "component---src-templates-discount-page-js" */),
  "component---src-templates-ingredients-map-js": () => import("./../../../src/templates/ingredientsMap.js" /* webpackChunkName: "component---src-templates-ingredients-map-js" */),
  "component---src-templates-ingredients-page-js": () => import("./../../../src/templates/ingredientsPage.js" /* webpackChunkName: "component---src-templates-ingredients-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/productPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-routes-approach-js": () => import("./../../../src/templates/routes/approach.js" /* webpackChunkName: "component---src-templates-routes-approach-js" */),
  "component---src-templates-routes-articles-js": () => import("./../../../src/templates/routes/articles.js" /* webpackChunkName: "component---src-templates-routes-articles-js" */),
  "component---src-templates-routes-build-a-bundle-js": () => import("./../../../src/templates/routes/build-a-bundle.js" /* webpackChunkName: "component---src-templates-routes-build-a-bundle-js" */),
  "component---src-templates-routes-cart-js": () => import("./../../../src/templates/routes/cart.js" /* webpackChunkName: "component---src-templates-routes-cart-js" */),
  "component---src-templates-routes-clinical-study-js": () => import("./../../../src/templates/routes/clinical-study.js" /* webpackChunkName: "component---src-templates-routes-clinical-study-js" */),
  "component---src-templates-routes-gift-js": () => import("./../../../src/templates/routes/gift.js" /* webpackChunkName: "component---src-templates-routes-gift-js" */),
  "component---src-templates-routes-index-js": () => import("./../../../src/templates/routes/index.js" /* webpackChunkName: "component---src-templates-routes-index-js" */),
  "component---src-templates-routes-ingredients-js": () => import("./../../../src/templates/routes/ingredients.js" /* webpackChunkName: "component---src-templates-routes-ingredients-js" */),
  "component---src-templates-routes-login-js": () => import("./../../../src/templates/routes/login.js" /* webpackChunkName: "component---src-templates-routes-login-js" */),
  "component---src-templates-routes-press-js": () => import("./../../../src/templates/routes/press.js" /* webpackChunkName: "component---src-templates-routes-press-js" */),
  "component---src-templates-routes-reviews-js": () => import("./../../../src/templates/routes/reviews.js" /* webpackChunkName: "component---src-templates-routes-reviews-js" */),
  "component---src-templates-routes-shop-vitamins-js": () => import("./../../../src/templates/routes/shop-vitamins.js" /* webpackChunkName: "component---src-templates-routes-shop-vitamins-js" */),
  "component---src-templates-routes-signup-js": () => import("./../../../src/templates/routes/signup.js" /* webpackChunkName: "component---src-templates-routes-signup-js" */),
  "component---src-templates-shop-landing-page-js": () => import("./../../../src/templates/shopLandingPage.js" /* webpackChunkName: "component---src-templates-shop-landing-page-js" */)
}

