import React from "react";
import styled from "styled-components";

// Utils
import { Color, rem } from "../../../../utils/style";

// Components
import Highlighter from "../../Highlighter";
import MagicLink from "../../../MagicLink";

const ProductLinksContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Subheader = styled.h4`
  color: ${Color.ritualBlue};
  opacity: 0.56;

  line-height: ${rem(26)};
  font-size: ${rem(16)};
  font-weight: 300;

  margin: 0 0 8px;
`;

const ListItem = styled.li`
  color: ${Color.ritualBlue};

  font-size: ${rem(22)};
  line-height: ${rem(32)};
  font-weight: 500;
  letter-spacing: -0.2px;

  margin: 0 0 8px;
`;

const ProductLinks = props => {
  const { subheader, links, handleNavClick } = props;

  return (
    <ProductLinksContainer>
      {subheader && <Subheader>{subheader}</Subheader>}
      {links.map((link, i) => {
        return (
          <ProductLink
            key={i}
            title={link.title}
            to={link.url}
            handleNavClick={handleNavClick}
          />
        );
      })}
    </ProductLinksContainer>
  );
};

const ProductLink = props => {
  const { title, to, handleNavClick } = props;

  const handleClick = () => {
    handleNavClick({ title, url: to });
  };

  return (
    <ListItem>
      <MagicLink data-test-product-link to={to} onClick={handleClick}>
        <Highlighter height={8}>
          <span>{title}</span>
        </Highlighter>
      </MagicLink>
    </ListItem>
  );
};

export default ProductLinks;
