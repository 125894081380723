import { createSelector } from "reselect";

const _meta = state => state.users.meta;
const _byId = state => state.users.byId;

const isLoggedIn = createSelector(_meta, meta => meta.authenticated);
const activeUser = createSelector(
  _meta,
  _byId,
  (meta, byId) => byId[meta.activeId],
);

export default { isLoggedIn, activeUser };
