import Model from "../model";
import { Reviews } from "./types";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "PRODUCT";

export default class Product extends Model {
  // Attributes
  name: string = "";
  description: string = "";
  sku: string = "";
  imageUrl: string = "";
  imagePath: string = "";
  inStock: boolean = true;
  urlSlug: string = "";
  category: string = "";
  subCategory: string = "";
  genders: string[] = [];
  ageRanges: string[] = [];
  lifestages: string[] = [];

  reviews: Reviews = {
    count: 0,
    average_rating: 0,
    percent_would_recommend: 0,
  };

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);
  }
}
