import Cookies from "js-cookie";

//Utils
import launchDarkly from "./launchDarkly";
import metrics from "./metrics";
import { analytics } from "./analytics";

// Store
import { getStore } from "../store/createStore";
import { fetchUser, updateActiveUser } from "../store/user/actions";
import cartSelectors from "../store/cart/selectors";
import userSelectors from "../store/user/selectors";

// Authenticte
import { SET_COOKIE, updateAuthenticationCookie } from "./authenticate";
import fetch, { setAccessToken } from "./fetch";

// Helpers
import { safeParse, safeStringify, supportsLocalStorage } from "./helpers";

const isStorageSupported = supportsLocalStorage("ritual-ils");

function _readAuthenticationCookie() {
  let cookie = Cookies.get("ritual_auth-session");
  if (!cookie) return {};

  let authCookie = safeParse(cookie);
  if (!authCookie) return {};

  const {
    access_token,
    refresh_token,
    expires_at,
    token_type,
  } = authCookie.authenticated;
  const expiration_date = new Date(expires_at);

  return {
    token_type,
    access_token,
    refresh_token,
    expires_at,
    expiration_date,
  };
}

function _requestRefreshToken(refreshToken) {
  return fetch("oauth/token", {
    method: "POST",
    credentials: "include",
    body: {
      refresh_token: refreshToken,
      grant_type: "refresh_token",
    },
    headers: {
      Accept: "application/json", // Guarantees returns JSON
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

async function _refreshToken(cookie) {
  const data = await _requestRefreshToken(cookie.refresh_token);

  if (SET_COOKIE) {
    updateAuthenticationCookie(data);
  }
}

function _fetchAndStoreUser(accessToken) {
  return getStore()
    .dispatch(fetchUser(accessToken))
    .then(data => {
      const user = data && data.data;
      const { id } = user;
      _storeCurrentUser(id);
      return user;
    })
    .then(user => {
      const ldKey = user.attributes.ld_key;
      if (ldKey) {
        return launchDarkly.setKey(ldKey);
      } else {
        return _setLDKeyForUser();
      }
    })
    .catch(error => {
      console.error(error);
    });
}

function _setLDKeyForUser() {
  const ldKey = launchDarkly.getKey();
  if (!ldKey) return Promise.resolve();

  return getStore()
    .dispatch(updateActiveUser({ ldKey }))
    .catch(e => {
      // If the error code is a 409, we know there is a conflict with
      // LaunchDarkly key. We should generate a new key and re-issue the
      // request.
      if (e.status === 409) {
        return launchDarkly.resetUser().then(() => {
          return _setLDKeyForUser();
        });
      }
    });
}

export function identifyUser() {
  const state = getStore().getState();

  const user = userSelectors.activeUser(state);
  if (!user) return;

  const { id, email, firstName, lastName, castleSignature } = user;

  const segmentAttributes = {
    email,
    first_name: firstName,
    last_name: lastName,
    ld_key: launchDarkly.getKey(),
  };

  // Add the cart id to the segment identify call, if one exists.
  const activeCart = cartSelectors.activeCart(state);
  if (activeCart && activeCart.id) {
    segmentAttributes.cart_id = activeCart.id;
  }

  metrics.identify(id, segmentAttributes);

  launchDarkly.identifyFromUserInfo(
    {
      id: id,
      traits: {
        email,
        first_name: firstName,
        last_name: lastName,
      },
    },
    {
      cartId: activeCart && activeCart.id,
    },
  );

  if (window._castle && typeof window._castle === "function") {
    window._castle("identify", id);
    if (castleSignature) {
      window._castle("secure", castleSignature);
    }
  }

  if (window._talkableq) {
    window._talkableq.push([
      "authenticate_customer",
      {
        email,
        first_name: firstName,
        last_name: lastName,
        customer_id: id,
      },
    ]);
  }
}

export function getUserTraits() {
  const state = getStore().getState();
  const activeUser = userSelectors.activeUser(state);

  if (activeUser) {
    const { email, firstName, lastName } = activeUser;
    return { email, firstName, lastName };
  }

  const storedTraits = analytics.getStoredUserInfo().traits;
  const { email, first_name: firstName, last_name: lastName } = storedTraits;
  return { email, firstName, lastName };
}

function _storeCurrentUser(id) {
  if (!isStorageSupported) return;

  let localStorage = window.localStorage;
  let currentUser = safeStringify({
    id,
  });

  if (!currentUser) return;

  localStorage.setItem("currentUser", currentUser);
}

export function getStoredUser() {
  if (!isStorageSupported) return null;

  const localStorage = window.localStorage;
  const currentUser = localStorage.getItem("currentUser");

  // If there's a currentUser property in storage but it isn't an object with
  // an id, return null.
  const parsedUser = safeParse(currentUser);
  if (parsedUser && !parsedUser.id) return null;

  return parsedUser;
}

export function clearCurrentUser() {
  Cookies.remove("ritual_auth-session", {
    domain: process.env.GATSBY_COOKIE_DOMAIN,
  });

  if (window._castle && typeof window._castle === "function") {
    window._castle("reset");
  }

  if (!isStorageSupported) return;

  const localStorage = window.localStorage;
  return localStorage.removeItem("currentUser");
}

export function hasLoggedOutUser() {
  const cookie = _readAuthenticationCookie();
  const storedUser = getStoredUser();

  const hasAuthCookie = cookie && cookie.access_token;
  return !!storedUser && !hasAuthCookie;
}

export async function authAndFetchUser() {
  let cookie = _readAuthenticationCookie();

  if (!cookie || !cookie.access_token) {
    clearCurrentUser();
    return Promise.resolve();
  }

  const { expiration_date } = cookie;
  const isAccessTokenExpired = expiration_date <= new Date();

  // If Access Token is expired, refresh the token.
  if (isAccessTokenExpired) {
    try {
      await _refreshToken(cookie);
    } catch (e) {
      // If refreshing the token fails, clear the stored user and auth cookie.
      console.error(e);
      clearCurrentUser();
      return Promise.resolve();
    }
    cookie = _readAuthenticationCookie();
  }

  setAccessToken(cookie.access_token);

  return _fetchAndStoreUser(cookie.access_token);
}
