import React from "react";
import styled from "styled-components";
import { openConsentManager } from "@segment/consent-manager";
import Text from "../../Text";

// Utils
import { Color } from "../../../utils/style";

const Wrapper = styled.div`
  margin-right: 16px;
  display: inline-block;
`;

const Button = styled.button`
  color: ${Color.ritualBlue};
  font-weight: 500;

  padding: 0;
  margin: 0;

  background: none;
  border: none;

  &:hover {
    opacity: 0.56;
  }

  transition: all 0.2s;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }
`;

export default class DoNotTrack extends React.Component {
  render() {
    return (
      <Wrapper>
        <Button onClick={openConsentManager}>
          <Text id="footer.donottrack" defaultMessage="Do Not Track" />
        </Button>
      </Wrapper>
    );
  }
}
