// Utils
import metrics from "../metrics";
import { getPropertiesForProduct, getCartProductsForIterable } from "./helpers";

// Store
import { getStore } from "../../store/createStore";
import cartSelectors from "../../store/cart/selectors";
import cartProductSelectors from "../../store/cart-product/selectors";

export function trackProductAdded(planId, additionalProperties) {
  const state = getStore().getState();

  const { code } = state.pendingCode;

  const productProperties = getPropertiesForProduct(planId);

  let properties = {
    cart_id: _getCartId(state),
    products: productProperties.product_id ? [productProperties] : [],
    coupon: code,
  };

  properties = { ...properties, ...productProperties, ...additionalProperties };

  const iterableProducts = getCartProductsForIterable(state);

  const iterableProperties = { ...properties };
  iterableProperties.products = iterableProducts;

  metrics.track("Product Added", properties, {
    addTraitsToContext: true,
    addEmail: true,
    addFacebookCookies: true,
    segmentOptions: {
      integrations: {
        Iterable: false,
      },
    },
  });

  metrics.track("Product Added", iterableProperties, {
    addTraitsToContext: true,
    addEmail: true,
    segmentOptions: {
      integrations: {
        All: false,
        Iterable: true,
      },
    },
  });
}

export function trackProductRemoved(planId, additionalProperties) {
  const state = getStore().getState();

  const { code } = state.pendingCode;

  let properties = {
    cart_id: _getCartId(state),
    coupon: code,
  };

  const productProperties = getPropertiesForProduct(planId);
  properties = { ...properties, ...productProperties, ...additionalProperties };

  const iterableProducts = getCartProductsForIterable(state);

  const iterableProperties = { ...properties };
  iterableProperties.products = iterableProducts;

  metrics.track("Product Removed", properties, {
    addTraitsToContext: true,
    addEmail: true,
    addFacebookCookies: true,
    segmentOptions: {
      integrations: {
        Iterable: false,
      },
    },
  });

  metrics.track("Product Removed", iterableProperties, {
    addTraitsToContext: true,
    addEmail: true,
    segmentOptions: {
      integrations: {
        All: false,
        Iterable: true,
      },
    },
  });
}

// Cart Events Only

export function trackCheckoutCTAClicked() {
  const state = getStore().getState();

  const { code } = state.pendingCode;

  return metrics.track("Checkout CTA Clicked", {
    cart_id: _getCartId(state),
    products: _getCartProducts(state),
    coupon: code,
  });
}

export function trackCartViewed() {
  const state = getStore().getState();

  const { code } = state.pendingCode;

  const productQuantity = cartProductSelectors.activeCartProductQuantity(state);

  let properties = {
    cart_id: _getCartId(state),
    products: _getCartProducts(state),
    coupon: code,
    num_products: productQuantity,
  };

  metrics.track("Cart Viewed", properties, {
    addEmail: true,
  });
}

// Upsell in cart events

export function trackUpsell(skus) {
  const state = getStore().getState();

  return metrics.track("product recommendations set", {
    product_in_cart: _getCartProducts(state),
    recommendations_returned: skus,
  });
}

// Helpers

export function _getCartProducts(state) {
  const cartProducts = cartProductSelectors.activeCartProducts(state);
  return cartProducts.map(product => {
    const { quantity, planId } = product;
    return getPropertiesForProduct(planId, quantity);
  });
}

function _getCartId(state) {
  const activeCart = cartSelectors.activeCart(state);
  return activeCart ? activeCart.id : null;
}
