import React from "react";
import styled from "styled-components";

// Utils
import { rem, responsive } from "../../../utils/style";

// Components
import MagicLink from "../../MagicLink";
import Highlighter from "../Highlighter";

// Styled Elements
const Link = styled(MagicLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;

  h3 {
    font-size: ${rem(30)};
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: ${rem(36)};
    margin-bottom: 0px;
  }
`;

export default class CategoryLink extends React.Component {
  handleCategoryLinkClick() {
    const { title, path, handleNavClick } = this.props;

    let homeUrl = process.env.GATSBY_URL || "//ritual.com";
    handleNavClick({
      title: title,
      url: `${homeUrl}${path}`,
    });
  }

  render() {
    const { title, path, onMouseOver, menuFocusRef, className } = this.props;
    return (
      <Link
        hrefLang="x-default"
        to={path}
        ref={menuFocusRef}
        onClick={this.handleCategoryLinkClick.bind(this)}
        onMouseOver={onMouseOver}
        className={className}
      >
        <Highlighter height={12}>
          <h3>{title}</h3>
        </Highlighter>
      </Link>
    );
  }
}
