const NameMap = function(node) {
  let productName = node.name;

  if (productName && productName.indexOf("Essential ") !== -1) {
    let nameSuffix = productName.split("Essential ")[1];
    productName = `Essential <em>${nameSuffix}</em>`;
  }

  return { plain: node.name, html: productName };
};

module.exports = NameMap;
