import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Utils
import { rem, responsive } from "../../../../utils/style";
import { Icons } from "../../../../utils/svg";
import { analytics } from "../../../../utils/analytics";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { updateRedirects } from "../../../../store/redirects/actions";
import { closeNavPanel } from "../../../../store/navigation/actions";
import userSelectors from "../../../../store/user/selectors";

// Components
import MagicLink from "../../../MagicLink";
import Highlighter from "../../Highlighter";

// Styled Elements
const AccountLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const AccountLinkText = styled.span`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
  }

  p {
    margin: 0;
    padding-left: 12px;

    font-size: ${rem(16)};
    font-weight: 300;
    letter-spacing: 0px;
    line-height: ${rem(26)};
  }

  ${responsive.md`
    svg {
      height: 24px;
      width: 24px;
    }

    p {
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    }
  `}
`;

const LogoutLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    font-size: ${rem(16)};
    font-weight: 300;
    letter-spacing: 0px;
    line-height: ${rem(26)};
  }

  & > span {
    padding-left: 16px;
    padding-right: 16px;
  }

  ${responsive.md`
    p{
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    }
  `}
`;

const AccountLinks = props => {
  const { handleNavClick } = props;
  const accountUrl = process.env.GATSBY_ACCOUNT_URL;

  const authenticated = useSelector(userSelectors.isLoggedIn);

  const dispatch = useDispatch();

  const handleHover = () => {
    dispatch(closeNavPanel(1));
  };

  return (
    <AccountLinksWrapper>
      <AccountLink
        accountUrl={accountUrl}
        authenticated={authenticated}
        handleNavClick={handleNavClick}
        handleHover={handleHover}
      />
      {authenticated && (
        <LogoutLink
          accountUrl={accountUrl}
          handleNavClick={handleNavClick}
          handleHover={handleHover}
        />
      )}
    </AccountLinksWrapper>
  );
};

const AccountLink = props => {
  const { accountUrl, authenticated, handleNavClick, handleHover } = props;
  const dispatch = useDispatch();

  const [hasExistingId, setHasExistingId] = useState(false);

  useEffect(() => {
    const user = analytics.getStoredUserInfo();
    setHasExistingId(!!user.id);
  }, []);

  const handleAccountLinkClick = () => {
    handleNavClick({
      title: "Account",
      url: linkProps.to || linkProps.href,
    });
  };

  const handleLoginRedirect = () => {
    dispatch(
      updateRedirects({
        login: hasExistingId ? accountUrl : "/cart",
      }),
    );
    handleAccountLinkClick();
  };

  let linkProps = {
    href: accountUrl,
    onClick: handleAccountLinkClick,
    onMouseOver: handleHover,
    onFocus: handleHover,
    target: "_self",
  };

  if (!authenticated) {
    linkProps = {
      to: hasExistingId ? "/login" : "/signup",
      onClick: handleLoginRedirect,
      onMouseOver: handleHover,
      onFocus: handleHover,
    };
  }

  return (
    <MagicLink {...linkProps}>
      <Highlighter>
        <AccountLinkText>
          <Icons.Account />
          <p>Account</p>
        </AccountLinkText>
      </Highlighter>
    </MagicLink>
  );
};

const LogoutLink = props => {
  const { accountUrl, handleNavClick, handleHover } = props;

  const handleClick = () => {
    handleNavClick({
      title: "Log Out",
      url: `${accountUrl}/logout`,
    });
  };

  return (
    <LogoutLinkWrapper>
      <span> | </span>
      <MagicLink
        href={`${accountUrl}/logout`}
        target="_self"
        onClick={handleClick}
        onMouseOver={handleHover}
        onFocus={handleHover}
      >
        <Highlighter>
          <p>Log Out</p>
        </Highlighter>
      </MagicLink>
    </LogoutLinkWrapper>
  );
};

export default AccountLinks;
