import React from "react";
import styled from "styled-components";
import { Color, rgba } from "../../utils/style";

const FadedYellow = rgba(Color.ritualYellow, 0.56);

function getGradient(height, percent) {
  let top = `33.33%`;
  let bottom = `66.66%`;

  if (height) {
    top = `calc(50% - ${height / 2}px)`;
    bottom = `calc(50% + ${height / 2}px)`;
  } else if (percent) {
    top = `${50 - percent / 2}%`;
    bottom = `${50 + percent / 2}%`;
  }

  return `linear-gradient(
    transparent ${top},
    ${FadedYellow} ${top},
    ${FadedYellow} ${bottom},
    transparent ${bottom}
  )`;
}

const Strike = styled.span`
  & > * {
    text-decoration: none;
    background-image: ${p =>
      p.hover ? `none` : getGradient(p.height, p.percent)};

    margin: ${p => (p.tight ? 0 : `0 -5px`)};
    padding: ${p => (p.tight ? 0 : `0 5px`)};

    &:hover {
      background-image: ${p => getGradient(p.height, p.percent)};
    }
  }
`;

/**
 * @param {Boolean} hover Whether the highlight should only display on hover.
 * @param {Number} height The height of the highlight, in pixels.
 * @param {Number} percent The height of the highlight, as a percentage of the
 *    line height.
 * @param {Boolean} tight Whether the highlight extends beyond the content (
 *    currently set to 5px to the left and right).
 */
const Highlighter = props => {
  const { hover = true, height, percent, tight } = props;
  return (
    <Strike hover={hover} height={height} percent={percent} tight={tight}>
      {props.children}
    </Strike>
  );
};

export default Highlighter;
