import React from "react";
import styled from "styled-components";
import { rem, Font, Color, responsive, rgba } from "../../../utils/style";
import { Icons } from "../../../utils/svg";

const CloseButtonWrapper = styled.div``;

const CloseButtonElement = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  height: 44px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }
`;

const CloseButtonIconWrapper = styled.div`
  position: relative;
  height: 16px;
  width: 16px;
  padding: 0;
  margin-top: 3px;

  svg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

export default class CloseButton extends React.Component {
  render() {
    let { handleCloseClick } = this.props;
    return (
      <CloseButtonWrapper>
        <CloseButtonElement onClick={handleCloseClick} aria-label="Close">
          <CloseButtonIconWrapper>
            <Icons.Close></Icons.Close>
          </CloseButtonIconWrapper>
        </CloseButtonElement>
      </CloseButtonWrapper>
    );
  }
}
