import React from "react";
import styled from "styled-components";
import MessageBanner from "../MessageBanner";
import { Icons } from "../../utils/svg";
import Text from "../Text";
import { Font, Color, rem, responsive } from "../../utils/style";

const BannerContent = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 12px;

  ${responsive.md`
    padding-top: 12px;
    padding-bottom: 14px;
  `}

  .svg-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 28px;
    width: 28px;
    margin-right: 3px;

    ${responsive.md`
      height: 30px;
      width: 30px;
      margin-right: 12px;
    `}

    svg {
      height: 18px;
      width: 18px;

      ${responsive.md`
        height: 22px;
        width: 22px;
      `}

      path {
        fill: ${Color.white};
      }
    }

  }

  p {
    ${Font.circular}
    font-size: ${rem(16)};
    letter-spacing: 0;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 0;

    ${responsive.md`
      font-size: ${rem(20)};
      line-height: 30px;
    `}
  }
`;

export default class IdMeConfirmationBanner extends React.Component {
  render() {
    return (
      <MessageBanner {...this.props}>
        <BannerContent>
          <div className="svg-wrapper">
            <Icons.Upvote />
          </div>

          <p>
            <Text
              id="banners.idme.code-applied"
              defaultMessage="Your code has been applied — you'll see it in checkout!"
            />
          </p>
        </BannerContent>
      </MessageBanner>
    );
  }
}
