import React from "react";
import styled from "styled-components";

// Utils
import { rem, responsive, Color, rgba } from "../../utils/style";
import { variation } from "../../utils/launchDarkly";
import metrics from "../../utils/metrics";

// Components
import MessageBanner from "../MessageBanner";
import Emoji from "../Emoji";
import MagicLink from "../../components/MagicLink";
import Text from "../Text";
import Currency from "../Currency";

// Styled Elements
export const BundleMessage = styled.div`
  line-height: 20px;
  font-weight: 300;
  font-size: ${rem(16)};
  padding: 8px 0;

  p {
    margin-bottom: 0;
    text-align: left;

    ${responsive.md`
      text-align: center;
    `}
  }
`;

const BundleLink = styled(MagicLink)`
  color: ${Color.white};
  border-bottom: 1px solid ${Color.white};

  &:hover {
    color: ${rgba(Color.white, 0.72)};
    border-bottom: 1px solid ${rgba(Color.white, 0.72)};
  }
`;

export default class BundleBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      withCTA: false,
    };
  }

  componentDidMount() {
    const bannerVariation = variation("bundle-banner");
    const withCTA = bannerVariation === "with-cta";

    this.setState({
      withCTA,
    });
  }

  handleClick() {
    metrics.track("CTA Clicked", {
      location: "Bundle Banner",
      title: "Shop bundles",
      nonInteraction: false,
    });
  }

  render() {
    let { withCTA } = this.state;

    // Variation 1 / Variation 2

    return (
      <>
        <MessageBanner {...this.props} lightenCloseButton={true}>
          <BundleMessage>
            <p>
              <Text
                id="banners.bundle-banner.message"
                defaultMessage="Get {amount} off when you bundle {icon} or more products. "
                values={{
                  icon: <Emoji symbol="✌️" label="hand showing two fingers" />,
                  amount: <Currency value={10} round={true} />,
                }}
              />{" "}
              {withCTA ? (
                <BundleLink
                  onClick={this.handleClick.bind(this)}
                  to="/shop-bundles"
                >
                  <Text
                    id="banners.bundle-banner.shop-bundles"
                    defaultMessage="Shop bundles"
                  />
                </BundleLink>
              ) : (
                <Text
                  id="banners.bundle-banner.discount"
                  defaultMessage="Discount will be applied automatically."
                />
              )}
            </p>
          </BundleMessage>
        </MessageBanner>
      </>
    );
  }
}
