import React from "react";
import styled from "styled-components";
import MessageBanner from "../MessageBanner";
import { Icons } from "../../utils/svg";
import { Font, Color, rem, responsive } from "../../utils/style";
import Text from "../Text";

const BannerContent = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 12px;

  ${responsive.md`
    padding-top: 12px;
    padding-bottom: 14px;
  `}

  p {
    ${Font.circular}
    font-size: ${rem(16)};
    letter-spacing: 0;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 0;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: 28px;
    `}
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 28px;
  width: 28px;
  margin-right: 3px;

  ${responsive.md`
    margin-right: 12px;
  `}

  svg {
    height: 18px;
    width: 18px;

    ${responsive.md`
      height: 22px;
      width: 22px;
    `}

    path {
      fill: ${Color.white};
    }
  }
`;

export default class GiftBanner extends React.Component {
  constructor(props) {
    super(props);

    this.setGiftCardCode(props.code);
  }

  setGiftCardCode(code) {
    if (!code) return;

    const isSessionStorageAvailable =
      typeof window !== "undefined" && window.sessionStorage;
    if (isSessionStorageAvailable) {
      window.sessionStorage.setItem("gift_card_for_checkout", code);
    }
  }

  render() {
    return (
      <MessageBanner {...this.props}>
        <BannerContent>
          <IconWrapper>
            <Icons.Upvote />
          </IconWrapper>
          <p>
            <Text
              id="banners.gift.card"
              defaultMessage="Your gift card will be automatically applied in checkout."
            />
          </p>
        </BannerContent>
      </MessageBanner>
    );
  }
}
