import React from "react";
import styled from "styled-components";
import { responsive } from "../../../utils/style";
import { RitualLogos } from "../../../utils/svg";
import MagicLink from "../../MagicLink";

const FooterLogoWrapper = styled(MagicLink)`
  display: inline-block;

  &.no-mobile {
    display: none;
    ${responsive.sm`
      display: inline-block;
    `}
  }

  svg {
    height: 34px;

    path {
      transition: all 0.2s;
    }
  }
`;

const FooterLogo = ({ className }) => {
  return (
    <FooterLogoWrapper to="/" ariaLabel="Ritual" className={className}>
      <RitualLogos.RitualLogo />
    </FooterLogoWrapper>
  );
};
export default FooterLogo;
