import * as LDClient from "launchdarkly-js-client-sdk";
import merge from "lodash/merge";
import metrics from "./metrics";
import UAParser from "ua-parser-js";

let launchDarklyInstance = null;

class LaunchDarklyInstance {
  async initialize(userInfo, attributes = {}) {
    const params = new URLSearchParams(window.location.search);
    // If the ld_key query pararm is set, the key should be set from the param
    // instead of from Cloudflare.
    const key = await this._setUserKeyCookie(params.get("ld_key"));
    attributes = { ...attributes, ...this._getClientAttributes() };

    this._setUserFromInfo(userInfo, attributes);
    this.user.key = key || "";

    this.ldClient = LDClient.initialize(
      process.env.GATSBY_LAUNCHDARKLY_CLIENT_ID,
      this.user,
      {
        sendEventsOnlyForVariation: true,
      },
    );

    // A cache of feature keys and their values. Used to decide whether a value
    // has changed and an "LaunchDarkly Variation" event should be reported.
    this.variationMap = {};

    // Wait for Launch Darkly to load.
    return new Promise(resolve => {
      this.ldClient.on("ready", resolve);
    });
  }

  _setUserFromInfo(userInfo, attributes = {}) {
    const updatedUser = {
      firstName: userInfo.traits.first_name,
      lastName: userInfo.traits.last_name,
      email: userInfo.traits.email,
      anonymous: !userInfo.id,
      custom: {
        userId: userInfo.id,
        anonymousId: userInfo.anonymousId,
        cartId: userInfo.traits.cart_id,
        ...attributes,
      },
    };

    this.user = merge(this.user, updatedUser);
  }

  _resetUser(key) {
    this.user = {
      key,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      anonymous: true,
      custom: {
        userId: "",
        anonymousId: this.user.custom.anonymousId,
        cartId: undefined,
        ...this._getClientAttributes(),
      },
    };
  }

  _setUserFromAttributes(attributes) {
    this.user.custom = merge(this.user.custom, attributes);
  }

  setKey(key) {
    this.user.key = key;
    return this._setUserKeyCookie(key).then(() => {
      return this._identify();
    });
  }

  getKey() {
    return this.user.key;
  }

  _setUserKeyCookie(key, reset = false) {
    const hostname = process.env.GATSBY_CLOUDFLARE_HOST;
    if (!hostname) return undefined;

    let url = `${hostname}/set-ld-key-cookie`;
    if (key) {
      url += `?uuid=${key}`;
    } else if (reset) {
      url += `?reset=1`;
    }

    return fetch(url, {
      credentials: "include",
    })
      .then(response => {
        return response.json().then(data => {
          return data.data.uuid;
        });
      })
      .catch(() => {
        return undefined;
      });
  }

  _resetUserKeyCookie() {
    return this._setUserKeyCookie(undefined, true);
  }

  _identify(hash = null) {
    return this.ldClient.identify(this.user, hash);
  }

  identifyFromUserInfo(userInfo, attributes = {}, hash) {
    this._setUserFromInfo(userInfo, attributes);
    return this._identify(hash);
  }

  async resetUser() {
    const key = await this._resetUserKeyCookie();
    this._resetUser(key);
    return this._identify();
  }

  _getClientAttributes() {
    const referrer = document.referrer
      ? new URL(document.referrer).hostname
      : null;

    const params = new URLSearchParams(window.location.search);
    const uaParser = new UAParser(navigator.userAgent);

    return {
      referrer,
      browser: uaParser.getBrowser().name || null,
      browserVersion: uaParser.getBrowser().version || null,
      device: uaParser.getDevice().model || null,
      deviceResolution: window.innerWidth,
      utmCampaign: params.get("utm_campaign"),
      utmSource: params.get("utm_source"),
      utmContent: params.get("utm_content"),
      utmMedium: params.get("utm_medium"),
    };
  }

  attribute(attribute, defaultValue) {
    return this.user.custom[attribute] || defaultValue;
  }

  pushAttributes(attributes) {
    this._setUserFromAttributes(attributes);
    return this._identify();
  }

  variation(featureKey, defaultValue = false) {
    const value = this.ldClient.variation(featureKey, defaultValue);

    // If the value of the feature hasn't changed since the last variation
    // check, do not report a track event.
    if (this.variationMap[featureKey] !== value) {
      this.variationMap[featureKey] = value;

      metrics.track("LaunchDarkly Variation", {
        key: this.user.key,
        featureKey,
        featureValue: value,
      });
    }

    return value;
  }

  track(event, properties) {
    if (this.user.key) {
      this.ldClient.track(event, properties);
    }
  }
}

function initialize() {
  launchDarklyInstance = new LaunchDarklyInstance();
  return launchDarklyInstance.initialize(...arguments);
}

function identifyFromUserInfo() {
  if (launchDarklyInstance) {
    return launchDarklyInstance.identifyFromUserInfo(...arguments);
  }
}

function resetUser() {
  if (launchDarklyInstance) {
    return launchDarklyInstance.resetUser(...arguments);
  }
}

function attribute(attribute, defaultValue = false) {
  if (!launchDarklyInstance) return defaultValue;
  return launchDarklyInstance.attribute(attribute, defaultValue);
}

function pushAttributes() {
  if (launchDarklyInstance) {
    return launchDarklyInstance.pushAttributes(...arguments);
  }
}

function track() {
  if (launchDarklyInstance) {
    return launchDarklyInstance.track(...arguments);
  }
}

function setKey() {
  if (launchDarklyInstance) {
    return launchDarklyInstance.setKey(...arguments);
  }
}

function getKey() {
  if (!launchDarklyInstance) return undefined;
  return launchDarklyInstance.getKey();
}

export function variation(featureKey, defaultValue = false) {
  if (!launchDarklyInstance) return defaultValue;
  return launchDarklyInstance.variation(featureKey, defaultValue);
}

export default {
  initialize,
  identifyFromUserInfo,
  resetUser,
  attribute,
  pushAttributes,
  track,
  setKey,
  getKey,
};
