// Action Types
import { UPDATE_BANNER } from "./actions";

export default function reducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_BANNER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
