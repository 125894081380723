import React from "react";
import styled from "styled-components";

// Utils
import { rem, Color, responsive } from "../../../../utils/style";

// Components
import MagicLink from "../../../MagicLink";
import Highlighter from "../../Highlighter";
import Emoji from "../../../Emoji";

// Services
import intl from "../../../../services/intl";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { closeNavPanel } from "../../../../store/navigation/actions";
import userSelectors from "../../../../store/user/selectors";

// Styled Elements
const BrandLinksWrapper = styled.div`
  margin-top: 32px;

  ${responsive.md`
    margin-top: 40px;
  `}
`;

const BrandList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 32px;

  ${responsive.md`
    margin-bottom: 40px;
  `}
`;

const BrandListItem = styled.li`
  padding-bottom: 12px;
  width: fit-content;

  &:last-of-type {
    padding-bottom: 0;
  }

  ${responsive.md`
    padding-bottom: 8px;
  `}
`;

const BrandLinkTitle = styled.span`
  font-size: ${rem(16)};
  font-weight: 300;
  letter-spacing: 0px;
  line-height: ${rem(26)};
  margin-bottom: 0;

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}
`;

const ReferralLinkTitle = styled(BrandLinkTitle)`
  color: ${Color.ritualGreen};
  font-weight: 500;
`;

const Links = [
  {
    title: "Who We Are",
    label: "Who We Are",
    to: "/approach",
  },
  {
    title: "Ingredients",
    label: "Ingredients",
    to: "/ingredients",
  },
  {
    title: "Our Clinical Study",
    label: "Our Clinical Study",
    to: "/clinical-study",
  },
  {
    title: "The Journal",
    label: "The Journal",
    to: "/articles",
  },
];

const BrandLinks = props => {
  const { handleNavClick } = props;
  const authenticated = useSelector(userSelectors.isLoggedIn);

  const dispatch = useDispatch();

  const handleHover = () => {
    dispatch(closeNavPanel(1));
  };

  return (
    <BrandLinksWrapper>
      <BrandList>
        {Links.map((link, i) => {
          return (
            <BrandLink
              key={i}
              title={link.title}
              to={link.to}
              handleNavClick={handleNavClick}
              handleHover={handleHover}
            />
          );
        })}
        {authenticated && (
          <ReferralLink
            handleNavClick={handleNavClick}
            handleHover={handleHover}
          />
        )}
      </BrandList>
    </BrandLinksWrapper>
  );
};

const BrandLink = props => {
  const { title, to, handleNavClick, handleHover } = props;

  const handleClick = () => {
    const homeUrl = process.env.GATSBY_URL;
    handleNavClick({
      title,
      url: `${homeUrl}${to}`,
    });
  };

  return (
    <BrandListItem>
      <MagicLink
        to={to}
        onClick={handleClick}
        onMouseOver={handleHover}
        onFocus={handleHover}
      >
        <Highlighter>
          <BrandLinkTitle>{title}</BrandLinkTitle>
        </Highlighter>
      </MagicLink>
    </BrandListItem>
  );
};

const ReferralLink = props => {
  const { handleNavClick, handleHover } = props;
  const accountUrl = process.env.GATSBY_ACCOUNT_URL;

  const referralTitle = intl.t("nav.referral.title", "Get {amount}", {
    amount: intl.formatCurrency(30, { round: true }),
  });
  const handleClick = () => {
    handleNavClick({
      title: `${referralTitle} 💸`,
      url: `${accountUrl}/refer`,
    });
  };

  return (
    <BrandListItem>
      <MagicLink
        href={`${accountUrl}/refer`}
        target="_self"
        ariaLabel="Refer Friends"
        onClick={handleClick}
        onMouseOver={handleHover}
      >
        <Highlighter>
          <ReferralLinkTitle>
            {referralTitle} <Emoji symbol="💸" label="Money with wings emoji" />
          </ReferralLinkTitle>
        </Highlighter>
      </MagicLink>
    </BrandListItem>
  );
};

export default BrandLinks;
