import React from "react";
import styled, { css } from "styled-components";

// Utils
import { Color, hideScrollbars, rem, responsive } from "../../../utils/style";
import launchDarkly from "../../../utils/launchDarkly";
import { lockScroll } from "../../../utils/lockScroll";
import { Icons } from "../../../utils/svg";

// Components
import CategoryLink from "./CategoryLink";
import SlideOut from "./SlideOut";
import SlideOutLink from "./SlideOutLink";
import CloseButton from "./CloseButton";
import BrandLinks from "./BrandLinks";
import AccountLinks from "./AccountLinks";
import Highlighter from "../Highlighter";
import Ribbon from "../../global/Ribbon";
import BundleRibbon from "../../bundle/Ribbon";
import Text from "../../Text";

// Styled Elements
const SideNavContainer = styled.nav`
  position: fixed;
  top: 52px;
  width: 100vw;
  height: calc(100% - 52px);
  z-index: 10000;

  background-color: ${Color.white};

  &.open {
    opacity: 1;
    z-index: 10000;
    transition-delay: 0ms;
    visibility: visible;
    transition: visibility 1ms linear, opacity 400ms ease-out,
      z-index 400ms ease-in-out;
  }

  &.closed {
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: visibility 1ms linear 500ms, opacity 400ms ease-out 200ms,
      z-index 400ms ease-in-out 200ms;
  }

  ${responsive.sm`
    height: 100%;
    width: 50vw;
    top: 0;
    opacity: 1;
    z-index: 10000;

    &.open {
      opacity: 1;
      z-index: 10000;
      transform: translateX(0px);
      visibility: visible;
      transition: visibility 1ms linear, opacity 500ms ease-in-out, z-index 500ms ease-in-out, transform 500ms ease-out;
    }

    &.closed {
      opacity: 1;
      z-index: 10000;
      transform: translateX(-50vw);
      visibility: hidden;
      transition: visibility 1ms linear 500ms, opacity 500ms ease-in-out, z-index 500ms ease-in-out, transform 500ms ease-out;
    }
  `}

  ${responsive.md`
    width: 33vw;
  `}
`;

const SideNavContent = styled.div`
  position: relative;
  padding: 24px 20px;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  background-color: ${Color.white};

  overflow-y: ${p => (p.expanded ? "visible" : "scroll")};

  ${hideScrollbars}

  &.open {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition: transform 400ms ease-in-out 200ms, opacity 400ms ease-in-out 200ms;
  }

  &.closed {
    opacity: 0;
    visibility: hidden;
    transform: translateX(100px);
    transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
  }

  ${responsive.sm`
    overflow-y: visible;
    padding: 80px 50px;
    border-right: 1px solid #ccc;

    // Disable Mobile Animations
    &.open {
      transform: translateX(0);
      transition: none;
      visibility: visible;
    }

    &.closed {
      transform: translateX(0);
      transition: none;
      visibility: hidden;
    }
  `}

  ${responsive.md`
    padding: 80px 45px;
  `}

  ${responsive.lg`
    padding: 120px 80px;
  `}
`;

const SideNavFill = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
`;

const SideNavCloseButton = styled.div`
  display: none;
  position: absolute;
  right: 20px;
  top: 20px;

  ${responsive.sm`
    display: block;
    top: 25px;
    right: 30px
  `}
`;

const CategoryHoverLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 12px;

  ${responsive.md`
    margin-bottom: 16px;
  `}
`;

const MenuCategoryHover = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  margin: 0;
  color: ${Color.ritualBlue};
  font-size: ${rem(30)};
  font-weight: 500;
  letter-spacing: -0.4px;
  line-height: ${rem(36)};

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }
`;

const TransitionContainer = styled.div`
  opacity: 1;
  transform: translateY(0);

  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out,
    visibility 0s linear;

  &.dismissed {
    opacity: 0;
    visibility: hidden;
    transform: translateY(${p => p.dismissedPosition}px);
    transition: transform 500ms ease-in-out, opacity 500ms ease-in-out,
      visibility 0s linear 500ms;
  }

  &.selected {
    opacity: 1;
    visibility: visible;
    transform: translateY(${p => p.selectedPosition}px);
    transition: transform 500ms ease-in-out;
  }

  /* disable transition for non-mobile */
  ${responsive.sm`
    opacity: 1;
    transform: translateY(0);
    transition: none;

    &.dismissed {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    &.selected {
      opacity: 1;
      transform: translateY(0px);
    }
  `}
`;

const SlideOutHeader = styled.h2`
  color: rgba(20, 43, 111, 0.56);
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 300;
  margin: 0 0 8px;
`;

const BundleMessaging = styled.p`
  position: relative;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
  margin-bottom: 32px;
  max-width: 280px;

  ${responsive.md`
    max-width: 224px;
  `};

  ${responsive.lg`
    max-width: 270px;
    margin-bottom: 40px;
  `};

  svg {
    display: none;

    ${responsive.md`
      display: block;
      position: absolute;
      width: 21px;
      height: 48px;
      left: -32px;
      top: -32px;
      transform: scaleY(-1); /* CSS hack to flip image */
    `};
  }
`;

const FocusHandlerNav = styled.a``;

const MultiCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 16px;

    ${responsive.sm`
      margin-bottom: 24px;
    `}
  }
`;

const TransitionItem = styled.div`
  transform: ${p => `translateX(${10 + p.index * 2}vw)`};

  &.active {
    transform: translateX(0);
    transition: ${p =>
      `transform ${300 + p.index * 50}ms ease-in-out ${300 + p.index * 50}ms`};

    ${responsive.sm`
      transition: none;
    `}
  }
`;

const RibbonStyle = css`
  margin-left: 16px;

  height: 28px;
  letter-spacing: 0.67px;
  font-size: ${rem(10)};
  line-height: ${rem(16)};

  &::before,
  &::after {
    border-width: 14px 8px;
  }

  ${responsive.sm`
    height: 32px;
    letter-spacing: 0.8px;
    font-size: ${rem(12)};
    line-height: ${rem(20)};

    &::before,
    &::after {
      border-width: 16px 8px;
    }
  `}
`;

export default class SideNavNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasExistingSub: false,
    };

    this.setExistingSub = this.setExistingSub.bind(this);
    this.handleSecondaryNavFocusChange = this.handleSecondaryNavFocusChange.bind(
      this,
    );
    this.handleFocusOnBack = this.handleFocusOnBack.bind(this);
    this.sideNavRef = React.createRef();
  }

  componentDidMount() {
    const hasExistingSub = this.checkExisitngSub();

    this.setState({
      hasExistingSub,
    });
  }

  checkExisitngSub() {
    const hasExistingSub = launchDarkly.attribute("existingSubscriber", false);
    return hasExistingSub;
  }

  setExistingSub() {
    this.setState({
      hasExistingSub: this.checkExisitngSub(),
    });
  }

  handleSecondaryNavFocusChange() {
    // close secondary nav for keyboard users when focusing on category
    this.showSecondaryNav(undefined);
  }

  handleFocusOnBack() {
    this.props.backButtonRef.current.focus();
  }

  showSecondaryNav(secondaryNav) {
    this.props.updateSecondaryNav({
      secondaryNav: secondaryNav,
    });
  }

  hideSecondaryNav() {
    this.props.updateSecondaryNav({ secondaryNav: undefined });
  }

  closeMenu() {
    this.props.toggleSideNav();
  }

  render() {
    const {
      navOpen,
      categoryLinks,
      handleNavClick,
      authenticated,
      secondaryNav,
      menuFocusRef,
      handleClose,
    } = this.props;
    const { hasExistingSub } = this.state;

    if (this.sideNavRef.current) {
      lockScroll(navOpen, this.sideNavRef.current);
    }

    const womenTransitionContainerClassName = `${
      secondaryNav ? (secondaryNav === "women" ? "selected" : "dismissed") : ""
    }`;

    const menTransitionContainerClassName = `
      ${secondaryNav ? (secondaryNav === "men" ? "selected" : "dismissed") : ""}
    `;

    const kidsTransitionContainerClassName = `${
      secondaryNav ? (secondaryNav === "kids" ? "selected" : "dismissed") : ""
    }`;

    const bundleMessagingHeight = 72;

    const { bundles, men, women, kids, shopAll } = categoryLinks;
    const womenCategoryLinks = women[0];
    const menCategoryLinks = men[0];
    const kidsCategories = kids;

    return (
      <SideNavContainer className={navOpen ? "open" : "closed"}>
        <SideNavContent
          className={navOpen ? "open" : "closed"}
          expanded={secondaryNav}
          ref={this.sideNavRef}
        >
          <SideNavFill />
          {!secondaryNav && (
            <SideNavCloseButton>
              <CloseButton handleCloseClick={this.closeMenu.bind(this)} />
            </SideNavCloseButton>
          )}
          {/* Bundles Link */}
          <TransitionContainer
            className={secondaryNav ? "dismissed" : ""}
            dismissedPosition={-75}
          >
            <CategoryHoverLink>
              <CategoryLink
                title={bundles.title}
                path={bundles.url}
                menuFocusRef={menuFocusRef}
                handleNavClick={handleNavClick}
                onMouseOver={this.hideSecondaryNav.bind(this)}
                className={secondaryNav ? "dismissed" : ""}
              />
              <BundleRibbon contentStyle={RibbonStyle} />
            </CategoryHoverLink>
            {hasExistingSub && (
              <BundleMessaging className={secondaryNav ? "open" : ""}>
                <Text
                  id="nav.side.bundle-message"
                  defaultMessage="Turn your Ritual into a bundle and get a one-time discount"
                />
                <Icons.ArrowCurvedLg />
              </BundleMessaging>
            )}
          </TransitionContainer>

          {/* Women Category */}
          <TransitionContainer
            className={womenTransitionContainerClassName}
            dismissedPosition={-75}
            selectedPosition={
              hasExistingSub ? -50 - bundleMessagingHeight : -50
            }
          >
            <CategoryHoverLink>
              <Highlighter height={12}>
                <MenuCategoryHover
                  onMouseEnter={this.showSecondaryNav.bind(this, "women")}
                  onClick={this.showSecondaryNav.bind(this, "women")}
                  aria-haspopup="true"
                  aria-expanded={secondaryNav === "women" ? "true" : undefined}
                >
                  <Text
                    id="nav.side.menu-category.women"
                    defaultMessage="Women"
                  />
                </MenuCategoryHover>
              </Highlighter>
            </CategoryHoverLink>
          </TransitionContainer>

          {/* Women Category Secondary Panel */}
          <SlideOut
            expanded={!!secondaryNav}
            active={secondaryNav === "women"}
            closeMenu={this.closeMenu.bind(this)}
          >
            <SlideOutHeader>{womenCategoryLinks.title}</SlideOutHeader>
            {womenCategoryLinks.links.map((link, index) => {
              return (
                <SlideOutLink
                  title={link.title}
                  label={link.label}
                  url={link.url}
                  handleNavClick={handleNavClick}
                  key={index}
                />
              );
            })}
            <FocusHandlerNav
              href="#"
              onFocus={this.handleFocusOnBack}
              className="d-block d-sm-none"
            />
          </SlideOut>

          {/* Men Category */}
          <TransitionContainer
            className={menTransitionContainerClassName}
            dismissedPosition={secondaryNav === "women" ? 50 : -75}
            selectedPosition={
              hasExistingSub ? -100 - bundleMessagingHeight : -100
            }
          >
            <CategoryHoverLink>
              <Highlighter height={12}>
                <MenuCategoryHover
                  onMouseEnter={this.showSecondaryNav.bind(this, "men")}
                  onClick={this.showSecondaryNav.bind(this, "men")}
                  aria-haspopup="true"
                  aria-expanded={secondaryNav === "men" ? "true" : undefined}
                >
                  <Text id="nav.side.menu-category.men" defaultMessage="Men" />
                </MenuCategoryHover>
              </Highlighter>
            </CategoryHoverLink>
          </TransitionContainer>

          {/* Men Category Secondary Panel */}
          <SlideOut
            secondaryNav={secondaryNav}
            category="men"
            expanded={!!secondaryNav}
            active={secondaryNav === "men"}
            closeMenu={this.closeMenu.bind(this)}
          >
            <SlideOutHeader>{menCategoryLinks.title}</SlideOutHeader>

            {menCategoryLinks.links.map((link, index) => {
              return (
                <SlideOutLink
                  title={link.title}
                  label={link.label}
                  url={link.url}
                  handleNavClick={handleNavClick}
                  key={index}
                />
              );
            })}
            <FocusHandlerNav
              href="#"
              onFocus={this.handleFocusOnBack}
              className="d-block d-sm-none"
            />
          </SlideOut>

          {/* Kids Category */}
          <>
            <TransitionContainer
              className={kidsTransitionContainerClassName}
              dismissedPosition={50}
              selectedPosition={
                hasExistingSub ? -140 - bundleMessagingHeight : -140
              }
            >
              <CategoryHoverLink>
                <Highlighter height={12}>
                  <MenuCategoryHover
                    onMouseEnter={this.showSecondaryNav.bind(this, "kids")}
                    onClick={this.showSecondaryNav.bind(this, "kids")}
                    aria-haspopup="true"
                    aria-expanded={secondaryNav === "kids" ? "true" : undefined}
                  >
                    <Text
                      id="nav.side.menu-category.kids"
                      defaultMessage="Kids"
                    />
                  </MenuCategoryHover>
                </Highlighter>
                <Ribbon contentStyle={RibbonStyle}>New</Ribbon>
              </CategoryHoverLink>
            </TransitionContainer>

            {/* Kids Category Secondary Panel  */}
            <SlideOut
              secondaryNav={secondaryNav}
              category="kids"
              expanded={!!secondaryNav}
              active={secondaryNav === "kids"}
              closeMenu={this.closeMenu.bind(this)}
            >
              <MultiCategoryContainer>
                {kidsCategories.map((category, index) => {
                  return (
                    <TransitionItem
                      key={index}
                      index={index}
                      className={secondaryNav === "kids" ? "active" : ""}
                    >
                      <SlideOutHeader>{category.title}</SlideOutHeader>

                      {category.links.map((link, i) => {
                        return (
                          <TransitionItem
                            key={i}
                            index={i}
                            className={secondaryNav === "kids" ? "active" : ""}
                          >
                            <SlideOutLink
                              title={link.title}
                              label={link.label}
                              url={link.url}
                              handleNavClick={handleNavClick}
                              key={i}
                            />
                          </TransitionItem>
                        );
                      })}
                    </TransitionItem>
                  );
                })}
              </MultiCategoryContainer>
              <FocusHandlerNav
                href="#"
                onFocus={this.handleFocusOnBack}
                className="d-block d-sm-none"
              />
            </SlideOut>
          </>

          {/* Shop All Link Link */}
          <TransitionContainer
            className={secondaryNav ? "dismissed" : ""}
            dismissedPosition={75}
          >
            <CategoryLink
              title={shopAll.title}
              path={shopAll.url}
              handleNavClick={handleNavClick}
              onMouseOver={this.hideSecondaryNav.bind(this)}
              className={`${secondaryNav ? "dismissed" : ""} last-category`}
            />
          </TransitionContainer>

          <TransitionContainer
            className={`
              ${navOpen ? "open" : "closed"}
              ${secondaryNav ? "dismissed" : ""}
            `}
            dismissedPosition={100}
            onFocus={this.handleSecondaryNavFocusChange}
          >
            <BrandLinks
              handleNavClick={handleNavClick}
              onMouseOver={this.hideSecondaryNav.bind(this)}
              authenticated={authenticated}
              navOpen={navOpen}
            />

            <AccountLinks
              handleNavClick={handleNavClick}
              onMouseOver={this.hideSecondaryNav.bind(this)}
              authenticated={authenticated}
              navOpen={navOpen}
            />
            <FocusHandlerNav href="#" onFocus={handleClose} />
          </TransitionContainer>
        </SideNavContent>
      </SideNavContainer>
    );
  }
}
