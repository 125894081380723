import React from "react";
import { ConsentManager } from "@segment/consent-manager";
import intl from "../../services/intl";

const PreferencesDialogTitle = intl.t(
  "segment.consent.pref-title",
  "Website Data Collection Preferences",
);
const PreferencesDialogContent = intl.t(
  "segment.consent.pref-content",
  "We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.",
);
const CancelDialogTitle = intl.t(
  "segment.consent.cancel-title",
  "Are you sure you want to cancel?",
);
const CancelDialogContent = intl.t(
  "segment.consent.cancel-content",
  "Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Privacy Policy.",
);

export default class SegmentConsentManager extends React.Component {
  render() {
    return (
      <ConsentManager
        writeKey={process.env.GATSBY_SEGMENT_WRITE_KEY}
        otherWriteKeys={[
          process.env.GATSBY_CHECKOUT_SEGMENT_WRITE_KEY,
          process.env.GATSBY_ACCOUNT_SEGMENT_WRITE_KEY,
        ]}
        cdnHost="analytics.seg.ritual.com"
        cookieDomain={process.env.GATSBY_COOKIE_DOMAIN}
        defaultDestinationBehavior="enable"
        shouldRequireConsent={() => false}
        preferencesDialogTitle={PreferencesDialogTitle}
        preferencesDialogContent={PreferencesDialogContent}
        cancelDialogTitle={CancelDialogTitle}
        cancelDialogContent={CancelDialogContent}
      />
    );
  }
}
