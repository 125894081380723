import React from "react";
import styled from "styled-components";
import $ from "jquery";
import _ from "underscore";
import classNames from "classnames/bind";

// Utils
import { Color, media, rem, responsive } from "../../../utils/style";

// Redux
import { connect } from "react-redux";
import { updateNavigation } from "../../../store/navigation/actions";
import cartProductSelectors from "../../../store/cart-product/selectors";

// Components
import MagicLink from "../../MagicLink";
import CloseButton from "./CloseButton";
import RitualLogo from "../RitualLogo";
import CartIconLink from "./CartIconLink";
import { Icons } from "../../../utils/svg";

const SimpleNavPaths = [
  "/vitamins-for-women",
  "/vitamins-for-men",
  "/shop-vitamins",
  "/add-mens",
  "/add-womens",
];

// Styled Elements
const Wrapper = styled.div`
  position: fixed;
  z-index: 110;
  // Width excluding scrollbars.
  width: calc(100vw - (100vw - 100%));
`;

const NavContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  ${responsive.sm`
    width: calc(100vw - 48px);
  `}

  ${responsive.lg`
    width: calc(100vw - 80px);
    max-width: 1170px;
  `}
`;

const NavRow = styled.div`
  height: 100%;
  padding: 0 15px;

  ${responsive.sm`
    padding: 0;
  `}
`;

const TopNavElement = styled.nav`
  display: flex;
  border-top: 4px solid ${Color.ritualBlue};
  position: fixed;
  background-color: ${Color.white};
  z-index: 110;
  width: 100%;
  height: 52px;
  border-bottom: none;
  transition: border-bottom 200ms ease-in-out;

  .open {
    border-bottom: 1px solid rgba(255, 255, 255, 1);
  }

  &.scrolled {
    border-top: none;
    border-bottom: 1px solid rgba(204, 204, 204, 1);

    &.open {
      border-bottom: 1px solid rgba(255, 255, 255, 1);
    }
  }

  ${responsive.sm`
    transition: none;
    border-bottom: none;

    &.scrolled {
      border-top: none;
      border-bottom: 1px solid #ccc;

      &.open {
        border-bottom: 1px solid #ccc;
      }
    }
  `}

  ${responsive.md`
    height: 64px;
  `}
`;
const NavItemList = styled.ul`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;

const NavItem = styled.li`
  align-items: center;
  align-content: center;
  display: flex;
  height: 100%;
  position: relative;

  ${responsive.sm`
    .underscore-hover-element {
      background-color: ${Color.ritualYellow};
      width: 100%;
      height: 0px;
      left: 0;
      position: absolute;
      bottom: 0;
      transition: height 200ms ease-in-out;
    }

    [data-whatintent="mouse"] &:hover {
      .underscore-hover-element {
        height: 4px;
      }
    }
  `}
`;

const MenuItem = styled(NavItem)`
  ${media.mobile`
    visibility: visible;
    transform: translate(0, 0);
    transition: transform 500ms ease-in-out;

    &.open {
      visibility: hidden;
      transform: translate(-100vw, 0);
      transition: transform 500ms ease-in-out, visibility 1ms linear 500ms;
    }
  `}
`;

const LogoItem = styled(NavItem)`
  position: absolute;
  left: calc(50% - 25px);

  ${media.mobile`
    transition: left 400ms ease-in-out;
    visibility: visible;

    &.open {
      left: 6px;

      &.submenu {
        visibility: hidden;
        left: -65px;
        transition: left 400ms ease-in-out, visibility 1ms linear 400ms;
      }
    }
  `}
`;

const FeaturedItem = styled(NavItem)`
  display: none;

  ${responsive.sm`
    display: flex;
    position: absolute;
    right: 64px;
  `}
`;

const CartItem = styled(NavItem)`
  ${media.mobile`
    transition: transform 500ms ease-in-out;
    transform: translate(0, 0);

    &.open {
      transform: translate(-38px, 0);
    }
  `}
`;

const BackItem = styled(NavItem)`
  position: absolute;
  left: 40px;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: left 400ms ease-in-out, opacity 400ms ease-in-out,
    visibility 1ms ease-in-out 400ms;

  &.submenu {
    left: 0;
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transition: left 400ms ease-in-out 200ms, opacity 400ms ease-in-out 200ms,
      visibility 400ms ease-in-out 200ms;
  }

  ${responsive.sm`
    display: none;
  `}
`;

const CloseItem = styled(NavItem)`
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  transform: translate(17vw, 0);
  transition: transform 500ms ease-in-out, visibility 1ms linear 500ms;

  &.open {
    visibility: visible;
    transform: translate(0, 0);
    transition: transform 500ms ease-in-out, visibility 1ms linear;
  }

  ${responsive.sm`
    display: none;
  `};
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${responsive.sm`
    display: none;
  `}
`;

const MenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
  padding: 0;
`;

const MenuText = styled.p`
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  font-weight: 500;
  letter-spacing: 0px;
  line-height: ${rem(26)};
  margin: 0;
`;

const LogoWrapper = styled.div`
  svg {
    height: 16px;
    width: 51px;

    ${responsive.sm`
      height: 18px;
      width: 58px;
    `}

    ${responsive.md`
      height: 22px;
      width: 70px;
    `}
  }
`;

const BackButton = styled.button`
  background: none;
  border: none;
`;

const FeaturedPageLink = styled(MagicLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: ${rem(16)};
  font-weight: 500;
  letter-spacing: 0px;
  line-height: ${rem(26)};
`;

const CartIconWrapper = styled.div`
  position: relative;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TopNavComponent = class TopNav extends React.Component {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.homeUrl = process.env.GATSBY_URL || "//ritual.com";
  }

  componentDidMount() {
    setTimeout(() => {
      this.scrollHandler();
      this.handleScroll(window);
    }, 200);
  }

  componentWillUnmount() {
    $(window).off("scroll.navHandler");
  }

  scrollHandler() {
    let _this = this;
    let throttled = _.throttle(_this.handleScroll.bind(_this, window), 10);
    $(window).on("scroll.navHandler", throttled);
  }

  handleScroll(w) {
    let prevState = this.props.navigation.scrolled;
    if ($(w).scrollTop() >= 12 && !prevState) {
      this.props.dispatchUpdateNavigation({ scrolled: true });
    } else if ($(w).scrollTop() < 12 && prevState) {
      this.props.dispatchUpdateNavigation({ scrolled: false });
    }
  }

  isSimpleNav() {
    return SimpleNavPaths.includes(this.props.pathname);
  }

  handleLogoClick() {
    this.props.handleNavClick({
      title: "Ritual",
      url: this.homeUrl,
    });
  }

  handleCartIconClick() {
    this.props.handleNavClick({
      title: "Cart",
      url: `${this.homeUrl}/cart`,
    });
  }

  handleFeaturePageClick() {
    this.props.handleNavClick({
      title: "Who We Are",
      url: `${this.homeUrl}/approach`,
    });
  }

  handleBackClick() {
    const { panels } = this.props;
    let currentDepth = 0;
    for (let i = panels.length - 1; i >= 0; i--) {
      if (panels[i]) {
        currentDepth = i;
        break;
      }
    }
    this.props.closeNavPanel(currentDepth);
  }

  render() {
    const { isOpen, panels, toggleNavigation, navigation } = this.props;
    const { scrolled } = navigation;

    const topNavClassNames = classNames({
      open: isOpen,
      submenu: panels[1],
      scrolled,
    });

    const showFeaturedPage = !this.isSimpleNav();
    const showCartIcon =
      !this.isSimpleNav() || this.props.activeCartProductQuantity > 0;

    const menuButtonText = "Menu";

    return (
      <Wrapper>
        {this.props.children}
        <TopNavElement className={topNavClassNames}>
          <NavContainer>
            <NavRow>
              <NavItemList>
                {!this.isSimpleNav() && (
                  <MenuItem className={topNavClassNames}>
                    <MenuButton
                      onClick={this.props.toggleNavigation}
                      aria-haspopup="true"
                      aria-expanded={isOpen ? "true" : "false"}
                    >
                      <MenuText>{menuButtonText}</MenuText>
                    </MenuButton>
                    <span className="underscore-hover-element"></span>
                  </MenuItem>
                )}

                <LogoItem className={topNavClassNames}>
                  <LogoWrapper>
                    <RitualLogo
                      to="/"
                      title="Ritual"
                      handleNavigateClick={this.handleLogoClick.bind(this)}
                    />
                  </LogoWrapper>
                </LogoItem>

                <BackItem className={topNavClassNames}>
                  <BackButton onClick={this.handleBackClick}>
                    <Icons.ArrowRoundedLeft />
                  </BackButton>
                </BackItem>

                {showFeaturedPage && (
                  <FeaturedItem>
                    <FeaturedPageLink
                      to="/approach"
                      onClick={this.handleFeaturePageClick.bind(this)}
                      hrefLang="x-default"
                    >
                      Who We Are
                    </FeaturedPageLink>
                    <span className="underscore-hover-element"></span>
                  </FeaturedItem>
                )}

                {showCartIcon && (
                  <CartItem className={topNavClassNames}>
                    <CartIconWrapper>
                      <CartIconLink
                        className="path"
                        onClick={this.handleCartIconClick.bind(this)}
                      />
                    </CartIconWrapper>
                    <span className="underscore-hover-element"></span>
                  </CartItem>
                )}

                <CloseItem className={topNavClassNames}>
                  <CloseButtonWrapper>
                    <CloseButton handleCloseClick={toggleNavigation} />
                  </CloseButtonWrapper>
                </CloseItem>
              </NavItemList>
            </NavRow>
          </NavContainer>
        </TopNavElement>
      </Wrapper>
    );
  }
};

const mapStateToProps = state => ({
  navigation: state.navigation,
  activeCartProductQuantity: cartProductSelectors.activeCartProductQuantity(
    state,
  ),
});

export default connect(mapStateToProps, {
  dispatchUpdateNavigation: updateNavigation,
})(TopNavComponent);
