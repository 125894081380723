import { createSelector } from "reselect";
import i18nSelectors from "../i18n/selectors";

const staticData = state => state.staticData;

const contentfulProducts = createSelector(
  staticData,
  i18nSelectors.locale,
  (staticData, locale) => {
    return staticData.contentfulProducts.filter(contentfulProduct => {
      return contentfulProduct.node_locale === locale;
    });
  },
);

export default {
  contentfulProducts,
};
