import React from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

// Services
import intl from "../../../../services/intl";

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const TestImageModule = props => {
  const image = intl.cf(props.data.images.nodes, true);
  return (
    <ImageWrapper>
      <Img
        fluid={image.fluid}
        alt={image.description}
        style={{
          userSelect: "none",
          userDrag: "none",
          pointerEvents: "none",
          touchCallout: "none",
          width: "100%",
          height: "100%",
        }}
      />
    </ImageWrapper>
  );
};

export default props => (
  <StaticQuery
    query={componentQuery}
    render={data => <TestImageModule {...props} data={data} />}
  />
);

const componentQuery = graphql`
  query TestImageModule {
    images: allContentfulAsset(
      filter: { contentful_id: { eq: "1zmcKgHPtggFFLESVZc7Q6" } }
    ) {
      nodes {
        node_locale
        title
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
      }
    }
  }
`;
