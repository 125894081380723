import React from "react";
import styled from "styled-components";

// Utils
import { rem, responsive } from "../../utils/style";
import intl from "../../services/intl";

// Components
import MessageBanner from "../MessageBanner";
import Text from "../Text";
import Currency from "../Currency";

// Styled Elements
export const BundleMessage = styled.div`
  line-height: 20px;
  font-weight: 300;
  font-size: ${rem(16)};
  padding: 8px 0;

  p {
    margin-bottom: 0;
    text-align: left;

    ${responsive.md`
      text-align: center;
    `}

    em {
      font-style: normal;
      font-weight: 500;
    }
  }
`;

export default class BundleExistingBanner extends React.Component {
  render() {
    const ariaLabelCopy = intl.t(
      "banners.bundle-existing.aria",
      "Get {amount} off your next order when you add any product to your subscription. Discount applied in checkout.",
      {
        amount: intl.formatCurrency(10, { round: true }),
      },
    );

    return (
      <MessageBanner {...this.props} lightenCloseButton={true}>
        <BundleMessage aria-label={ariaLabelCopy}>
          <p>
            <em>
              <Text
                id="banners.bundle-existing-em"
                defaultMessage="Get {amount} off your next order"
                values={{
                  amount: <Currency value={10} round={true} />,
                }}
              />
            </em>{" "}
            <Text
              id="banners.bundle-existing.copy"
              defaultMessage="when you add any product to your subscription. Discount applied in checkout."
            />
          </p>
        </BundleMessage>
      </MessageBanner>
    );
  }
}
