import { createSelector } from "reselect";

const _meta = state => state.guests.meta;
const _byId = state => state.guests.byId;

const activeGuest = createSelector(
  _meta,
  _byId,
  (meta, byId) => byId[meta.activeId],
);

export default {
  activeGuest,
};
