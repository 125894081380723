import normalizer from "./normalizer";
import normalizedActions from "./actions";
import fetch from "../../../utils/fetch";

export default function(
  url,
  options,
  dispatch,
  modelType,
  associations,
  suppressStoreUpdates,
  retryCount = 3,
) {
  return new Promise((resolve, reject) => {
    normalizedActions.requestStart(dispatch, modelType);
    fetch(url, options, retryCount)
      .then(data => {
        if (!suppressStoreUpdates) {
          const normalizedData = normalizer.normalizeData(data);
          normalizedActions.addDataToStore(
            dispatch,
            normalizedData,
            associations,
          );
        }
        return data;
      })
      .then(data => {
        normalizedActions.requestSuccess(dispatch, modelType);
        resolve(data);
      })
      .catch(error => {
        normalizedActions.requestFailure(dispatch, modelType);
        reject(error);
      });
  });
}
