import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

function lock(target) {
  disableBodyScroll(target);
}

function unlock(target) {
  enableBodyScroll(target);
}

export function lockScroll(open, target = undefined) {
  open ? lock(target) : unlock(target);
}
