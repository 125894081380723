import React from "react";
import { Provider } from "react-redux";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

// Utils
import { kustomer as kustomerService } from "./src/utils/kustomer";
import { lux as luxService } from "./src/utils/lux";
import { analytics as analyticsService } from "./src/utils/analytics";
import { currentPageData } from "./src/utils/currentPageData";
import launchDarkly, { variation } from "./src/utils/launchDarkly";
import metrics from "./src/utils/metrics";

// Store
import { getStore } from "./src/store/createStore";
import { updatePreviousUrl } from "./src/store/navigation/actions";
import InitializeStore from "./src/components/global/InitializeStore";
import InitializeLocale from "./src/components/global/InitializeLocale";

// Animations
import { TweenMax } from "gsap";
import ScrollMagic from "scrollmagic";
import ScrollMagicGsapPlugin from "ScrollMagicGsapPlugin";

export const wrapRootElement = ({ element }) => {
  Bugsnag.start({
    apiKey: process.env.GATSBY_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.GATSBY_BUGSNAG_RELEASE_STAGE,
  });

  var ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = getStore();
  return (
    <ErrorBoundary>
      <InitializeStore store={store}>
        <Provider store={store}>{element}</Provider>
      </InitializeStore>
    </ErrorBoundary>
  );
};

export const onClientEntry = async () => {
  // Initializes ScrollMagic with the GSAP animations plugin.
  ScrollMagicGsapPlugin(ScrollMagic, TweenMax);

  // Synchronously gets the stored Segment user info. This ensures that waiting
  // for Segment does not delay rendering. If no user info is stored, we default
  // the id to "" and update the LaunchDarkly state after the initial render.
  const userInfo = analyticsService.getStoredUserInfo();

  // Delay rendering until Launch Darkly has been initialized. This ensures
  // that the initial content is rendered with correct feature values instead
  // of default values, which prevents flickering.
  await launchDarkly.initialize(userInfo);

  // Initialize all 3rd Party Scripts (Speedcurve, Segment, Talkable, Kustomer)
  luxService.initialize();
  if (typeof window._talkableq !== "undefined") {
    window._talkableq.push(["init", { site_id: "ritual" }]);
  }
  if (typeof Kustomer !== "undefined" && variation("kustomer-live-chat-home")) {
    Kustomer.init(process.env.GATSBY_KUSTOMER_API_KEY);
    kustomerService.start();
  }

  if (window._castle && typeof window._castle === "function") {
    window._castle("setAppId", process.env.GATSBY_CASTLE_APP_ID);
  }
};

export const onInitialClientRender = () => {
  // Identify User to Bugsnag
  const userInfo = analyticsService.getStoredUserInfo();

  let userData = {
    id: userInfo.id || userInfo.anonymousId,
    email: userInfo.traits.email || null,
    name: userInfo.traits.firstName
      ? `${userInfo.traits.firstName} ${userInfo.traits.lastName}`
      : null,
  };
  Bugsnag.setUser(userData.id, userData.email, userData.name);
};

export const wrapPageElement = ({ element, props }) => {
  // Identify branch name to Bugsnag
  let { netlify } = props.pageContext;

  if (!!Bugsnag && netlify) {
    Bugsnag.addMetadata("netlify", netlify);
  }

  const { locale, activeLocales } = props.pageContext;
  return (
    <InitializeLocale locale={locale} activeLocales={activeLocales}>
      {element}
    </InitializeLocale>
  );
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  // Reset the page label on transitions.
  currentPageData.label = null;
  luxService.onPreRouteUpdate(prevLocation);
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const pageLabel = currentPageData.label;

  if (prevLocation && prevLocation.pathname) {
    getStore().dispatch(updatePreviousUrl(prevLocation.pathname));
  }

  luxService.onRouteUpdate(prevLocation, pageLabel);
  if (typeof Kustomer !== "undefined" && variation("kustomer-live-chat-home")) {
    kustomerService.onRouteUpdate(location);
  }

  if (window._castle && typeof window._castle === "function") {
    window._castle("trackPageview");
  }

  if (!window.analytics || typeof window.analytics.page !== "function") {
    if (process.env.NODE_ENV === "development") {
      console.warn("Unable to locate analytics.js");
    }
    return;
  }

  metrics.page(pageLabel);
};
