// Action Types
import { UPDATE_REDIRECTS } from "./actions";

const initialState = {
  login: "/cart",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_REDIRECTS:
      return {
        ...state,
        ...action.redirects,
      };
    default:
      return state;
  }
}
