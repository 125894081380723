import React from "react";
import styled from "styled-components";
import { rem } from "../../utils/style";
import MessageBanner from "../MessageBanner";
import { connect } from "react-redux";

// Actions
import { addPendingCode } from "../../store/pending-code/actions";

const BannerWrapper = styled.div``;

export const PodcastMessage = styled.div`
  line-height: 20px;
  font-weight: 300;
  font-size: ${rem(16)};
  padding: 8px 0;

  p {
    margin-bottom: 0;
  }
  a {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
  }
`;

export class PodcastBanner extends React.Component {
  componentDidMount() {
    const { banner, dispatchAddPendingCode } = this.props;

    if (banner && banner.promoCode) {
      dispatchAddPendingCode(banner.promoCode);
    }
  }

  render() {
    const { banner } = this.props;

    if (!banner) return null;

    return (
      <BannerWrapper>
        <MessageBanner {...this.props}>
          <PodcastMessage>
            <div
              // for testing
              id="podcast-message"
              dangerouslySetInnerHTML={{
                __html: banner.content.childMarkdownRemark.html,
              }}
            />
          </PodcastMessage>
        </MessageBanner>
      </BannerWrapper>
    );
  }
}

export default connect(null, {
  // Pending Code Actions
  dispatchAddPendingCode: addPendingCode,
})(PodcastBanner);
