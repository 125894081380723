import React from "react";
import styled, { css } from "styled-components";

// Utils
import { Color, rem, responsive } from "../../../../utils/style";

// Services
import navigation from "../../../../services/navigation";

// Components
import Panel from "../Panel";
import Highlighter from "../../Highlighter";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { openNavPanel } from "../../../../store/navigation/actions";
import navigationSelectors from "../../../../store/navigation/selectors";

const SmallLink = css`
  font-weight: 500;
  letter-spacing: -0.2px;
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  margin-bottom: 8px;
`;

const LargeLink = css`
  font-weight: 500;
  letter-spacing: -0.4px;
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  margin-bottom: 12px;
`;

// Styled Elements
const Button = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;

  appearance: none;
  background: none;
  border: none;
  padding: 0;

  color: ${Color.ritualBlue};

  ${p => (p.depth ? SmallLink : LargeLink)}

  ${responsive.sm`
    ${LargeLink}
  `}
`;

const PanelButton = props => {
  const { title, panel, depth, panelCount, handleNavClick } = props;

  const panels = useSelector(navigationSelectors.panels);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openNavPanel(depth + 1, panel.key));
  };

  const expanded = navigation.isPanelOpened(panel.key, depth + 1)
    ? "true"
    : "false";

  return (
    <>
      <Button
        depth={depth}
        data-test-panel-button
        onClick={handleClick}
        onMouseOver={handleClick}
        aria-haspopup="true"
        aria-expanded={expanded}
      >
        <Highlighter height={12}>
          <span>{title}</span>
        </Highlighter>
      </Button>
      <Panel
        panelData={panel}
        panelCount={panelCount}
        depth={depth + 1}
        panels={panels}
        handleNavClick={handleNavClick}
      />
    </>
  );
};

export default PanelButton;
