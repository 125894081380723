import React from "react";
import styled from "styled-components";

// Utils
import { Color, hideScrollbars, responsive } from "../../../utils/style";
import { lockScroll } from "../../../utils/lockScroll";

// Components
import CloseButton from "./CloseButton";

// Styled Elements
const SlideOutWrapper = styled.div`
  z-index: -2; /* Hack to get child parent positioned behind parent */

  position: absolute;
  left: 0;
  top: 60px;
  bottom: 0;
  width: 0;

  display: flex;
  flex-direction: column;
  background-color: ${Color.white};

  visibility: hidden;
  overflow: hidden;

  ${hideScrollbars}

  opacity: 0;

  transition: transform 300ms ease-in-out, opacity 300ms linear 300ms,
    visibility 300ms linear 300ms, width 300ms linear 300ms;

  ${responsive.sm`
    top: 0;
  `}

  &.active {
    opacity: 1;
    visibility: visible;
    overflow-y: scroll;

    z-index: 0;
    transition: transform 300ms ease-in-out 300ms, opacity 600ms linear 300ms;

    ${responsive.sm`
      z-index: -1;
      transition: transform 300ms ease-in-out;
    `}
  }

  &.expanded {
    width: 100vw;
    padding: 24px 36px;

    ${responsive.sm`
        padding: 80px 50px;
        width: 50vw;
        transform: translateX(50vw);
    `};

    ${responsive.md`
        padding: 80px 45px;
        width: 33vw;
        transform: translateX(33vw);
    `};

    ${responsive.lg`
      padding: 120px 80px;
    `};
  }
`;

const TransitionItem = styled.div`
  transform: ${p => `translateX(${10 + p.index * 2}vw)`};

  &.active {
    transform: translateX(0);
    transition: ${p =>
      `transform ${300 + p.index * 50}ms ease-in-out ${300 + p.index * 50}ms`};

    ${responsive.sm`
      transition: none;
    `}
  }
`;

const SlideOutLinks = styled.div`
  position: relative;
  visibility: hidden;
  opacity: 0;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  transition: opacity 1s ease-in-out;
`;

const SlideOutEventBlocker = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  visibility: visible;

  &.active {
    visibility: hidden;
    transition: visibility 0s linear 500ms;
  }
`;

const SideNavCloseButton = styled.div`
  display: none;
  position: absolute;
  right: 20px;
  top: 20px;

  ${responsive.sm`
    display: block;
    top: 25px;
    right: 30px;
  `};
`;

export default class SlideOut extends React.Component {
  constructor() {
    super();

    this.slideOutRef = React.createRef();
  }

  render() {
    const { active, closeMenu, children, expanded } = this.props;

    if (this.slideOutRef.current) {
      lockScroll(active, this.slideOutRef.current);
    }

    return (
      <SlideOutWrapper
        className={`
              ${expanded ? "expanded" : ""}
              ${active ? "active" : ""}
            `}
        ref={this.slideOutRef}
      >
        <SideNavCloseButton>
          <CloseButton handleCloseClick={closeMenu} />
        </SideNavCloseButton>
        <SlideOutLinks className={active ? "active" : ""}>
          {children &&
            children.map((child, index) => {
              if (Array.isArray(child)) {
                return child.map((product, i) => {
                  return (
                    <TransitionItem
                      className={active ? "active" : ""}
                      index={i}
                      key={i}
                    >
                      {product}
                    </TransitionItem>
                  );
                });
              } else {
                return (
                  <TransitionItem
                    className={active ? "active" : ""}
                    index={index}
                    key={index}
                  >
                    {child}
                  </TransitionItem>
                );
              }
            })}
        </SlideOutLinks>
        <SlideOutEventBlocker className={active ? "active" : ""} />
      </SlideOutWrapper>
    );
  }
}
