import React from "react";
import styled, { css } from "styled-components";
import MagicLink from "../../../MagicLink";

// Utils
import { rem, responsive } from "../../../../utils/style";

// Components
import Highlighter from "../../Highlighter";
import PanelModule from "../PanelModule";

// Redux
import { useDispatch } from "react-redux";
import { closeNavPanel } from "../../../../store/navigation/actions";

const SmallLink = css`
  font-weight: 500;
  letter-spacing: -0.2px;
  font-size: ${rem(22)};
  line-height: ${rem(32)};
`;

const LargeLink = css`
  font-weight: 500;
  letter-spacing: -0.4px;
  font-size: ${rem(30)};
  line-height: ${rem(36)};
`;

// Styled Elements
const LinkWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: ${p => (p.depth ? `8px;` : `12px`)};

  ${responsive.sm`
    margin-bottom: 12px;
  `};
`;

const LinkComponent = styled(MagicLink)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;

  ${p => (p.depth ? SmallLink : LargeLink)}

  ${responsive.sm`
    ${LargeLink}
  `}

  margin-right: 16px;
`;

const PrimaryLink = props => {
  const { title, to, depth, module, handleNavClick } = props;

  const dispatch = useDispatch();

  const handleHover = () => {
    dispatch(closeNavPanel(depth + 1));
  };

  const handleClick = () => {
    handleNavClick({ title, url: to });
  };

  return (
    <LinkWrapper depth={depth}>
      <LinkComponent
        depth={depth}
        data-test-primary-link
        to={to}
        onClick={handleClick}
        onMouseOver={handleHover}
        onFocus={handleHover}
      >
        <Highlighter height={12}>
          <span>{title}</span>
        </Highlighter>
      </LinkComponent>
      {module && (
        <PanelModule name={module.name} moduleProps={module.props} {...props} />
      )}
    </LinkWrapper>
  );
};

export default PrimaryLink;
