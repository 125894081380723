import React from "react";
import { graphql, StaticQuery } from "gatsby";

import CyberMondayBanner from "./CyberMondayBanner";
import NewYearsBanner from "./NewYearsBanner";
import GiftBanner from "./GiftBanner";
import IdMeConfirmationBanner from "./IdMeConfirmationBanner";
import PodcastBanner from "./PodcastBanner";
import WelcomeBanner from "./WelcomeBanner";
import BundleBanner from "./BundleBanner";
import BundleExistingBanner from "./BundleExistingBanner";

import { BannerType } from "../../utils/bannerType";
import { variation } from "../../utils/launchDarkly";

// Services
import intl from "../../services/intl";

// Redux
import { connect } from "react-redux";
import {
  updateBannerType,
  setUtmCampaign,
  setGiftCardCode,
} from "../../store/banners/actions";

const componentQuery = graphql`
  query BannersComponentQuery {
    allContentfulBanner {
      nodes {
        node_locale
        content {
          childMarkdownRemark {
            html
          }
        }
        vanityUrl
        utmContent
        utmSource
        utmMedium
        utmCampaign
        promoCode
      }
    }
  }
`;

class Banners extends React.Component {
  constructor(props) {
    super(props);

    this.contentfulBanners = intl.cf(props.data.allContentfulBanner.nodes);
  }

  componentDidMount() {
    // If there's already a banner type set, we render from that banner type
    // instead of overriding it.
    if (this.props.bannerType) return;

    const params = new URLSearchParams(window.location.search);

    const utmContent = params.get("utm_content");
    const utmCampaign = params.get("utm_campaign");
    const giftCardCode = params.get("gc");

    if (utmCampaign) {
      this.props.dispatchSetUtmCampaign(utmCampaign, utmContent);
    }
    if (giftCardCode) {
      this.props.dispatchSetGiftCardCode(giftCardCode);
    }

    const podcastBanner = this.getPodcastBanner(utmCampaign, utmContent);

    // Prioritize podcast banners over all other banner types. If a podcast
    // banner is found, ignore other banner types.
    if (podcastBanner) {
      this.props.dispatchUpdateBannerType(BannerType.PODCAST);
      return;
    }

    // First check the query params and then feature flags to prioritize which
    // banner should be displayed.
    const typeFromParams = this.getBannerTypeFromParams(params);
    const typeFromFlags = this.getBannerTypeFromFlags(params);

    const bannerType = this.props.bannerType || typeFromParams || typeFromFlags;

    if (bannerType) {
      this.props.dispatchUpdateBannerType(bannerType);
    }
  }

  getPodcastBanner(campaign, content) {
    if (!campaign) return;

    return this.contentfulBanners.find(banner => {
      const campaignMatch =
        banner.utmCampaign.toLowerCase() === campaign.toLowerCase();

      // If the utm_campaign does not match, return false
      if (!campaignMatch) return false;

      // If there's no utm_content, return the first banner that matches the
      // utm_campaign.
      if (!content) return true;

      // Return the first banner that matches the campaign and content.
      return (
        banner.utmContent &&
        banner.utmContent.toLowerCase() === content.toLowerCase()
      );
    });
  }

  getBannerTypeFromParams(params) {
    if (params.get("gc")) {
      return BannerType.GIFT;
    }
    if (params.get("utm_campaign") === "pp") {
      return BannerType.WELCOME;
    }
  }

  getBannerTypeFromFlags() {
    if (variation("black-friday-2020")) {
      return BannerType.CYBER_MONDAY;
    }

    if (variation("new-years-promo")) {
      return BannerType.NEW_YEARS;
    }
    // If the variation returns the string "off", the flag is disabled.
    if (variation("bundle-banner", "off") !== "off") {
      return BannerType.BUNDLE;
    }
  }

  shouldHideBanner() {
    const { bannerType, hideBanner } = this.props;

    if (!hideBanner) return false;

    // If a banners are hidden on the page, only show banners that are
    // triggered by a user action (IDME).
    return bannerType !== BannerType.IDME;
  }

  render() {
    const { bannerType, utmCampaign, utmContent, giftCardCode } = this.props;

    const podcastBanner = this.getPodcastBanner(utmCampaign, utmContent);

    if (this.shouldHideBanner()) return null;

    switch (bannerType) {
      case BannerType.PODCAST:
        if (!podcastBanner) return null;
        return <PodcastBanner {...this.props} banner={podcastBanner} />;
      case BannerType.BUNDLE:
        return <BundleBanner {...this.props} />;
      case BannerType.GIFT:
        return <GiftBanner {...this.props} code={giftCardCode} />;
      case BannerType.WELCOME:
        return <WelcomeBanner {...this.props} />;
      case BannerType.CYBER_MONDAY:
        return <CyberMondayBanner {...this.props} hideCountdown={true} />;
      case BannerType.NEW_YEARS:
        return <NewYearsBanner {...this.props} />;
      case BannerType.IDME:
        return <IdMeConfirmationBanner {...this.props} />;
      case BannerType.BUNDLE_EXISTING:
        return <BundleExistingBanner {...this.props} />;
      default:
        return <div />;
    }
  }
}

const mapStateToProps = state => {
  const { banners } = state;
  return {
    bannerType: banners.bannerType,
    utmCampaign: banners.utmCampaign,
    utmContent: banners.utmContent,
    giftCardCode: banners.giftCardCode,
  };
};

export const ConnectedBanners = connect(mapStateToProps, {
  dispatchUpdateBannerType: updateBannerType,
  dispatchSetUtmCampaign: setUtmCampaign,
  dispatchSetGiftCardCode: setGiftCardCode,
})(Banners);

export default props => (
  <StaticQuery
    query={componentQuery}
    render={data => <ConnectedBanners {...props} data={data} />}
  />
);
