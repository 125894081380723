import React from "react";
import styled from "styled-components";
import { Color, Font, rem } from "../../../utils/style";

const TextNoticeWrapper = styled.p`
  ${Font.circular}
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  font-weight: 500;
  margin: 0;
  color: ${Color.ritualBlue};

  a {
    border-bottom: 1px solid ${Color.ritualBlue};
    transition: all 0.2s;

    &:hover {
      opacity: 0.56;
    }
  }
`;

const TextNotice = props => <TextNoticeWrapper {...props} />;
export default TextNotice;
