import React from "react";
import styled from "styled-components";
import { BBBLogos } from "../../../utils/svg";

const BBBLinkWrapper = styled.a.attrs({
  "aria-label": "Better Business Bureau",
})`
  flex-shrink: 0;
  display: block;
  position: relative;
  overflow: hidden !important;
  margin: 0px 24px 0px 0px !important;
  padding: 0px;
  width: 28px !important;
  height: 40px !important;

  img {
    width: 28px !important;
    height: 40px !important;
  }
`;

const BBBLink = () => {
  return (
    <BBBLinkWrapper
      target="_blank"
      href="https://www.bbb.org/us/ca/los-angeles/profile/vitamins-and-supplements/ritual-1216-911505#bbbseal"
    >
      <BBBLogos.BBBLogo alt="Ritual on the Better Business Bureau Website" />
    </BBBLinkWrapper>
  );
};

export default BBBLink;
