import React from "react";
import { useDispatch } from "react-redux";
import intl from "../../services/intl";

// Actions
import { setLocale, setActiveLocales } from "../../store/i18n/actions";

export default props => {
  let { locale, activeLocales, children } = props;

  if (!locale) locale = intl.defaultLocale.locale;

  intl.updateIntl(locale);
  const dispatch = useDispatch();
  dispatch(setActiveLocales(activeLocales));
  dispatch(setLocale(locale));

  return <>{children}</>;
};
