import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";
import Cart from "../cart/model";

export const ModelType = "CART_PRODUCT";

export default class CartProduct extends Model {
  // Attributes
  quantity: number = 0;
  productPrice: number = 0;
  taxAmount: number = 0;
  taxRate: number = 0;
  options: object = {};
  createdAt: Date = new Date();

  // Relationships
  cartId: string = ""; // BelongsTo
  planId: string = ""; // BelongsTo
  productId: string = ""; // BelongsTo

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);

    const relationships = input.relationships;
    this.cartId = this.getRelationshipId(relationships, "cart");
    this.planId = this.getRelationshipId(relationships, "plan");
    this.productId = this.getRelationshipId(relationships, "product");
  }

  serialize(updatedAttributes: { [key: string]: any }) {
    const { quantity } = this;
    const attributes = {
      quantity,
      ...updatedAttributes,
    };
    return this.serializeInternal(attributes);
  }
}
