import React from "react";
import styled, { css } from "styled-components";

import { Font, Color, rem, responsive } from "../../utils/style";

// Components
import MagicModal from "../MagicModal";

const modalStyle = css`
  padding: 40px 24px;

  ${responsive.sm`
    padding: 40px;
    width: 75vw;
  `};

  ${responsive.md`
    padding: 56px 80px;
  `};

  ${responsive.lg`
    padding: 56px 100px;
    max-width: 770px;
  `};
`;

const Title = styled.h3`
  margin: 0;
  font-weight: 500;

  padding-bottom: 24px;
  letter-spacing: -0.2px;
  font-size: ${rem(22)};
  line-height: ${rem(32)};

  ${responsive.sm`
    padding-bottom: 24px;
    letter-spacing: -0.4px;
    font-size: ${rem(30)};
    line-height: ${rem(36)};
  `}

  ${responsive.md`
    padding-bottom: 40px;
  `}

  border-bottom: 2px solid ${Color.ritualBlue};
`;

const Body = styled.p`
  ${Font.dutch};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  margin-bottom: 0;
  padding-top: 16px;

  ${responsive.md`
    padding-top: 24px;
  `}
`;

export default class PromotionDetailsModal extends React.Component {
  render() {
    const { isOpen, onRequestClose, title, body } = this.props;

    return (
      <MagicModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        slideOut={false}
        contentStyle={modalStyle}
      >
        <Title>{title}</Title>
        <Body>{body}</Body>
      </MagicModal>
    );
  }
}
