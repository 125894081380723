import React from "react";
import styled from "styled-components";
import { rem } from "../../utils/style";
import MessageBanner from "../MessageBanner";
import Text from "../Text";

const PodcastMessage = styled.div`
  line-height: 20px;
  font-weight: 300;
  font-size: ${rem(16)};
  padding: 8px 0;
`;

export default class WelcomeBanner extends React.Component {
  render() {
    return (
      <MessageBanner {...this.props}>
        <PodcastMessage>
          <Text
            id="banners.welcome-banner.heading"
            defaultMessage="Welcome, Westsider."
          />{" "}
          <span role="img" aria-label="Waving Emoji">
            👋
          </span>{" "}
          <br />
          <Text
            id="banners.welcome-banner.p"
            defaultMessage="Try Ritual happiness guaranteed: if you don't like it, the first
              month's on us."
          />
        </PodcastMessage>
      </MessageBanner>
    );
  }
}
