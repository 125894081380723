import React from "react";
import styled from "styled-components";

// Services
import intl from "../../../services/intl";

// Utils
import { rem, Color, responsive } from "../../../utils/style";

// Components
import MagicLink from "../../MagicLink";
import Highlighter from "../Highlighter";
import Emoji from "../../Emoji";

// Styled Elements
const BrandLinksWrapper = styled.div`
  margin-top: 32px;

  ${responsive.md`
    margin-top: 40px;
  `}
`;

const BrandList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 32px;

  ${responsive.md`
    margin-bottom: 40px;
  `}
`;

const BrandListItem = styled.li`
  padding-bottom: 12px;
  width: fit-content;

  &.open {
    transform: translateX(0);
    transition: transform 400ms ease-out ${p => p.index * 100 + 200}ms;
  }

  &.closed {
    transform: translateX(50px);
    transition: transform 400ms ease-out ${p => (4 - p.index) * 50}ms;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  ${responsive.sm`
    &.open {
      transform: translateX(0);
      transition: none;
    }

    &.closed {
      transform: translateX(0);
      transition: none;
    }
  `}

  ${responsive.md`
    padding-bottom: 8px;
  `}
`;

const BrandLink = styled(MagicLink)``;

const BrandLinkTitle = styled.span`
  font-size: ${rem(16)};
  font-weight: 300;
  letter-spacing: 0px;
  line-height: ${rem(26)};
  margin-bottom: 0;

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}
`;

const ReferralLinkTitle = styled(BrandLinkTitle)`
  color: ${Color.ritualGreen};
  font-weight: 500;
`;

export default class BrandLinks extends React.Component {
  get referralTitle() {
    return intl.t("nav.referral.title", "Get {amount}", {
      amount: intl.formatCurrency(30, { round: true }),
    });
  }

  handleBrandLinkClick(brandLink) {
    const homeUrl = process.env.GATSBY_URL || "//ritual.com";
    this.props.handleNavClick({
      title: brandLink.title,
      url: `${homeUrl}${brandLink.path}`,
    });
  }

  handleAccountLinkClick() {
    const accountUrl = process.env.GATSBY_ACCOUNT_URL || "//account.ritual.com";
    this.props.handleNavClick({
      title: `${this.referralTitle} 💸`,
      url: `${accountUrl}/refer`,
    });
  }

  render() {
    const { navOpen, authenticated = false, onMouseOver } = this.props;
    const accountUrl = process.env.GATSBY_ACCOUNT_URL || "//account.ritual.com";

    const brandLinks = [
      {
        title: intl.t("nav.links.approach", "Who We Are"),
        label: intl.t("nav.links.approach", "Who We Are"),
        path: "/approach",
      },
      {
        title: intl.t("nav.links.ingredients", "Ingredients"),
        label: intl.t("nav.links.ingredients", "Ingredients"),
        path: "/ingredients",
      },
      {
        title: intl.t("nav.links.clinical-study", "Our Clinical Study"),
        label: intl.t("nav.links.clinical-study", "Our Clinical Study"),
        path: "/clinical-study",
      },
      {
        title: intl.t("nav.links.articles", "The Journal"),
        label: intl.t("nav.links.articles", "The Journal"),
        path: "/articles",
      },
    ];

    return (
      <BrandLinksWrapper>
        <BrandList>
          {brandLinks.map((brandLink, i) => {
            return (
              <BrandListItem
                key={i}
                index={i}
                className={navOpen ? "open" : "closed"}
              >
                <BrandLink
                  to={brandLink.path}
                  onClick={this.handleBrandLinkClick.bind(this, brandLink)}
                  onMouseOver={onMouseOver}
                  ariaLabel={brandLink.label}
                  hrefLang="x-default"
                >
                  <Highlighter>
                    <BrandLinkTitle>{brandLink.title}</BrandLinkTitle>
                  </Highlighter>
                </BrandLink>
              </BrandListItem>
            );
          })}
          {authenticated && (
            <BrandListItem
              index={brandLinks.length}
              className={navOpen ? "open" : "closed"}
            >
              <BrandLink
                href={`${accountUrl}/refer`}
                target="_self"
                ariaLabel="Refer Friends"
                onClick={this.handleAccountLinkClick.bind(this)}
              >
                <Highlighter>
                  <ReferralLinkTitle>
                    {this.referralTitle}{" "}
                    <Emoji symbol="💸" label="Money with wings emoji" />
                  </ReferralLinkTitle>
                </Highlighter>
              </BrandLink>
            </BrandListItem>
          )}
        </BrandList>
      </BrandLinksWrapper>
    );
  }
}
