import Product, { ModelType } from "./model";
import normalizedActions from "../utils/normalized/actions";

export function addProductsFromGraphQL(productData) {
  return dispatch => {
    productData.forEach(data => {
      const product = new Product();
      product.deserialize(data);
      dispatch(addProduct(product));
    });
  };
}

export function addProductFromNormalizedData(data, associations) {
  return dispatch => {
    const product = new Product();
    product.deserialize(data, associations);
    dispatch(addProduct(product));
  };
}

export function addProduct(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().products,
      ModelType,
      data,
      associations,
    );
  };
}
