export function matchesAll(object, filters) {
  // Ensure the every filter matches an object value.
  return Object.keys(filters).every(key => matches(object[key], filters[key]));
}

function matches(value, filter) {
  if (Array.isArray(value) && Array.isArray(filter)) {
    // If both the value and filter are arrays, check if any of the values exist
    // in the filter array.
    return value.find(v => filter.includes(v));
  } else if (Array.isArray(value)) {
    // If only the value is an array, check if the value array includes the
    // filter
    return value.includes(filter);
  } else if (Array.isArray(filter)) {
    // If only the filter is an array, check if the filter array includes the
    // value.
    return filter.includes(value);
  } else {
    // If neither the value or the filter are array, simply check if they're
    // equal.
    return value === filter;
  }
}
