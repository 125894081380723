import RSVP from "rsvp";

const MockUser = {
  id: () => "",
  anonymousId: () => "",
  traits: () => {
    return {};
  },
};

export const analytics = {
  on: (...args) =>
    callIfPresent(window.analytics && window.analytics.on, { args }),

  /**
   * Waits for window.analytics to be available and window.analytics.ready to
   * be called before returning the Segment user info. If window.analytics is
   * not available when this function is called, we continuously poll until
   * it becomes available.
   *
   * @return {Object} the userInfo object { id:, anonymousId:, traits: }
   */
  waitForUserInfo() {
    return waitForAnalyticsAndGetUser();
  },

  /**
   * Synchronously gets the user info from stored Segment data without waiting
   * for window.analytics or window.analytics.ready. Most of the time (unless
   * Cookies are cleared or it's a first time load), the stored Segment data
   * will be accurate. If the stored data is not available, we return a user
   * with a mock anonymousId.
   *
   * @return {Object} the userInfo object { id:, anonymousId:, traits: }
   */
  getStoredUserInfo() {
    return getUserInfo();
  },
};

/**
 * Polls for windows.analytics and window.analytics.ready and resolves with
 * the Segment userInfo. Waiting for the ready function ensures that the
 * window.analytics.user function will be available, and that the userInfo
 * returned will be up to date.
 *
 * If window.analytics or window.analytics.ready never become available, then
 * this function will never resolve.
 *
 * @return {Promise<Object>} a promise that resolves to the userInfo
 */
function waitForAnalyticsAndGetUser() {
  if (window.analytics && typeof window.analytics.ready === "function") {
    return new RSVP.Promise(resolve => {
      window.analytics.ready(() => resolve());
    }).then(() => {
      console.log("analytics readied");
      return getUserInfo();
    });
  }

  return new RSVP.Promise(resolve => {
    setTimeout(() => {
      resolve(waitForAnalyticsAndGetUser());
    }, 100);
  });
}

function getUserInfo() {
  return {
    id: getUserId(),
    anonymousId: getAnonymousId(),
    traits: getUserTraits(),
  };
}

function getUser() {
  return callIfPresent(window.analytics && window.analytics.user, {
    defaultReturn: MockUser,
  });
}

/**
 * Retrieves Segment storage data from Local Storage based on the specified
 * storageKey. If the value is not available, the onNoValue function called
 * instead.
 *
 * @param {String} storageKey
 * @param {Function} onNoValue the function to call if the value is not
 *  available in storage
 * @return {*} The parsed JSON value
 */
function getSegmentValue(storageKey, onNoValue) {
  const storeVal = localStorage.getItem(storageKey);
  const json = safeParse(storeVal);
  if (json) {
    return json;
  }
  return onNoValue();
}

function getUserId() {
  return getSegmentValue("ajs_user_id", getUser().id);
}

function getAnonymousId() {
  return getSegmentValue("ajs_anonymous_id", getUser().anonymousId);
}

function getUserTraits() {
  return getSegmentValue("ajs_user_traits", getUser().traits);
}

/** Non-analytics related helper functions */

function callIfPresent(fn, options = {}) {
  const { args = [], defaultReturn } = options;
  const callee =
    typeof fn === "function"
      ? fn
      : () => {
          return defaultReturn;
        };
  return callee(...args);
}

function safeParse(json, defaultRet) {
  try {
    return JSON.parse(json);
  } catch {
    return defaultRet;
  }
}
