import metrics from "./metrics";

export const kustomer = {
  DEFAULT_CUSTOMER_ATTRIBUTES: ["email"],
  started: false,

  start() {
    if (
      (typeof Kustomer === "undefined" ||
        typeof Kustomer.start !== "function") &&
      !this.started
    ) {
      return;
    }
    Kustomer.start();
    this.started = true;

    // Wait to add the initial metrics until start has completed.
    setTimeout(() => {
      this._setInitialKustomerData();
    }, 3000);
  },

  _setInitialKustomerData() {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get("utm_source");
    const referrer = document.referrer;

    this.onRouteUpdate(window.location);

    const initialData = {
      siteStr: "ritual.com",
    };

    if (referrer) {
      initialData.referrerStr = referrer;
    }

    if (utmSource) {
      initialData.utmSoureStr = utmSource;
    }

    this._addConversationAttributes(initialData);
  },

  onRouteUpdate(location) {
    this._addConversationAttributes({
      currentUrlStr: this._stripQueryParams(location.href),
    });

    if (
      !window.analytics ||
      typeof Kustomer === "undefined" ||
      typeof Kustomer.isChatAvailable !== "function"
    ) {
      return;
    }

    Kustomer.isChatAvailable()
      .then(isAvailable => {
        metrics.track(isAvailable ? "Chat Available" : "Chat Unavailable");
      })
      .catch(error => {
        // Workaround for an issue were `isChatAvailable` throws an error when
        // hidden outside of business hours.
        if (error.message.includes("company hours not found")) {
          metrics.track("Chat Unavailable");
        }
        // Suppress other errors, as isChatAvailable will throw an error if
        // Kustomer is initialized but not started.
      });
  },

  _identifyCustomer(email, externalId) {
    if (typeof Kustomer.identify !== "function") {
      return Promise.resolve();
    }
    const identifyUrl = ".netlify/functions/kustomer-identify";
    const url = `${identifyUrl}?email=${email}&externalId=${externalId}`;

    return fetch(url)
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        response.text().then(hash => {
          Kustomer.identify(hash);
        });
      })
      .catch(() => {});
  },

  setKustomerDataFromUser(userInfo) {
    if (!window.analytics || typeof Kustomer === "undefined") return;

    const email = userInfo.traits.email;
    const externalId = userInfo.id;

    if (email && externalId) {
      this._identifyCustomer(email, externalId).then(() => {
        this._addCustomerAttributes({
          userIdStr: externalId,
          anonymousIdStr: userInfo.anonymousId,
          email: email,
          firstNameStr: userInfo.traits.first_name,
          lastNameStr: userInfo.traits.last_name,
        });
      });
    } else {
      this._addCustomerAttributes({
        anonymousIdStr: userInfo.anonymousId,
        userIdStr: externalId,
      });
    }
  },

  _addConversationAttributes(attributes) {
    if (
      typeof Kustomer === "undefined" ||
      typeof Kustomer.describeConversation !== "function"
    )
      return;

    const describeObject = { custom: {} };
    Object.keys(attributes).forEach(key => {
      if (this.DEFAULT_CUSTOMER_ATTRIBUTES.includes(key)) {
        describeObject[key] = attributes[key];
      } else {
        describeObject.custom[key] = attributes[key];
      }
    });
    Kustomer.describeConversation(describeObject);
  },

  _addCustomerAttributes(attributes) {
    if (
      typeof Kustomer === "undefined" ||
      typeof Kustomer.describeCustomer !== "function"
    )
      return;

    const describeObject = { custom: {} };
    Object.keys(attributes).forEach(key => {
      if (this.DEFAULT_CUSTOMER_ATTRIBUTES.includes(key)) {
        describeObject[key] = attributes[key];
      } else {
        describeObject.custom[key] = attributes[key];
      }
    });
    Kustomer.describeCustomer(describeObject);
  },

  _stripQueryParams(url) {
    return url.split("?")[0];
  },
};
