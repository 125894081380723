export function setLocale(locale) {
  return {
    type: "SET_LOCALE",
    payload: {
      locale,
    },
  };
}

export function setActiveLocales(activeLocales) {
  return {
    type: "SET_ACTIVE_LOCALES",
    payload: {
      activeLocales,
    },
  };
}
