import { createSelector } from "reselect";

const _byId = state => state.subscriptions.byId;
const _allIds = state => state.subscriptions.allIds;

const allSubscriptions = createSelector(_byId, _allIds, (byId, allIds) =>
  allIds.map(id => byId[id]),
);

const hasActiveSubscription = createSelector(_allIds, allIds => {
  return !!allIds.length;
});

const hasBundlePromoEligibleSubscription = createSelector(
  allSubscriptions,
  allSubscriptions => {
    if (!allSubscriptions.length) return false;

    return !!allSubscriptions.find(subscription => {
      const isBundlePromoEligible =
        !subscription.promotions ||
        !subscription.promotions.find(promotion => promotion.name === "bundle");
      return isBundlePromoEligible;
    });
  },
);

export default {
  allSubscriptions,
  hasActiveSubscription,
  hasBundlePromoEligibleSubscription,
};
