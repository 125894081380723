import React from "react";
import styled from "styled-components";
import $ from "jquery";
import _ from "underscore";

// Utils
import { rem, Color, responsive } from "../../../utils/style";
import metrics from "../../../utils/metrics";

// Redux
import { connect } from "react-redux";
import { updateNavigation } from "../../../store/navigation/actions";
import cartProductSelectors from "../../../store/cart-product/selectors";

// Components
import MagicLink from "../../MagicLink";
import CloseButton from "./CloseButton";
import RitualLogo from "../RitualLogo";
import CartIconLink from "./CartIconLink";
import { Icons } from "../../../utils/svg";
import Text from "../../Text";

const SimpleNavPaths = [
  "/vitamins-for-women",
  "/vitamins-for-men",
  "/shop-vitamins",
  "/add-mens",
  "/add-womens",
];

// Styled Elements
const Wrapper = styled.div`
  position: fixed;
  z-index: 110;
  // Width excluding scrollbars.
  width: calc(100vw - (100vw - 100%));
`;

const NavContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  ${responsive.sm`
    width: calc(100vw - 48px);
  `}

  ${responsive.md``}

  ${responsive.lg`
    width: calc(100vw - 80px);
    max-width: 1170px;
  `}
`;

const NavRow = styled.div`
  height: 100%;
  padding: 0 15px;

  ${responsive.sm`
    padding: 0;
  `}
`;

const TopNavElement = styled.nav`
  display: flex;
  border-top: 4px solid ${Color.ritualBlue};
  position: fixed;
  background-color: ${Color.white};
  z-index: 110;
  width: 100%;
  height: 52px;
  border-bottom: none;
  transition: border-bottom 200ms ease-in-out;

  .open {
    border-bottom: 1px solid rgba(255, 255, 255, 1);
  }

  &.scrolled {
    border-top: none;
    border-bottom: 1px solid rgba(204, 204, 204, 1);

    &.open {
      border-bottom: 1px solid rgba(255, 255, 255, 1);
    }
  }

  ${responsive.sm`
    transition: none;
    border-bottom: none;
    &.scrolled {
      border-top: none;
      border-bottom: 1px solid #ccc;

      &.open {
        border-bottom: 1px solid #ccc;
      }
    }
  `}

  ${responsive.md`
    height: 64px;
  `}
`;
const NavItemList = styled.ul`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;

const NavItem = styled.li`
  width: calc(100% / 3);
  height: 100%;
  align-items: center;
  align-content: center;
  display: flex;

  &.left-nav-element {
    justify-content: flex-start;

    transition: transform 500ms ease-in-out;
    transform: translate(0, 0);

    &.open {
      transform: translate(-100%, 0);
    }
  }

  &.center-nav-element {
    transition: transform 500ms ease-in-out;
    transform: translate(0, 0);

    &.open {
      transform: translate(-100%, 0);
      position: relative;
    }
  }

  &.right-nav-element {
    justify-content: flex-end;

    transition: transform 500ms ease-in-out;
    transform: translate(0, 0);

    &.open {
      transform: translate(calc(-38px), 0);
    }
  }

  /* No transitions for non-mobile screensizes */
  ${responsive.sm`
    &.left-nav-element {
      transition: none;
      transform: translate(0, 0);

      &.open {
        transform: translate(0, 0);
        border-bottom: none;
      }
    }

    &.center-nav-element {
      justify-content: center;
      transition: none;
      transform: translate(0, 0);
      flex-grow: 2;

      &.open {
        transform: translate(0, 0);
        border-bottom: none;
      }
    }

    &.right-nav-element {
      transition: none;
      transform: translate(0, 0);
      display: flex;

      &.open {
        transform: translate(0, 0);
        border-bottom: none;
      }
    }
  `}
`;

const TopNavCloseButton = styled.div`
  top: 0;
  display: flex;
  height: 100%;
  align-items: center;
  position: absolute;
  right: 0px;
  transition: transform 500ms ease-in-out;
  transform: translate(17vw, 0px);

  &.open {
    transform: translate(0px, 0px);
  }

  ${responsive.sm`
    display: none;
    transition: none;
    transform: none;
  `};
`;

const NavButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;

  align-self: center;
  width: 44px;
  height: 100%;
  cursor: pointer;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  ${responsive.sm`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .underscore-hover-element {
      background-color: ${Color.ritualYellow};
      width: 100%;
      height: 0px;
      left: 0;
      position: absolute;
      bottom: 0;
      transition: height 200ms ease-in-out;
    }

    [data-whatintent="mouse"] &:hover {
      .underscore-hover-element {
        height: 4px;
      }
    }
  `}
`;

const ShopIcon = styled.p`
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  font-weight: 500;
  letter-spacing: 0px;
  line-height: ${rem(26)};
  margin: 0;
`;

const LogoWrapper = styled.div`
  position: absolute;
  left: calc(50% - 25px);
  transition: left 400ms ease-in-out;

  &.open {
    left: 6px;
    border-bottom: none;
    transition: left 400ms ease-in-out 200ms;

    &.submenu {
      left: -65px;
      transition: left 400ms ease-in-out;
    }
  }

  ${responsive.sm`
    position: relative;
    left: initial;

    &.open {
      left: 0;

      &.submenu {
        left: 0;
        transition: none;
      }
    }
  `}

  svg {
    height: 16px;
    width: 51px;

    ${responsive.sm`
      height: 18px;
      width: 58px;
    `}

    ${responsive.md`
      height: 22px;
      width: 70px;
    `}
  }
`;

const BackButtonWrapper = styled.button`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  left: calc(50% - 25px);
  transition: left 400ms ease-in-out;
  background: none;
  border: none;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &.open {
    left: 40px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    border-bottom: none;
    transition: left 400ms ease-in-out, opacity 400ms ease-in-out,
      visibility 400ms ease-in-out;

    &.submenu {
      left: 6px;
      opacity: 1;
      visibility: visible;
      transition: left 400ms ease-in-out 200ms, opacity 400ms ease-in-out 200ms,
        visibility 400ms ease-in-out 200ms;
    }
  }

  ${responsive.sm`
    display: none;
    visibility: hidden;
  `}
`;

const RightNavElements = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${responsive.sm`
    width: 167px;
  `}

  ${responsive.md`
    width: 187px;
  `}
`;

const FeaturedPageLink = styled(MagicLink)`
  display: none;
  font-size: ${rem(16)};
  font-weight: 500;
  letter-spacing: 0px;
  line-height: ${rem(26)};
  height: 100%;

  ${responsive.sm`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .underscore-hover-element {
      background-color: ${Color.ritualYellow};
      width: 100%;
      height: 0px;
      left: 0;
      position: absolute;
      bottom: 0;
      transition: height 200ms ease-in-out;
    }

    [data-whatintent="mouse"] &:hover {
      .underscore-hover-element {
        height: 4px;
      }
    }
  `}
`;

const CartIconWrapper = styled.div`
  position: relative;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${responsive.sm`
    .underscore-hover-element {
      width: 100%;
      height: 0px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: ${Color.ritualYellow};
      transition: height 200ms ease-in-out;
    }

    [data-whatintent="mouse"] &:hover {
      .underscore-hover-element {
        height: 4px;
      }
    }
  `}
`;

export const TopNavComponent = class TopNav extends React.Component {
  constructor(props) {
    super(props);

    this.handleNavigateClick = this.handleNavigateClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.homeUrl = process.env.GATSBY_URL || "//ritual.com";
  }

  componentDidMount() {
    setTimeout(() => {
      this.scrollHandler();
      this.handleScroll(window);
    }, 200);
  }

  componentWillUnmount() {
    $(window).off("scroll.navHandler");
  }

  scrollHandler() {
    let _this = this;
    let throttled = _.throttle(_this.handleScroll.bind(_this, window), 10);
    $(window).on("scroll.navHandler", throttled);
  }

  handleScroll(w) {
    let prevState = this.props.navigation.scrolled;
    if ($(w).scrollTop() >= 12 && !prevState) {
      this.props.updateNavigationAction({ scrolled: true });
    } else if ($(w).scrollTop() < 12 && prevState) {
      this.props.updateNavigationAction({ scrolled: false });
    }
  }

  isSimpleNav() {
    return SimpleNavPaths.includes(this.props.pathname);
  }

  renderSideNavButton() {
    const buttonText = "Menu";
    return (
      <NavButton onClick={this.props.toggleSideNav}>
        <ShopIcon>{buttonText}</ShopIcon>
        <span className="underscore-hover-element"></span>
      </NavButton>
    );
  }

  renderRightNavElement() {
    const showFeaturedPage = !this.isSimpleNav();
    const showCartIcon =
      !this.isSimpleNav() || this.props.activeCartProductQuantity > 0;

    return (
      <RightNavElements>
        {showFeaturedPage && (
          <FeaturedPageLink
            to="/approach"
            onClick={this.handleFeaturePageClick.bind(this)}
            hrefLang="x-default"
          >
            <Text id="nav.top.featured-page" defaultMessage="Who We Are" />
            <span className="underscore-hover-element"></span>
          </FeaturedPageLink>
        )}

        {showCartIcon && (
          <CartIconWrapper>
            <CartIconLink
              className="path"
              onClick={this.handleCartIconClick.bind(this)}
            />
            <span className="underscore-hover-element"></span>
          </CartIconWrapper>
        )}
      </RightNavElements>
    );
  }

  handleNavigateClick() {
    if (this.props.navigation.open) {
      this.props.updateNavigationAction({ open: false });
    }
  }

  trackNavItemClicked(params) {
    metrics.track("Nav Item Clicked", {
      title: params.title,
      location: this.props.navigation.open ? "Open Nav" : "Closed Nav",
      url: params.url,
    });
  }

  handleLogoClick() {
    this.handleNavigateClick();
    this.trackNavItemClicked({
      title: "Ritual",
      url: this.homeUrl,
    });
  }

  handleCartIconClick() {
    this.handleNavigateClick();
    this.trackNavItemClicked({
      title: "Cart",
      url: `${this.homeUrl}/cart`,
    });
  }

  handleFeaturePageClick() {
    this.handleNavigateClick();
    this.trackNavItemClicked({
      title: "Who We Are",
      url: `${this.homeUrl}/approach`,
    });
  }

  handleBackClick() {
    this.props.updateSecondaryNav({ secondaryNav: undefined });
    setTimeout(() => {
      this.props.menuFocusRef.current.focus();
    }, 300);
  }

  render() {
    const { toggleSideNav, navigation, backButtonRef } = this.props;
    const { open, scrolled, secondary } = navigation;
    const navStateClass = open ? "open" : "";
    const secondaryNavStateClass = secondary ? "submenu" : "";
    const scrolledStateClass = scrolled ? "scrolled" : "";

    return (
      <Wrapper>
        {this.props.children}
        <TopNavElement className={`${scrolledStateClass} ${navStateClass}`}>
          <NavContainer>
            <NavRow>
              <NavItemList>
                <NavItem className={`left-nav-element ${navStateClass}`}>
                  {!this.isSimpleNav() && this.renderSideNavButton()}
                </NavItem>
                <NavItem className={`center-nav-element ${navStateClass}`}>
                  <LogoWrapper
                    className={`${navStateClass} ${secondaryNavStateClass}`}
                  >
                    <RitualLogo
                      to="/"
                      title="Ritual"
                      handleNavigateClick={this.handleLogoClick.bind(this)}
                    />
                  </LogoWrapper>
                  <BackButtonWrapper
                    className={`${navStateClass} ${secondaryNavStateClass}`}
                    ref={backButtonRef}
                    onClick={this.handleBackClick}
                  >
                    <Icons.ArrowRoundedLeft />
                  </BackButtonWrapper>
                </NavItem>
                <NavItem className={`right-nav-element ${navStateClass}`}>
                  {this.renderRightNavElement()}
                </NavItem>
                <li className="d-flex d-sm-none">
                  <TopNavCloseButton
                    className={`close-button-item ${navStateClass}`}
                  >
                    <CloseButton handleCloseClick={toggleSideNav} />
                  </TopNavCloseButton>
                </li>
              </NavItemList>
            </NavRow>
          </NavContainer>
        </TopNavElement>
      </Wrapper>
    );
  }
};

const mapStateToProps = state => ({
  navigation: state.navigation,
  activeCartProductQuantity: cartProductSelectors.activeCartProductQuantity(
    state,
  ),
});

export default connect(mapStateToProps, {
  updateNavigationAction: updateNavigation,
})(TopNavComponent);
