import { getStore } from "../store/createStore";
import productSelectors from "../store/product/selectors";
import navigationSelectors from "../store/navigation/selectors";
import { navigate as navigateGatsby } from "gatsby";
import intl from "./intl";

const ProductCategoryLinks = [
  {
    sku: "EFW60",
    title: "Multivitamin 18+",
    label: "Multivitamin 18+",
    url: "/products/essential-for-women-multivitamin",
  },
  {
    sku: "E50PLUS60",
    title: "Multivitamin 50+",
    label: "Multivitamin 50+",
    url: "/products/essential-multivitamin-for-women-over-50",
  },
  {
    sku: "EPRE60",
    title: "Prenatal Multivitamin",
    label: "Prenatal Multivitamin",
    url: "/products/essential-prenatal-multivitamin",
  },
  {
    sku: "EPOST60",
    title: "Postnatal Multivitamin",
    label: "Postnatal Multivitamin",
    url: "/products/essential-postnatal-multivitamin",
  },
  {
    sku: "EFM60",
    title: "Multivitamin 18+",
    label: "Multivitamin 18+",
    url: "/products/essential-multivitamin-for-men",
  },
  {
    sku: "EFM50PLUS60",
    title: "Multivitamin 50+",
    label: "Multivitamin 50+",
    url: "/products/essential-multivitamin-for-men-over-50",
  },
  {
    sku: "EFTG60",
    title: "Multivitamin Teen Girls",
    label: "Multivitamin Teen Girls",
    url: "/products/essential-multivitamin-for-teen-girls",
  },
  {
    sku: "EFKIDS90",
    title: "Multivitamin Kids 4+",
    label: "Multivitamin Kids 4+",
    url: "/products/essential-multivitamin-for-kids",
  },
  {
    sku: "EFTB60",
    title: "Multivitamin Teen Boys",
    label: "Multivitamin Teen Boys",
    url: "/products/essential-multivitamin-for-teen-boys",
  },
  {
    title: "Daily Shake 18+",
    label: "Daily Shake 18+",
    url: "/products/essential-postnatal-multivitamin",
  },
  {
    title: "Daily Shake 50+",
    label: "Daily Shake 50+",
    url: "/products/essential-multivitamin-for-men",
  },
  {
    title: "Daily Shake Prenatal & Postpartum",
    label: "Daily Shake Prenatal & Postpartum",
    url: "/products/essential-multivitamin-for-men-over-50",
  },
];

class NavigationService {
  initialize() {
    this._Navigation = this._getNavigation();
    this._NavigationSmall = this._getNavigationSmall();
  }

  get Navigation() {
    return this._Navigation;
  }

  get NavigationSmall() {
    return this._NavigationSmall;
  }

  get panels() {
    return navigationSelectors.panels(getStore().getState());
  }

  get isOpen() {
    return navigationSelectors.isOpen(getStore().getState());
  }

  get offset() {
    return getStore().getState().navigation.offset;
  }

  // Whether the panel is opened. An opened panel maybe not necessarily be
  // visible to the user (on mobile opened panels may be offscreen if a deeper
  // panel has been opened).
  isPanelOpened(key, depth) {
    return key === this.panels[depth];
  }

  // Whether the panel is expanded. Panels are considered expanded when they
  // have transitioned into place. Expanded panels are not necessarily open,
  // but open panels are always expanded.
  isPanelExpanded(_, depth) {
    return !!this.panels[depth];
  }

  // Whether the panel is active. The deeps open panels is considered to be the
  // active panel. On mobile, only the active panel is visible to the user.
  isPanelActive(key, depth) {
    const opened = this.isPanelOpened(key, depth);
    return opened && !this.panels[depth + 1];
  }

  // Whether the panel is the first panel in the stack.
  isTopPanel(_, depth) {
    return !depth;
  }

  _getNavigation() {
    return {
      panelCount: 3,
      panel: {
        key: "top",
        content: [
          {
            title: "Multivitamins",
            label: "Multivitamins",
            panel: {
              key: "multivitamins",
              header: "Multivitamins",
              content: [
                {
                  title: "Women",
                  label: "Women",
                  panel: {
                    key: "multivitamins-women",
                    header: "Women",
                    content: [
                      {
                        subheader: "Essential for Women",
                        links: this._getProductLinksFor(
                          {
                            genders: "Women",
                            subCategory: "Multivitamin",
                            ageRanges: ["18-49", "50+"],
                          },
                          true,
                        ),
                      },
                    ],
                  },
                },
                {
                  title: "Men",
                  label: "Men",
                  panel: {
                    key: "multivitamins-men",
                    header: "Men",
                    content: [
                      {
                        subheader: "Essential for Men",
                        links: this._getProductLinksFor(
                          {
                            genders: "Men",
                            subCategory: "Multivitamin",
                            ageRanges: ["18-49", "50+"],
                          },
                          true,
                        ),
                      },
                    ],
                  },
                },
                {
                  title: "Kids",
                  label: "Kids",
                  panel: {
                    key: "multivitamins-kids",
                    header: "Kids",
                    content: [
                      {
                        subheader: "Essential for Kids",
                        links: this._getProductLinksFor({
                          genders: "Kids & Teens",
                          subCategory: "Multivitamin",
                          ageRanges: "4-12",
                        }),
                      },
                      {
                        subheader: "Essential for Teens",
                        links: this._getProductLinksFor({
                          genders: "Kids & Teens",
                          subCategory: "Multivitamin",
                          ageRanges: "13-17",
                        }),
                      },
                      {
                        subheader: "",
                        links: [
                          this._getShopAllLinkFor({
                            genders: "Kids & Teens",
                            subCategory: "Multivitamin",
                          }),
                        ],
                      },
                    ],
                  },
                },
                this._getShopAllLinkFor({
                  subCategory: "Multivitamin",
                }),
              ],
            },
          },
          {
            title: "Protein",
            label: "Protein",
            panel: {
              key: "protein",
              header: "Protein",
              content: [
                {
                  subheader: "Essential Protein",
                  links: this._getProductLinksFor(
                    {
                      subCategory: "Protein",
                    },
                    true,
                  ),
                },
              ],
            },
          },
          {
            title: "Bundles",
            label: "Bundles",
            url: "/shop-bundles",
            module: {
              name: "BundleRibbon",
            },
          },
          {
            module: {
              name: "BundleMessaging",
            },
          },
        ],
      },
    };
  }

  _getNavigationSmall() {
    return {
      panelCount: 2,
      panel: {
        key: "top",
        content: [
          {
            title: "Multivitamins",
            label: "Multivitamins",
            panel: {
              key: "multivitamins",
              header: "Multivitamins",
              content: [
                {
                  subheader: "Essential for Women",
                  links: this._getProductLinksFor({
                    genders: "Women",
                    subCategory: "Multivitamin",
                    ageRanges: ["18-49", "50+"],
                  }),
                },
                {
                  subheader: "Essential for Men",
                  links: this._getProductLinksFor({
                    genders: "Men",
                    subCategory: "Multivitamin",
                    ageRanges: ["18-49", "50+"],
                  }),
                },
                {
                  subheader: "Essential for Kids",
                  links: this._getProductLinksFor({
                    genders: "Kids & Teens",
                    subCategory: "Multivitamin",
                    ageRanges: "4-12",
                  }),
                },
                {
                  subheader: "Essential for Teens",
                  links: this._getProductLinksFor({
                    genders: "Kids & Teens",
                    subCategory: "Multivitamin",
                    ageRanges: "13-17",
                  }),
                },
                {
                  subheader: "",
                  links: [
                    this._getShopAllLinkFor({
                      subCategory: "Multivitamin",
                    }),
                  ],
                },
              ],
            },
          },
          {
            title: "Protein",
            label: "Protein",
            panel: {
              key: "protein",
              header: "Protein",
              content: [
                {
                  subheader: "Essential Protein",
                  links: this._getProductLinksFor(
                    {
                      subCategory: "Protein",
                    },
                    true,
                  ),
                },
              ],
            },
          },
          {
            title: "Bundles",
            label: "Bundles",
            url: "/shop-bundles",
            module: {
              name: "BundleRibbon",
            },
          },
          {
            module: {
              name: "BundleMessaging",
            },
          },
        ],
      },
    };
  }

  _getShopAllLinkFor() {
    return {
      title: "Shop All",
      label: "Shop All",
      url: "/shop-vitamins",
    };
  }

  _getProductLinksFor(filters, includeShopAll) {
    const filteredProducts = productSelectors.filteredProducts(
      getStore().getState(),
      { filters },
    );

    const filteredLinks = ProductCategoryLinks.filter(link => {
      return filteredProducts.find(product => product.sku === link.sku);
    });

    if (includeShopAll) {
      filteredLinks.push(this._getShopAllLinkFor(filters));
    }

    return filteredLinks;
  }
}

const navigate = path => navigateGatsby(intl.localizePath(path));

const navigationService = new NavigationService();
export default navigationService;
export { NavigationService, navigate };
