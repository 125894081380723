export default function reducer(
  state = { locale: "en-US", activeLocales: ["en-US"] },
  action,
) {
  switch (action.type) {
    case "SET_LOCALE":
      return {
        ...state,
        locale: action.payload.locale,
      };
    case "SET_ACTIVE_LOCALES":
      return {
        ...state,
        activeLocales: action.payload.activeLocales,
      };
    default:
      return state;
  }
}
