// Action Types
export const INITIALIZE_CONTENTFUL_PRODUCT_DATA =
  "INITIALIZE_CONTENTFUL_PRODUCT_DATA";
export const INITIALIZE_PRODUCT_DATA = "INITIALIZE_PRODUCT_DATA";
export const INITIALIZE_PLAN_DATA = "INITIALIZE_PLAN_DATA";

export function initializeContentfulProductData(products) {
  return {
    type: INITIALIZE_CONTENTFUL_PRODUCT_DATA,
    contentfulProducts: products,
  };
}

export function initializeProductData(products) {
  return {
    type: INITIALIZE_PRODUCT_DATA,
    products,
  };
}

export function initializePlanData(plans) {
  return {
    type: INITIALIZE_PLAN_DATA,
    plans,
  };
}
