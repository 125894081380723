import React from "react";

export default class Emoji extends React.Component {
  render() {
    const props = this.props;
    return (
      <span
        className="emoji"
        role="img"
        aria-label={props.label ? props.label : ""}
        aria-hidden={props.label ? "false" : "true"}
      >
        {props.symbol}
      </span>
    );
  }
}
