import React from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Redux
import { connect } from "react-redux";
import { updateNavigation } from "../../store/navigation/actions";
import userSelectors from "../../store/user/selectors";

// Components -
import SkipLink from "./navigation/SkipLink";
import TopNav from "./navigation/TopNav";
import Overlay from "./navigation/Overlay";
import SideNav from "./navigation/SideNav";

// Utils
import metrics from "../../utils/metrics";

// Styled Elements
const NavigationContainer = styled.header``;

export class NavigationComponent extends React.Component {
  constructor(props) {
    super(props);

    this.toggleSideNav = this.toggleSideNav.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNavClick = this.handleNavClick.bind(this);
    this.updateSecondaryNav = this.updateSecondaryNav.bind(this);
    this.handleEscDown = this.handleEscDown.bind(this);
    this.menuFocusRef = React.createRef();
    this.backButtonRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleEscDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEscDown);
  }

  handleEscDown(e) {
    if (e.keyCode === 27 && this.props.navigation.open) {
      this.toggleSideNav();
    }
  }

  toggleSideNav(e) {
    const navOpen = this.props.navigation.open;
    // Programatically update focus into Side Nav, wait for transitions
    if (!navOpen) {
      setTimeout(() => {
        this.menuFocusRef.current.focus();
      }, 500);
    }
    this.props.updateNavigationAction({ open: !navOpen, secondary: undefined });
  }

  handleClose() {
    if (this.props.navigation.open) {
      this.toggleSideNav();
    }
  }

  handleNavClick({ title, url }) {
    this.props.updateNavigationAction({ open: false, secondary: undefined });

    metrics.track("Nav Item Clicked", {
      title: title,
      location: this.props.navigation.open ? "Open Nav" : "Closed Nav",
      url: url,
    });
  }

  updateSecondaryNav({ secondaryNav }) {
    this.props.updateNavigationAction({ secondary: secondaryNav });
  }

  getCategoryLinks() {
    let categories = {
      bundles: {
        title: intl.t("nav.categories.bundles", "Bundles"),
        label: intl.t("nav.categories.bundles", "Bundles"),
        url: "/shop-bundles",
      },
      women: [
        {
          title: intl.t("nav.categories.efw", "Essential for Women"),
          links: [
            {
              title: intl.t("nav.categories.mv18", "Multivitamin 18+"),
              label: intl.t("nav.categories.mv18", "Multivitamin 18+"),
              url: "/products/essential-for-women-multivitamin",
            },
            {
              title: intl.t("nav.categories.mv50", "Multivitamin 50+"),
              label: intl.t("nav.categories.mv50", "Multivitamin 50+"),
              url: "/products/essential-multivitamin-for-women-over-50",
            },
            {
              title: intl.t("nav.categories.prenatal", "Prenatal Multivitamin"),
              label: intl.t("nav.categories.prenatal", "Prenatal Multivitamin"),
              url: "/products/essential-prenatal-multivitamin",
            },
            {
              title: intl.t(
                "nav.categories.postnatal",
                "Postnatal Multivitamin",
              ),
              label: intl.t(
                "nav.categories.postnatal",
                "Postnatal Multivitamin",
              ),
              url: "/products/essential-postnatal-multivitamin",
            },
            {
              title: intl.t("nav.categories.shopall", "Shop All"),
              label: intl.t(
                "nav.categories.shopall-womens",
                "Women's Shop All",
              ),
              url: "/vitamins-for-women",
            },
          ],
        },
      ],
      men: [
        {
          title: intl.t("nav.categories.efm", "Essential for Men"),
          links: [
            {
              title: intl.t("nav.categories.mv18", "Multivitamin 18+"),
              label: intl.t("nav.categories.mv18", "Multivitamin 18+"),
              url: "/products/essential-multivitamin-for-men",
            },
            {
              title: intl.t("nav.categories.mv50", "Multivitamin 50+"),
              label: intl.t("nav.categories.mv50", "Multivitamin 50+"),
              url: "/products/essential-multivitamin-for-men-over-50",
            },
            {
              title: intl.t("nav.categories.shopall", "Shop All"),
              label: intl.t("nav.categories.shopall-mens", "Men's Shop All"),
              url: "/vitamins-for-men",
            },
          ],
        },
      ],
    };

    // Add Kid's Category
    categories.kids = [
      {
        title: intl.t("nav.categories.efk", "Essential for Kids"),
        links: [
          {
            title: intl.t("nav.categories.mvk4", "Multivitamin Kids 4+"),
            label: intl.t("nav.categories.mvk4", "Multivitamin Kids 4+"),
            url: "/products/essential-multivitamin-for-kids",
          },
        ],
      },
      {
        title: intl.t("nav.categories.efteens", "Essential for Teens"),
        links: [
          {
            title: intl.t(
              "nav.categories.mvteengirls",
              "Multivitamin Teen Girls",
            ),
            label: intl.t(
              "nav.categories.mvteengirls",
              "Multivitamin Teen Girls",
            ),
            url: "/products/essential-multivitamin-for-teen-girls",
          },
          {
            title: intl.t(
              "nav.categories.mvteenboys",
              "Multivitamin Teen Boys",
            ),
            label: intl.t(
              "nav.categories.mvteenboys",
              "Multivitamin Teen Boys",
            ),
            url: "/products/essential-multivitamin-for-teen-boys",
          },
        ],
      },
      {
        title: "", // Shop All
        links: [
          {
            title: intl.t("nav.categories.shopall", "Shop All"),
            label: intl.t("nav.categories.shopall-kids", "Kid's Shop All"),
            url: "/vitamins-for-kids",
          },
        ],
      },
    ];

    // Add global Shop All link
    categories.shopAll = {
      title: intl.t("nav.categories.shopall", "Shop All"),
      url: "/shop-vitamins",
    };
    return categories;
  }

  render() {
    const navOpen = this.props.navigation.open;
    const secondaryNav = this.props.navigation.secondary;

    return (
      <NavigationContainer id="navigation">
        <SkipLink />
        <TopNav
          pathname={this.props.pathname}
          updateSecondaryNav={this.updateSecondaryNav}
          toggleSideNav={this.toggleSideNav}
          menuFocusRef={this.menuFocusRef}
          backButtonRef={this.backButtonRef}
        >
          {/* Banner Elements */}
          {this.props.children}
        </TopNav>
        <Overlay navOpen={navOpen} onClick={this.handleClose} />
        <SideNav
          navOpen={navOpen}
          secondaryNav={secondaryNav}
          categoryLinks={this.getCategoryLinks()}
          toggleSideNav={this.toggleSideNav}
          handleClose={this.handleClose}
          handleNavClick={this.handleNavClick}
          authenticated={this.props.isLoggedIn}
          updateSecondaryNav={this.updateSecondaryNav}
          hideSecondaryNav={this.hideSecondaryNav}
          menuFocusRef={this.menuFocusRef}
          backButtonRef={this.backButtonRef}
        />
      </NavigationContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  navigation: state.navigation,
  isLoggedIn: userSelectors.isLoggedIn(state),
  ...ownProps,
});

export default connect(mapStateToProps, {
  updateNavigationAction: updateNavigation,
})(NavigationComponent);
