// Only one item can have the "default: true" key.
export default {
  'en-US': {
    default: true,
    path: ``,
    locale: `en-US`,
    dateFormat: `MM/DD/YYYY`,
    siteLang: `en-US`,
    ogLocale: `en_US`,
    currency: {
      type: "USD",
      prefix: "",
    },
  },
  'en-CA': {
    path: `ca`,
    locale: `en-CA`,
    dateFormat: `MM/DD/YYYY`,
    siteLang: `en-CA`,
    ogLocale: `en_CA`,
    currency: {
      type: "CAD",
      prefix: "CA",
    },
  },
};
