import { getStore } from "../createStore";
import observeStore from "../observeStore";

import { createSelector } from "reselect";

import cartSelectors from "../cart/selectors";
import cartProductSelectors from "../cart-product/selectors";
import couponSelectors from "../coupon/selectors";

import { applyPendingCode } from "../pending-code/actions";

let clearObserver = null;

const selector = createSelector(
  couponSelectors.activeCoupon,
  cartProductSelectors.activeNonStubCartProductQuantity,
  cartSelectors.isProcessing,
  state => state.pendingCode,
  (activeCoupon, nonStubProductQuantity, isCartProcessing, pendingCode) => {
    // We select the non-stub cart products to ensure that the cart actually
    // has a product on the backend. If no product exists, in the cart, a
    // coupon cannot be applied.
    const hasCartProduct = nonStubProductQuantity > 0;

    // If there's no currently active coupon, at least once product in the cart
    // and no in-flight cart requests, we apply can apply the pending code.
    const canApplyCoupon = !isCartProcessing && hasCartProduct && !activeCoupon;
    return {
      canApplyCoupon,
      pendingCode,
    };
  },
);

function start() {
  clearObserver = observeStore(selector, handleChange);
}

function stop() {
  clearObserver && clearObserver();
}

function handleChange(selectedState) {
  const { canApplyCoupon, pendingCode } = selectedState;
  // Apply the pending code if we can apply is and there's no current coupon.
  if (canApplyCoupon && pendingCode.code) {
    getStore().dispatch(applyPendingCode());
  }
}

export default {
  start,
  stop,
};
