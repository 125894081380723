import Cart, { ModelType } from "./model";
import cartSelectors from "./selectors";

// Normalized
import normalizedActions from "../utils/normalized/actions";
import fetch from "../utils/normalized/fetch";

// Cart Product
import CartProduct from "../cart-product/model";
import {
  addCartProduct,
  removeCartProduct,
  removeCartProducts,
} from "../cart-product/actions";
import cartProductSelectors from "../cart-product/selectors";

// Coupon
import couponSelectors from "../coupon/selectors";

// Plan
import planSelectors from "../plan/selectors";

// Utils
import {
  trackProductAdded,
  trackProductRemoved,
} from "../../utils/tracking/cart";
import {
  trackCouponAdded,
  trackCouponDenied,
  trackCouponRemoved,
} from "../../utils/tracking/coupon";
import errorReporter from "../../utils/errorReporter";
import { getBundleCartLimit } from "../../utils/bundle";

export function fetchCart() {
  return _createAsyncAction("fetchCart", dispatch => dispatch(_fetchCart()));
}

export function addProductToCart(planId, propertiesToTrack = {}) {
  return _createAsyncAction("addProductToCart", (dispatch, getState) => {
    const state = getState();
    const activeCart = cartSelectors.activeCart(state);

    // If there's no active cart, don't take the action.
    if (!activeCart) return Promise.resolve();

    const productLimit = getBundleCartLimit();

    const totalQuantity = cartProductSelectors.activeCartProductQuantity(state);
    if (totalQuantity > productLimit) {
      throw new Error("Too many products exist on the cart!");
    }

    // If we've reach the product limit, show the user a banner and do not add
    // the product.
    if (totalQuantity === productLimit) {
      dispatch(updateLimitBanner(true));
      return Promise.resolve();
    }

    // Get the existing cartProduct for the current planId.
    const cartProduct = _getActiveCartProduct(state, planId);

    // If a cart product for the current planId already exists, update the
    // quantity instead.
    if (cartProduct && cartProduct.quantity > 0) {
      return dispatch(
        _updateCartProductQuantity(
          activeCart.id,
          cartProduct,
          1,
          propertiesToTrack,
        ),
      );
    }

    const stubCartProduct = _createStubCartProduct(
      state,
      activeCart.id,
      planId,
    );
    // Add the stub cartProduct to the store.
    dispatch(addCartProduct(stubCartProduct));
    trackProductAdded(planId, propertiesToTrack);

    // Issue the request to add the product to the cart.
    return dispatch(_postCartProduct(activeCart.id, planId)).catch(error => {
      errorReporter.error("Failed to add product to cart", {
        cartId: activeCart.id,
        ...errorReporter.getMetadata(error),
      });
      return dispatch(_fetchCart());
    });
  });
}

export function removeProductFromCart(planId, removeAll = false) {
  return _createAsyncAction("removeProductFromCart", (dispatch, getState) => {
    const state = getState();
    const activeCart = cartSelectors.activeCart(state);

    // If there's no active cart, don't take the action.
    if (!activeCart) return Promise.resolve();

    // Get the existing cartProduct for the current planId.
    const cartProduct = _getActiveCartProduct(state, planId);

    // If there's no existing cartProduct, don't take the action.
    if (!cartProduct) return Promise.resolve();

    // if removeAll is false, only remove 1 quantity of product
    if (!removeAll && cartProduct.quantity > 1) {
      return dispatch(
        _updateCartProductQuantity(activeCart.id, cartProduct, -1),
      );
    }
    // Else, remove all quantities of product

    // Synchronously remove the cartProduct so that the change is immediately
    // visible to the user.
    dispatch(removeCartProduct(cartProduct.id));
    trackProductRemoved(planId, { quantity: cartProduct.quantity });

    // Issue the request to remove the product from the cart.
    return dispatch(_deleteCartProduct(activeCart.id, cartProduct.id)).catch(
      error => {
        errorReporter.error("Failed to remove product from cart", {
          cartId: activeCart.id,
          ...errorReporter.getMetadata(error),
        });
        return dispatch(_fetchCart());
      },
    );
  });
}

export function updateCartProductQuantity(planId, quantity) {
  return _createAsyncAction(
    "updateCartProductQuantity",
    (dispatch, getState) => {
      const state = getState();
      const activeCart = cartSelectors.activeCart(state);

      // If there's no active cart, don't take the action.
      if (!activeCart) return Promise.resolve();

      // Get the existing cartProduct for the current planId.
      const cartProduct = _getActiveCartProduct(state, planId);

      // If there's no existing cartProduct, don't take the action.
      if (!cartProduct) return Promise.resolve();

      // Change in product quantity
      const delta = quantity - cartProduct.quantity;

      return dispatch(
        _updateCartProductQuantity(activeCart.id, cartProduct, delta),
      );
    },
  );
}

/**
 * Adds multiple products to the cart. If the quantity of the products in the
 * cart + the quantity of the products being added exceeds the maximum number
 * of allowed products, the cart is cleared and the new products are added
 * instead. Otherwise the products are added to the existing cart.
 *
 * @param {Array<Object>} productData An array of objects with the keys
 *   { planId, quantity }.
 */
export function addProductsToCart(productData) {
  return _createAsyncAction("addProductsToCart", async (dispatch, getState) => {
    const state = getState();

    // If there's no active cart, don't take the action.
    const activeCart = cartSelectors.activeCart(state);
    if (!activeCart) return Promise.resolve();

    const productsToAddQuantity = productData.reduce((sum, data) => {
      sum += data.quantity;
      return sum;
    }, 0);

    const productLimit = getBundleCartLimit();
    const currentProductQuantity = cartProductSelectors.activeCartProductQuantity(
      state,
    );

    if (productsToAddQuantity + currentProductQuantity > productLimit) {
      // If the total quantity of the existing cart + the new items exceeded
      // the product limit, clear the cart and add the items instead.
      return dispatch(_clearCartAndAddProducts(productData));
    } else {
      // If the new products fit in the cart, add them to the existing cart
      // instead.
      return dispatch(_addProductsToExistingCart(activeCart, productData));
    }
  });
}

/**
 * Clears the current cart and adds the new products instead. Synchronously
 * the entire cart is cleared and replaced with stub CartProducts representing
 * the new items. Asynchronously multiple fetch requests are made to DELETE the
 * existing products and POST the new Cart Products. If any of the requests
 * fail, the action is abandoned and the cart is re-fetched.
 *
 * @param {Array<Object>} productData An array of objects with the keys
 *   { planId, quantity }.
 */
export function clearCartAndAddProducts(
  productData,
  hideWipeoutBanner = false,
) {
  return _createAsyncAction("clearCartAndAddProducts", dispatch =>
    dispatch(_clearCartAndAddProducts(productData, hideWipeoutBanner)),
  );
}

export function addCouponToCart(code) {
  return _createAsyncAction("addCouponToCart", async (dispatch, getState) => {
    const state = getState();

    // If there's no active cart, don't take the action.
    const activeCart = cartSelectors.activeCart(state);
    if (!activeCart) return;

    const payload = activeCart.serialize({
      discountCode: code,
    });

    return new Promise((resolve, reject) =>
      fetch(
        `carts/${activeCart.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/vnd.api+json",
          },
          body: JSON.stringify(payload),
          credentials: "include",
        },
        dispatch,
        ModelType,
      )
        .then(() => {
          trackCouponAdded(code);
          resolve();
        })
        .catch(error => {
          trackCouponDenied(code);
          reject(error);
        }),
    );
  });
}

export function removeCouponFromCart() {
  return _createAsyncAction("removeCouponFromCart", (dispatch, getState) => {
    const state = getState();

    // If there's no active cart, don't take the action.
    const activeCart = cartSelectors.activeCart(state);
    if (!activeCart) return Promise.resolve();

    // If there's no activeCoupon, do not dispatch the action.
    const activeCoupon = couponSelectors.activeCoupon(state);
    if (!activeCoupon) return Promise.resolve();

    const previousCode = activeCart.discountCode;

    const payload = activeCart.serialize({
      discountCode: undefined,
    });

    return fetch(
      `carts/${activeCart.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      },
      dispatch,
      ModelType,
    ).then(() => {
      trackCouponRemoved(previousCode);
    });
  });
}

export function addNormalizedCart(data, associations) {
  return dispatch => {
    const cart = new Cart();
    cart.deserialize(data);
    dispatch(_addCart(cart, associations));
  };
}

function _addCart(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().carts,
      ModelType,
      data,
      associations,
    );
  };
}

export function updateReplaceBanner(shouldDisplay) {
  return {
    type: "UPDATE_CART_REPLACE_BANNER",
    payload: {
      shouldDisplay,
    },
  };
}

export function updateLimitBanner(shouldDisplay) {
  return {
    type: "UPDATE_CART_LIMIT_BANNER",
    payload: {
      shouldDisplay,
    },
  };
}

export function updateWipeoutBanner(shouldDisplay) {
  return {
    type: "UPDATE_CART_WIPEOUT_BANNER",
    payload: {
      shouldDisplay,
    },
  };
}

// Helper Actions

function _fetchCart() {
  return dispatch => {
    dispatch(removeCartProducts());

    return fetch(
      "carts/active",
      {
        credentials: "include",
      },
      dispatch,
      ModelType,
    )
      .then(() => {
        dispatch(_updateCartFetchFailed(false));
      })
      .catch(error => {
        dispatch(_updateCartFetchFailed(true));
        errorReporter.error(
          "Failed to fetch cart",
          errorReporter.getMetadata(error),
        );
      });
  };
}

function _updateCartFetchFailed(fetchFailed) {
  return {
    type: "UPDATE_CART_FETCH_FAILED",
    payload: {
      fetchFailed,
    },
  };
}

function _updateCartProductQuantity(
  cartId,
  cartProduct,
  delta,
  propertiesToTrack,
) {
  return dispatch => {
    const currerntQuantity = cartProduct.quantity;
    const updatedQuantity = currerntQuantity + delta;

    const updatedCartProduct = cartProduct.cloneWithUpdates({
      quantity: updatedQuantity,
    });

    // Add the updated cart product to the store. The existing product will
    // be overwritten, since the id is unchanged.
    dispatch(addCartProduct(updatedCartProduct));

    const planId = cartProduct.planId;
    if (delta > 0) {
      // Track the new product added.
      trackProductAdded(planId, { quantity: delta, ...propertiesToTrack });
    } else {
      // Track the product removed.
      trackProductRemoved(planId, { quantity: Math.abs(delta) });
    }

    return dispatch(_patchCartProduct(cartId, updatedCartProduct)).catch(
      error => {
        errorReporter.error("Failed to update cart product quantity", {
          cartId,
          ...errorReporter.getMetadata(error),
        });
        return dispatch(_fetchCart());
      },
    );
  };
}

/**
 * Clears the current cart and adds the new products instead. Synchronously
 * the entire cart is cleared and replaced with stub CartProducts representing
 * the new items. Asynchronously multiple fetch requests are made to DELETE the
 * existing products and POST the new Cart Products. If any of the requests
 * fail, the action is abandoned and the cart is re-fetched.
 *
 * @param {Array<Object>} productData An array of objects with the keys
 *   { planId, quantity }.
 */
export function _clearCartAndAddProducts(
  productData,
  hideWipeoutBanner = false,
) {
  return async (dispatch, getState) => {
    const state = getState();

    // If there's no active cart, don't take the action.
    const activeCart = cartSelectors.activeCart(state);
    if (!activeCart) return Promise.resolve();

    productData.forEach(data => {
      const { planId, quantity } = data;

      // Remove all Cart Products from the store that are associated with the
      // id of the active cart. We do not remove stub products, since the
      // stubs represent the new products that are being added and must
      // remain visible to the user.
      dispatch(
        removeCartProducts({
          cartId: activeCart.id,
          id: id => !id.includes("stub"),
        }),
      );

      // Create a new Cart Product stub for each of the products that are being
      // added to the cart so that the change is immediately visible to the
      // user.
      const cartProductStub = _createStubCartProduct(
        state,
        activeCart.id,
        planId,
        quantity,
      );
      dispatch(addCartProduct(cartProductStub));
      trackProductAdded(planId);
    });

    // Notify the user that we've replaced all the items in their cart.
    if (!hideWipeoutBanner) {
      dispatch(updateWipeoutBanner(true));
    }

    const activeCartProducts = cartProductSelectors.activeCartProducts(state);

    try {
      for (let i = 0; i < activeCartProducts.length; i++) {
        const cartProduct = activeCartProducts[i];
        // Issue the DELETE request to the backend. The products are removed one
        // by one, with each iteration of this loop, as there's currently no
        // bulk deletion endpoint.
        await dispatch(
          _deleteCartProduct(
            activeCart.id,
            cartProduct.id,
            true /* suppressStoreUpdates */,
          ),
        );
      }
      for (let i = 0; i < productData.length; i++) {
        // Create the new products on the backend.
        const data = productData[i];
        await dispatch(
          _postCartProduct(activeCart.id, data.planId, data.quantity),
        );
      }
    } catch (error) {
      errorReporter.error("Failed to clearCartAndAddProducts", {
        cartId: activeCart.id,
        ...errorReporter.getMetadata(error),
      });
      return dispatch(_fetchCart());
    }
  };
}

/**
 * Adds the specified products to the current cart. Synchronously stub
 * CartProducts are created for any new items, and existing items will have
 * their quantities updated. Asynchronously multiple fetch requests are made to
 * PATCH the existing products and POST the new ones. If any of the requests
 * fail, the action is abandoned and the cart is re-fetched.
 *
 * @param {Cart} activeCart The active Cart model.
 * @param {Array<Object>} productData An array of objects with the keys
 *   { planId, quantity }.
 */
function _addProductsToExistingCart(activeCart, productData) {
  return async (dispatch, getState) => {
    const state = getState();

    const productsToCreate = [];
    const productsToUpdate = [];

    productData.forEach(data => {
      const { planId, quantity } = data;

      // Get the existing cartProduct for the planId.
      const cartProduct = _getActiveCartProduct(state, planId);

      // If the Cart Product for the planId currently exists in the store,
      // update its quantity instead of the creating a new stub product.
      if (cartProduct) {
        const currentQuantity = cartProduct.quantity;
        const updatedCartProduct = cartProduct.cloneWithUpdates({
          quantity: currentQuantity + quantity,
        });
        dispatch(addCartProduct(updatedCartProduct));
        trackProductAdded(planId);
        productsToUpdate.push(updatedCartProduct);
      } else {
        // If the product does not exist, create a stub instead.
        const cartProductStub = _createStubCartProduct(
          state,
          activeCart.id,
          planId,
          quantity,
        );
        dispatch(addCartProduct(cartProductStub));
        trackProductAdded(planId);
        productsToCreate.push(data);
      }
    });

    try {
      for (let i = 0; i < productsToUpdate.length; i++) {
        // If this is last product to update and there are no products to
        // create, we know this is the last request.
        const isLastRequest =
          i === productsToUpdate.length - 1 && !productsToCreate.length;

        // PATCH the quantity update any existing items.
        const updateCartProduct = productsToUpdate[i];
        await dispatch(
          _patchCartProduct(
            activeCart.id,
            updateCartProduct,
            !isLastRequest /* suppressStoreUpdates */,
          ),
        );
      }
      for (let i = 0; i < productsToCreate.length; i++) {
        // POST the creation of any new products.
        const productData = productsToCreate[i];
        await dispatch(
          _postCartProduct(
            activeCart.id,
            productData.planId,
            productData.quantity,
          ),
        );
      }
    } catch (error) {
      errorReporter.error("Failed to addProductsToExistingCart", {
        cartId: activeCart.id,
        ...errorReporter.getMetadata(error),
      });
      return dispatch(_fetchCart());
    }
  };
}

function _postCartProduct(cartId, planId, quantity = 1) {
  return (dispatch, getState) => {
    const state = getState();

    const product = planSelectors.productForPlanId(state, { id: planId });

    const payload = {
      plan_id: planId,
      product_id: product.id,
      quantity: quantity,
    };

    return fetch(
      `carts/${cartId}/cart_product`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        credentials: "include",
      },
      dispatch,
      ModelType,
      // Pass the planId and productId associations. This ensures that the
      // new CartProduct created on the backend will be tied o the stub above.
      {
        cart_products: {
          planId: planId,
          productId: product.id,
        },
      },
    );
  };
}

function _patchCartProduct(cartId, updatedCartProduct, suppressStoreUpdates) {
  return dispatch => {
    const payload = updatedCartProduct.serialize();

    return fetch(
      `carts/${cartId}/cart_product/${updatedCartProduct.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
        body: JSON.stringify(payload),
        credentials: "include",
      },
      dispatch,
      ModelType,
      undefined /* associations */,
      suppressStoreUpdates,
    );
  };
}

function _deleteCartProduct(
  cartId,
  cartProductId,
  suppressStoreUpdates = false,
) {
  return dispatch => {
    return fetch(
      `carts/${cartId}/cart_product/${cartProductId}`,
      {
        method: "DELETE",
        credentials: "include",
      },
      dispatch,
      ModelType,
      undefined /* associations */,
      suppressStoreUpdates,
    );
  };
}

// Helpers

function _createAsyncAction(actionName, action) {
  return async (dispatch, getState) => {
    if (cartSelectors.isProcessing(getState())) {
      errorReporter.warn(`Attempted to ${actionName} while processing`);
      return Promise.resolve();
    }

    dispatch({ type: "CART_ACTION_START" });
    return action(dispatch, getState).finally(() => {
      dispatch({ type: "CART_ACTION_END" });
    });
  };
}

/**
 * Create a stub cartProduct and add it to the cart so that the addition is
 * immediately visible to the user.
 */
function _createStubCartProduct(state, cartId, planId, quantity = 1) {
  const plan = planSelectors.planForId(state, { id: planId });
  const product = planSelectors.productForPlanId(state, { id: planId });

  // If there's no plan or matching product, don't take the action.
  if (!plan || !product) {
    throw new Error("No plan or product found!");
  }

  const stubCartProduct = new CartProduct();
  stubCartProduct.quantity = quantity;
  stubCartProduct.cartId = cartId;
  stubCartProduct.planId = plan.id;
  stubCartProduct.productId = product.id;
  stubCartProduct.productPrice = plan.amount;
  return stubCartProduct;
}

/**
 *
 * Retrieve all cartProducts that match the specified planId. There should only
 * ever be 0 or 1 products matching this id.
 */
function _getActiveCartProduct(state, planId) {
  const cartProducts = cartProductSelectors.activeCartProductsForPlan(state, {
    planId,
  });

  if (cartProducts.length > 1) {
    // TODO: Clear cart on error.
    throw new Error(`Multiple products found for planId: ${planId}!`);
  }
  return cartProducts[0];
}
