import React from "react";
import styled from "styled-components";

// Utils
import { rem, responsive, Color } from "../../utils/style";
import { variation } from "../../utils/launchDarkly";
import { Icons } from "../../utils/svg";
import intl from "../../services/intl";

// Components
import MessageBanner from "../MessageBanner";
import PromotionDetailsModal from "./PromotionDetailsModal";

const BannerContent = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: center;

  ${responsive.sm`
    padding: 17px 0;
  `}

  ${responsive.md`
    justify-content: center;
  `}
`;

const BannerText = styled.div`
  display: inline;

  p {
    display: inline;
    font-weight: 300;
    font-size: ${rem(16)};
    line-height: ${rem(22)};
    margin-bottom: 0;

    span {
      display: inline-block;
      text-align: left;
      font-weight: 300;
      font-size: ${rem(16)};
      line-height: ${rem(22)};
      min-width: 105px;
      width: 105px;
    }
  }

  ${responsive.md`
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: ${rem(18)};

      span {
        font-size: ${rem(18)};
        min-width: 116px;
        width: 116px;
      }
    }
  `}
`;

const TooltipButton = styled.button`
  background: transparent;
  border: none;
  opacity: 0.56;
  height: 16px;
  width: 16px;
  display: inline;
  position: relative;
  padding: 0;
  margin-left 8px;
  vertical-align: text-bottom;

  &:hover {
    opacity: 1;
  }

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  svg {
    position: absolute;
    height: 16px;
    width: 16px;
    margin-left: 1px;
    top: 0;
    left: 0;

    path {
      stroke: ${Color.white};

      // Style the dot on the i.
      &.icon-info-i-dot {
        stroke: none;
        fill: ${Color.white};
      }
    }
  }
`;

export default class NewYearsBanner extends React.Component {
  constructor() {
    super();

    this.state = {
      newYearsPromoEnabled: false,
      modalOpen: false,
    };

    this.titleText = intl.t("banners.new-years.title", "Promo Details");
    this.bodyText = intl.t(
      "banners.new-years.body",
      `This offer expires on 1/5/2021 at 11:59PM PDT and cannot be combined with other offers, discounts, or applied to prior purchases. Discount amount limited to {amount} per subscription.`,
      { amount: intl.formatCurrency(30, { round: true }) },
    );
  }

  componentDidMount() {
    const newYearsPromoEnabled = variation("new-years-promo");

    this.setState({
      newYearsPromoEnabled,
    });
  }

  openModal() {
    this.setState({
      modalOpen: true,
    });
  }

  closeModal() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    const { newYearsPromoEnabled, modalOpen } = this.state;

    if (!newYearsPromoEnabled) return null;

    const bannerText = intl.t(
      "banners.new-years.text",
      `Start 2021 off right with 21% off your Ritual. Discount ends soon and is auto-applied at checkout`,
    );

    return (
      <MessageBanner {...this.props}>
        <BannerContent>
          <BannerText>
            <p dangerouslySetInnerHTML={{ __html: bannerText }} />
            <TooltipButton onClick={this.openModal.bind(this)}>
              <Icons.Info />
            </TooltipButton>
          </BannerText>
        </BannerContent>

        <PromotionDetailsModal
          isOpen={modalOpen}
          onRequestClose={this.closeModal.bind(this)}
          title={this.titleText}
          body={this.bodyText}
        />
      </MessageBanner>
    );
  }
}
