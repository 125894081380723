import User, { ModelType } from "./model";
import userSelectors from "./selectors";

// Normalized Store
import normalizedActions from "../utils/normalized/actions";
import fetch from "../utils/normalized/fetch";

// Utils
import errorReporter from "../../utils/errorReporter";

export function fetchUser(accessToken) {
  return function(dispatch) {
    return fetch(
      "users/me",
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      },
      dispatch,
      ModelType,
    );
  };
}

export function updateActiveUser(attributes) {
  return async (dispatch, getState) => {
    const activeUser = userSelectors.activeUser(getState());

    if (!activeUser) return Promise.resolve();

    const payload = activeUser.serialize(attributes);

    return fetch(
      `users/${activeUser.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/vnd.api+json",
        },
        body: JSON.stringify(payload),
      },
      dispatch,
      ModelType,
    ).catch(error => {
      errorReporter.error(
        "Failed to updateActiveUser",
        errorReporter.getMetadata(error),
      );
      // We catch and log the error, but still it to ensure that any callers
      // can problem handle the result.
      throw error;
    });
  };
}

export function addNormalizedUser(data, associations) {
  return function(dispatch) {
    let user = new User();
    user.deserialize(data, associations);
    dispatch(addUser(user));
  };
}

export function addUser(data, associations) {
  return function(dispatch, getState) {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().users,
      ModelType,
      data,
      associations,
    );
  };
}
