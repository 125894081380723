import { createSelector } from "reselect";
import { matchesAll } from "../../utils/filter";

const _byId = state => state.products.byId;
const _allIds = state => state.products.allIds;

const allProducts = createSelector(_byId, _allIds, (byId, allIds) =>
  allIds.map(id => byId[id]),
);

const productForSku = createSelector(
  allProducts,
  (_, props) => props.sku,
  (allProducts, sku) => allProducts.find(product => product.sku === sku),
);

const productForId = createSelector(
  allProducts,
  (_, props) => props.id,
  (allProducts, id) => allProducts.find(product => product.id === id),
);

const reviewCountsMap = createSelector(allProducts, allProducts => {
  const countsMap = {};
  allProducts.forEach(product => {
    countsMap[product.id] = product.reviews.count;
  });
  return countsMap;
});

const reviewRatingsMap = createSelector(allProducts, allProducts => {
  const ratingsMap = {};
  allProducts.forEach(product => {
    ratingsMap[product.id] = product.reviews.average_rating;
  });
  return ratingsMap;
});

const filteredProducts = createSelector(
  allProducts,
  (_, props) => props.filters,
  (allProducts, filters) => {
    return allProducts.filter(product => matchesAll(product, filters));
  },
);

export default {
  allProducts,
  productForSku,
  productForId,
  filteredProducts,
  reviewCountsMap,
  reviewRatingsMap,
};
