import React from "react";
import config from "../utils/siteConfig";
import { Link, navigate } from "gatsby";
import intl from "../services/intl";

export function magicUrl(nodeOrUrl) {
  let formats = {
    ContentfulArticleFreeform: n =>
      `/${config.contentHubArticlesUrl}/${n.slug}`,
    ContentfulProduct: n => `/products/${n.slug}`,
    ContentfulIngredient: n => `/ingredients/${n.slug}`,
  };
  let hasFormat =
    nodeOrUrl &&
    nodeOrUrl.__typename &&
    formats.hasOwnProperty(nodeOrUrl.__typename);

  const url = hasFormat ? formats[nodeOrUrl.__typename](nodeOrUrl) : nodeOrUrl;
  return intl.localizePath(url);
}

export function magicNavigate(node, link = false, target = "_blank") {
  return e => {
    e.stopPropagation();
    if (link) {
      window.open(link, target);
    } else {
      navigate(magicUrl(node));
    }
  };
}

export default class MagicLink extends React.Component {
  constructor() {
    super();

    this.linkRef = React.createRef();
  }

  focus() {
    this.linkRef.current.focus();
  }

  render() {
    let {
      to,
      state,
      href,
      children,
      className,
      target,
      title,
      onClick,
      onMouseOver,
      ariaLabel,
      rel,
      hrefLang = "x-default",
      draggable,
      id,
      ...options
    } = this.props;
    if (to) {
      return (
        <Link
          id={id}
          className={className}
          ref={this.linkRef}
          title={title}
          onClick={e => {
            if (onClick) {
              onClick(e);
            }
            e.stopPropagation();
          }}
          onMouseOver={onMouseOver}
          to={magicUrl(to)}
          state={state}
          hrefLang={hrefLang}
          aria-label={ariaLabel}
          rel={rel}
          draggable={draggable}
          {...options}
        >
          {children}
        </Link>
      );
    } else {
      return (
        <a
          className={className}
          title={title}
          onClick={e => {
            if (onClick) {
              onClick(e);
            }
            e.stopPropagation();
          }}
          onMouseOver={onMouseOver}
          href={href}
          hrefLang={hrefLang}
          ref={this.linkRef}
          target={target ? target : "_blank"}
          aria-label={ariaLabel}
          rel={rel}
          id={id}
          draggable={draggable}
        >
          {children}
        </a>
      );
    }
  }
}
