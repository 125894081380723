import { variation } from "./launchDarkly";
import intl from "../services/intl";

export function getBundleTotalFromPlans(bundle, plans) {
  let total = 0;
  for (const product of bundle.products) {
    total += plans[product.sku].amount;
  }
  total = total / 100;

  return total;
}

export function getBundleCartLimit() {
  return variation(
    "max-num-cart-products",
    Number(process.env.GATSBY_MAX_NUM_CART_PRODUCTS),
  );
}

export function getPlanIdAndQuantityArray(planIds) {
  const planToQuantityMap = {};
  planIds.forEach(planId => {
    if (!planToQuantityMap[planId]) {
      planToQuantityMap[planId] = 0;
    }
    planToQuantityMap[planId]++;
  });

  return Object.keys(planToQuantityMap).map(planId => {
    return {
      planId,
      quantity: planToQuantityMap[planId],
    };
  });
}

/**
 * @return {Object} The discount data.
 * @property {String} titleCopy The copy to use when promoting the discount in
 *    ribbons and headlines.
 * @property {String} sentenceCopy - The copy to use when promoting the discount
 *    in sentences or long form copy. Normally used as `save XXX on` or
 *    `get XXX off.
 * @property {Number} price The original price in dollars, if provided.
 * @property {Number} discountPrice The discount price in dollars, if the price
 *    was provided.
 * @property {Number} amountOff The amount off provided by the discount, in
 *    dollars. Can be used in copy and calculations, and may be more specific
 *    than the sentenceCopy. If null, percentOff must be defined.
 * @property {Number} percentOff The perfect off provided by the discount. Can
 *    be used in copy and calculations, and may be more specific than the
 *    sentenceCopy. If null, amountOff must be defined.
 */
export function getDiscountData(price) {
  const newYearsPromoEnabled = variation("new-years-promo");

  if (newYearsPromoEnabled) {
    const percentDiscount = (price - price * 0.21).toFixed(2);
    const fixedDiscount = price - 30;
    const useFixedDiscount = price - 30 > price - price * 0.21;
    return {
      titleCopy: intl.t("promo.discount.new-years.title", "Save More"),
      sentenceCopy: intl.t("promo.discount.new-years.sentence", "more"),
      price,
      discountPrice: useFixedDiscount ? fixedDiscount : percentDiscount,
      amountOff: useFixedDiscount ? 30 : null,
      percentOff: useFixedDiscount ? null : 21,
    };
  }

  return {
    titleCopy: intl.t("promo.discount.title", "Save {amount}", {
      amount: intl.formatCurrency(10, { round: true }),
    }),
    sentenceCopy: intl.formatCurrency(10, { round: true }),
    price,
    discountPrice: price - 10,
    amountOff: 10,
    percentOff: null,
  };
}
