import React from "react";
import Ribbon from "../global/Ribbon";

import { getDiscountData } from "../../utils/bundle";

export default class BundleRibbon extends React.Component {
  render() {
    const discountData = getDiscountData();
    return <Ribbon {...this.props}>{discountData.titleCopy}</Ribbon>;
  }
}
