import React from "react";
import styled from "styled-components";

// Utils
import { rem, responsive } from "../../../utils/style";
import { Icons } from "../../../utils/svg";
import { analytics } from "../../../utils/analytics";

// Redux & Actions
import { connect } from "react-redux";
import { updateRedirects } from "../../../store/redirects/actions";

// Components
import MagicLink from "../../MagicLink";
import Highlighter from "../Highlighter";
import Text from "../../Text";

// Styled Elements
const AccountLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const AccountLinkText = styled.span`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    height: 20px;
    width: 20px;
  }

  p {
    margin: 0;
    padding-left: 12px;

    font-size: ${rem(16)};
    font-weight: 300;
    letter-spacing: 0px;
    line-height: ${rem(26)};
  }

  ${responsive.md`
    svg {
      height: 24px;
      width: 24px;
    }

    p {
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    }
  `}
`;

const LogoutLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    font-size: ${rem(16)};
    font-weight: 300;
    letter-spacing: 0px;
    line-height: ${rem(26)};
  }

  & > span {
    padding-left: 16px;
    padding-right: 16px;
  }

  ${responsive.md`
    p{
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    }
  `}
`;

const AccountLink = styled(MagicLink)``;

const LogoutLink = styled(MagicLink)``;

export const AccountLinksComponent = class AccountLinks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasExistingId: false,
    };

    this.accountUrl =
      process.env.GATSBY_ACCOUNT_URL || "https://account.ritual.com";
  }

  componentDidMount() {
    const user = analytics.getStoredUserInfo();

    const hasExistingId = !!user.id;

    this.setState({
      hasExistingId,
    });
  }

  handleAccountLinkClick() {
    const { hasExistingId } = this.state;
    this.props.handleNavClick({
      title: "Account",
      url: hasExistingId ? this.accountUrl : "/cart",
    });
  }

  handleLogoutClick() {
    this.props.handleNavClick({
      title: "Log Out",
      url: `${this.accountUrl}/logout`,
    });
  }

  handleLoginRedirect() {
    const { hasExistingId } = this.state;
    const { dispatchUpdateRedirects } = this.props;

    dispatchUpdateRedirects({
      login: hasExistingId ? this.accountUrl : "/cart",
    });

    this.handleAccountLinkClick();
  }

  renderAccountLink() {
    const { hasExistingId } = this.state;
    const { authenticated, onMouseOver } = this.props;

    let linkProps = {
      href: this.accountUrl,
      onClick: this.handleAccountLinkClick.bind(this),
      onMouseOver,
      target: "_self",
    };

    if (!authenticated) {
      linkProps = {
        to: hasExistingId ? "/login" : "/signup",
        onClick: this.handleLoginRedirect.bind(this),
      };
    }

    return (
      <AccountLink {...linkProps}>
        <Highlighter>
          <AccountLinkText>
            <Icons.Account></Icons.Account>
            <p>
              <Text id="nav.links.account-main" defaultMessage="Account" />
            </p>
          </AccountLinkText>
        </Highlighter>
      </AccountLink>
    );
  }

  render() {
    let { authenticated = false, onMouseOver, handleClose } = this.props;

    return (
      <AccountLinksWrapper onBlur={handleClose}>
        {this.renderAccountLink()}

        {authenticated && (
          <LogoutLinkWrapper>
            <span> | </span>
            <LogoutLink
              href={`${this.accountUrl}/logout`}
              target="_self"
              onClick={this.handleLogoutClick.bind(this)}
              onMouseOver={onMouseOver}
            >
              <Highlighter>
                <p>
                  <Text id="nav.links.log-out" defaultMessage="Log Out" />
                </p>
              </Highlighter>
            </LogoutLink>
          </LogoutLinkWrapper>
        )}
      </AccountLinksWrapper>
    );
  }
};

export default connect(null, {
  dispatchUpdateRedirects: updateRedirects,
})(AccountLinksComponent);
