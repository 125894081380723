import Plan, { ModelType } from "./model";
import normalizedActions from "../utils/normalized/actions";

export function addPlansFromGraphQL(planData) {
  return dispatch => {
    planData.forEach(data => {
      const plan = new Plan();
      plan.deserialize(data);
      dispatch(addPlan(plan));
    });
  };
}

export function addPlanFromNormalizedData(data, associations) {
  return dispatch => {
    const plan = new Plan();
    plan.deserialize(data, associations);
    dispatch(addPlan(plan));
  };
}

export function addPlan(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().plans,
      ModelType,
      data,
      associations,
    );
  };
}
