import React from "react";
import TextNotice from "./TextNotice";

const USPNotice = () => {
  return (
    <TextNotice className="mb-3">
      USP has tested and verified ingredients, potency, and manufacturing
      process. USP sets official standards for dietary supplements. See&nbsp;
      <a
        href="https://www.quality-supplements.org"
        title="Read more about the USP verified mark in the supplement industry."
        aria-label="Read more about the USP verified mark in the supplement industry."
        target="_blank"
        rel="noreferrer"
      >
        www.uspverified.org
      </a>
      .
    </TextNotice>
  );
};
export default USPNotice;
