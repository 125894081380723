import React from "react";
import styled from "styled-components";
import classNames from "classnames/bind";

// Utils
import {
  rem,
  responsive,
  Variables,
  hideScrollbars,
} from "../../../utils/style";
import navigation from "../../../services/navigation";

// Link Components
import PanelButton from "./links/PanelButton";
import PrimaryLink from "./links/PrimaryLink";
import ProductLinks from "./links/ProductLinks";
import BrandLinks from "./links/BrandLinks";
import AccountLinks from "./links/AccountLinks";

// Module Components
import PanelModule from "./PanelModule";

// Style Variables
const CATMS = Variables.navContentAnimationTime;

const Container = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;

  position: absolute;
  left: 25vw;
  width: 100vw;
  max-height: 100%;
  padding: 24px 20px;

  overflow-y: scroll;
  ${hideScrollbars}

  opacity: 0;
  visibility: hidden;
  transition: visibility 1ms linear ${CATMS}, left ${CATMS} linear, opacity ${CATMS} linear;
  
  &.opened {
    left: 0;
    opacity: 1;
  }

  &.active {
    visibility: visible;
    transition: visibility 1ms linear, left ${CATMS} linear ${CATMS}, opacity ${CATMS} linear ${CATMS};
  }

  ${responsive.sm`
    left: 0; 
    width: auto;
    padding: 80px 50px;

    transition: visibility 1ms linear ${CATMS}, opacity ${CATMS} linear ${CATMS};

    &.opened {
      opacity: 1;
      visibility: visible;
      transition: opacity ${CATMS} linear;
    }
  `}

  ${responsive.md`
    padding: 80px 45px;
  `}

  ${responsive.lg`
    padding: 120px 80px;
  `}

`;

const PanelHeader = styled.h3`
  font-weight: 500;
  letter-spacing: -0.4px;
  font-size: ${rem(30)};
  line-height: ${rem(36)};

  margin-bottom: 24px;

  ${responsive.sm`
    display: none;
  `}
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;

  list-style: none;
  margin: 0;
  padding: 0;

  padding-left: ${p => (p.depth ? `16px` : 0)};

  ${responsive.sm`
    padding-left: 0;
  `}
`;

const PanelContent = props => {
  const { panelData, panelCount, depth, handleNavClick } = props;
  const { key, header, content } = panelData;

  const opened = navigation.isPanelOpened(key, depth);
  const expanded = navigation.isPanelExpanded(key, depth);
  const active = navigation.isPanelActive(key, depth);

  const className = {
    opened,
    expanded,
    active,
  };

  return (
    <Container className={classNames(className)}>
      {header && <PanelHeader>{header}</PanelHeader>}

      <List depth={depth}>
        {content.map((item, i) => {
          if (item.panel) {
            return (
              <li key={i}>
                <PanelButton
                  title={item.title}
                  panel={item.panel}
                  depth={depth}
                  panelCount={panelCount}
                  handleNavClick={handleNavClick}
                />
              </li>
            );
          }

          if (item.url) {
            return (
              <li key={i}>
                <PrimaryLink
                  to={item.url}
                  title={item.title}
                  module={item.module}
                  depth={depth}
                  handleNavClick={handleNavClick}
                />
              </li>
            );
          }

          if (item.links) {
            return (
              <ProductLinks
                key={i}
                subheader={item.subheader}
                links={item.links}
                handleNavClick={handleNavClick}
              />
            );
          }

          if (item.module) {
            return (
              <li key={i}>
                <PanelModule
                  name={item.module.name}
                  moduleProps={item.module.props}
                />
              </li>
            );
          }
        })}
        {depth === 0 && <BrandLinks handleNavClick={handleNavClick} />}
        {depth === 0 && <AccountLinks handleNavClick={handleNavClick} />}
      </List>
    </Container>
  );
};

export default PanelContent;
