import launchDarkly from "./launchDarkly";
import { getUserTraits } from "./currentUser";
import Cookies from "js-cookie";

function identify(id, attributes) {
  if (!window.analytics || !typeof window.analytics.identify === "function") {
    return;
  }
  window.analytics.identify(id, attributes);
}

function page(pageLabel) {
  if (!window.analytics || !typeof window.analytics.page === "function") {
    return;
  }
  window.analytics.page(pageLabel);
}

function track(event, properties, options) {
  let trackOptions = _parseOptions(properties, options);

  return new Promise(resolve => {
    if (!window.analytics || !typeof window.analytics.track === "function") {
      resolve();
    } else {
      window.analytics.track(event, properties, trackOptions, resolve);
      launchDarkly.track(event, properties);
    }
  });
}

function _parseOptions(properties, options = {}) {
  let traits;
  let trackOptions;

  if (options.addTraitsToContext) {
    if (!traits) traits = getUserTraits();
    trackOptions = {
      context: {
        traits,
      },
    };
  }

  if (options.addEmail) {
    // Only grab the user traits if they were not grabbed in the option above.
    if (!traits) traits = getUserTraits();
    if (traits.email) {
      properties.email = traits.email;
    }
  }

  if (options.addFacebookCookies) {
    const fbp = Cookies.get("_fbp");
    const fbc = Cookies.get("_fbc");
    if (fbp) {
      properties.fbp = fbp;
    }
    if (fbc) {
      properties.fbc = fbc;
    }
  }

  // If the segmentOptions property is passed, append those options to the top
  // level tracking options.
  if (options.segmentOptions) {
    trackOptions = trackOptions || {};
    trackOptions = { ...trackOptions, ...options.segmentOptions };
  }

  // Once all the options are parsed, update the options that are passed to the
  // analyitcs.track calls.
  return trackOptions;
}

export default {
  identify,
  page,
  track,
};
