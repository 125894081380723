// Utils
import { getProductAttributes } from "../planToProduct";
import cartProductSelectors from "../../store/cart-product/selectors";

export function getPropertiesForProduct(planId, quantity = 1) {
  if (!planId) return {};
  const productAttributes = getProductAttributes(planId, quantity);
  if (!productAttributes) return {};

  const product_id = productAttributes.id;
  delete productAttributes.slug;
  delete productAttributes.id;

  return {
    product_id,
    ...productAttributes,
  };
}

export function getCartProductsForIterable(state) {
  const cartProducts = cartProductSelectors.activeCartProducts(state);

  return cartProducts.map(product => {
    const { quantity, planId } = product;
    const productAttributes = getProductAttributes(planId, quantity);
    delete productAttributes.slug;

    return {
      ...productAttributes,
    };
  });
}
