import React from "react";
import styled from "styled-components";
import { Font, Color, rem, responsive } from "../../../utils/style";
import MagicLink from "../../MagicLink";
import DoNotTrack from "./DoNotTrack";
import Text from "../../Text";

const CopyrightTextWrapper = styled.div.attrs({
  id: "footer_container_row-2_column-0_copyright-text",
})`
  ${Font.circular}
  font-size: ${rem(12)};
  line-height: ${rem(22)};
  font-weight: 500;
  color: ${Color.ritualBlue};
  margin: 0;

  a {
    margin-right: 16px;
    font-weight: 500;
    color: ${Color.ritualBlue};
    display: inline-block;
    transition: all 0.2s;

    &:hover {
      opacity: 0.56;
    }
  }

  span {
    ${Font.circular}
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    font-weight: 500;
    font-weight: 500;
    white-space: nowrap;
    display: block;
    margin-top: 4px;

    ${responsive.md`
      display: inline;
    `}
  }
`;

const CopyrightText = () => {
  const year = new Date().getFullYear();
  return (
    <CopyrightTextWrapper>
      <MagicLink to="/privacy-policy">
        <Text
          id="footer.copyright.link.privacy"
          defaultMessage="Privacy Policy"
        />
      </MagicLink>
      <MagicLink to="/terms-of-service">
        <Text
          id="footer.copyright.link.terms"
          defaultMessage="Terms of Service"
        />
      </MagicLink>
      <MagicLink to="/accessibility">
        <Text
          id="footer.copyright.link.accessibility"
          defaultMessage="Accessibility"
        />
      </MagicLink>
      <DoNotTrack />
      <br className="d-block d-lg-none" />
      <span>
        <Text
          id="footer.copyright.copyright"
          defaultMessage="© {year} Ritual. All rights reserved."
          values={{ year }}
        />
      </span>
    </CopyrightTextWrapper>
  );
};
export default CopyrightText;
