import React from "react";
import styled from "styled-components";
import { Color, rem } from "../../utils/style";

const RibbonContent = styled.span`
  z-index: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: fit-content;
  box-sizing: content-box;

  height: ${p => (p.short ? "28px" : "32px")};
  padding: ${p => (p.short ? "0 12px" : "0 14px")};

  font-weight: 500;
  text-transform: uppercase;

  font-size: ${p => (p.short ? rem(10) : rem(12))};
  line-height: ${p => (p.short ? rem(16) : rem(20))};
  letter-spacing: ${p => (p.short ? "0.67px" : "0.8px")};

  background-color: ${p => (p.white ? Color.white : Color.ritualYellow)};

  &::before,
  &::after {
    box-sizing: content-box;
    content: "";

    position: absolute;
    top: 0;
    z-index: -1;

    border-style: solid;
    border-color: ${p => (p.white ? Color.white : Color.ritualYellow)};
    border-width: ${p => (p.short ? "14px 6px" : "16px 8px")};
  }

  &::before {
    display: ${p => (p.twoSided ? "block" : "none")};
    left: 0;
    transform translateX(-50%);
    border-left-color: transparent;
  }

  &::after {
    right: 0;
    border-right-color: transparent;
    transform translateX(50%);
  }

  ${p => p.contentStyle};
`;

/**
 * By default the `Ribbon` supports a height of 28px (short) and 32px. If a
 * specific component wants to further style the ribbon, the component can pass
 * the `contentStyle` property. If the `height` of the ribbon is changed in this
 * prop, the `::before` and `::after` `border-width` must also be changed. The
 * `border-width` must be set to `border-width: Xpx, Ypx`, where `X` is half of
 * the `height`. `Y` can be any number, depending on how steep we want the
 * flags to be.
 */
export default class Ribbon extends React.Component {
  render() {
    const { className, contentStyle, short, white, twoSided } = this.props;
    return (
      <RibbonContent
        className={className}
        contentStyle={contentStyle}
        short={short}
        white={white}
        twoSided={twoSided}
      >
        {this.props.children}
      </RibbonContent>
    );
  }
}
