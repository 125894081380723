import React from "react";

// Module Components
import BundleMessaging from "./modules/BundleMessaging";
import BundleRibbon from "../../bundle/Ribbon";
import TestImageModule from "./modules/TestImageModule";

const Modules = {
  BundleMessaging: BundleMessaging,
  BundleRibbon: BundleRibbon,
  TestImageModule: TestImageModule,
};

const PanelModule = props => {
  let { name, moduleProps } = props;

  const Module = Modules[name];
  if (!Module) return null;

  moduleProps = { ...props, ...moduleProps };
  return <Module {...moduleProps} />;
};

export default PanelModule;
