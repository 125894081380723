import { css } from "styled-components";

export function rem(value) {
  return `${value / 16}rem`;
}

export function maxLines(lineHeight, numberOfLines) {
  return `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${numberOfLines};
    max-height: ${lineHeight * numberOfLines};
  `;
}

export function capitalize(string) {
  let lowercaseString = string.toLowerCase();
  return lowercaseString.charAt(0).toUpperCase() + lowercaseString.slice(1);
}

// Font order: New font name, Shopify font name, backup;
export const Font = {
  dutch: `font-family: 'Dutch801', serif;`,
  circular: `font-family: 'LL Circular', serif;`,
};

export const Variables = {
  navHeight: "64px",
  navHeightSmall: "48px",
  navAnimationTime: "300ms",
  navAnimationTimeMobile: "500ms",
  navContentAnimationTime: "250ms",
};

export const Color = {
  ritualBlue: "#142b6f",
  ritualYellow: "#ffd600",
  ritualGrey: "#f2f1f5",
  ritualLightBlue: "#e1e9f0",
  ritualLightYellow: "#fff5c2",
  ritualSecondaryBlue: "#9fafc9",
  ritualDisabledBlue: "#8b95b4",
  ritualSecondaryDisabledBlue: "#B3BBD1",
  ritualRed: "#eb4343",
  ritualAccessibleRed: "#db3333", // contrast ratio of 4.5:1 on White background
  ritualPink: "#f97878",
  ritualGreen: "#55940b",

  white: "#ffffff",
  black: "#000000",

  paypalGold: "#ffce31",

  fadedBlue: "rgba(20, 43, 111, 0.24)",
  lessFadedBlue: "rgba(20, 43, 111, 0.56)",
};

export function rgba(hexColor, opacity) {
  const hexToRgb = hex =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b,
      )
      .substring(1)
      .match(/.{2}/g)
      .map(x => parseInt(x, 16));

  const rgb = hexToRgb(hexColor).map(val => (val > -1 ? val : `255`));
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity || 1})`;
}

export const sizes = {
  sm: 749,
  tablet: 959,
  desktop: 960,
};

export const atMost = {
  mobile: () => window.matchMedia("(max-width: 749px)").matches,
  tablet: () => window.matchMedia("(max-width: 959px)").matches,
};

export const atLeast = {
  tablet: () => window.matchMedia("(min-width: 750px)").matches,
  desktop: () => window.matchMedia("(min-width: 960px)").matches,
  largeDesktop: () => window.matchMedia("(min-width: 1366px)").matches,
};

// Mobile-first & correspond to the same breakpoints
// in account-manager and checkout
export const responsive = {
  sm: (...args) => css`
    @media (min-width: 750px) {
      ${css(...args)};
    }
  `,
  md: (...args) => css`
    @media (min-width: 960px) {
      ${css(...args)};
    }
  `,
  lg: (...args) => css`
    @media (min-width: 1200px) {
      ${css(...args)};
    }
  `,
};

export const media = {
  mobile: (...args) => css`
    @media (max-width: 749px) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media (max-width: 959px) {
      ${css(...args)};
    }
  `,
  desktop: (...args) => css`
    @media (min-width: 960px) {
      ${css(...args)};
    }
  `,
  reducedMotion: (...args) => css`
    @media screen and (prefers-reduced-motion: reduce) {
      ${css(...args)};
    }
  `,
  /*
   *  notDesktop: This is for the breakpoints between mobile & desktop that don't quite
   *  fit properly.
   */
  notDesktop: (...args) => css`
    @media (max-width: 1198px) {
      ${css(...args)};
    }
  `,
};

export const getBreakpoint = () => {
  if (atMost.mobile()) return "xs";
  if (atMost.tablet()) return "sm";
  if (!atLeast.largeDesktop()) return "md";
  return "lg";
};

export const hideScrollbars = css`
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
