import React from "react";
import styled from "styled-components";
import { Color } from "../../../utils/style";

const OverlayElement = styled.div.attrs(p => ({
  className: `d-none d-sm-block ${p.navOpen ? "open" : "closed"}`,
}))`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${Color.black};
  transition: opacity 500ms ease-in-out, z-index 500ms ease-in-out;

  &.closed {
    opacity: 0;
    z-index: -10;
  }

  &.open {
    opacity: 0.56;
    z-index: 9999;
  }
`;

export default class Overlay extends React.Component {
  render() {
    let { navOpen, onClick } = this.props;
    return <OverlayElement navOpen={navOpen} onClick={onClick} />;
  }
}
