import Cookies from "js-cookie";

// Redux
import { getStore } from "../store/createStore";
import { fetchDataAfterAuth } from "../store/app/actions";

// Helpers
import { safeStringify } from "./helpers";

// Utils
import { authAndFetchUser } from "./currentUser";
import fetch from "./fetch";
import launchDarkly from "./launchDarkly";

// Importing itself to give Jest the same reference for testing
import * as authenticateModule from "./authenticate";

export const SET_COOKIE =
  (process.env.ACTIVE_ENV || process.env.NODE_ENV) === "development";

export function createAccount(firstName, lastName, email, password) {
  const body = {
    first_name: firstName,
    last_name: lastName,
    email,
    password,
  };

  const ldKey = launchDarkly.getKey();

  // If the launchDarkly key is defined, add it to the user record when creating
  // an account.
  if (ldKey) {
    body.ld_key = ldKey;
  }

  return fetch("users", {
    method: "POST",
    body: JSON.stringify(body),
  })
    .then(() => {
      return authenticateModule.authenticate(email, password);
    })
    .catch(e => {
      // If the error code is a 409, we know there is a conflict with
      // LaunchDarkly key. We should generate a new key and re-issue the
      // request.
      if (e.status === 409) {
        return launchDarkly.resetUser().then(() => {
          return createAccount(firstName, lastName, email, password);
        });
      } else {
        throw e;
      }
    });
}

export function authenticate(email, password, setCookie = SET_COOKIE) {
  let castleClientID =
    window._castle &&
    typeof window._castle === "function" &&
    window._castle("getClientId");

  return fetch("oauth/token", {
    method: "POST",
    credentials: "include",
    body: `grant_type=password&username=${encodeURIComponent(
      email,
    )}&password=${encodeURIComponent(password)}`,
    headers: {
      Accept: "application/json", // Guarantees returns JSON
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Castle-Client-Id": castleClientID,
    },
  })
    .then(res => {
      if (res.access_token) {
        // Create new expiry date
        const now = new Date();
        const expiresAt = new Date(now.getTime() + res.expires_in * 1000);

        // Stores oauth response as cookie
        const cookieData = {
          data: res,
          expires: expiresAt,
        };

        if (setCookie) {
          // Set cookie on client side for development environments
          authenticateModule.updateAuthenticationCookie(cookieData);
        }

        return authAndFetchUser().then(() => {
          getStore().dispatch(fetchDataAfterAuth());
        });
      } else {
        throw {
          error: "No access_token",
        };
      }
    })
    .catch(e => {
      throw e;
    });
}

// Creates authentication cookies read across codebases
export function updateAuthenticationCookie(data) {
  if (!data || !data.data) {
    return;
  }

  const {
    access_token,
    created_at,
    expires_in,
    refresh_token,
    token_type,
  } = data.data;
  const expires_at = data.expires.getTime();

  const newCookie = safeStringify({
    authenticated: {
      access_token,
      authenticator: "authenticator:oauth2",
      created_at,
      expires_at,
      expires_in,
      refresh_token,
      token_type,
    },
  });

  Cookies.set("ritual_auth-session", newCookie, {
    expires: 30, // expiration time in days
    domain: process.env.GATSBY_COOKIE_DOMAIN,
  });
}
