import Coupon, { ModelType } from "./model";
import normalizedActions from "../utils/normalized/actions";

export function removeCoupon(id) {
  return dispatch => {
    return normalizedActions.removeModel(dispatch, ModelType, id);
  };
}

export function addCouponFromNormalizedData(data, associations) {
  return dispatch => {
    const coupon = new Coupon();
    coupon.deserialize(data);
    dispatch(addCoupon(coupon, associations));
  };
}

export function addCoupon(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().coupons,
      ModelType,
      data,
      associations,
    );
  };
}
