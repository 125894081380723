import React from "react";
import styled from "styled-components";
import classNames from "classnames/bind";

// Utils
import { Color, responsive, media, Variables } from "../../../utils/style";

// Services
import navigation from "../../../services/navigation";

// Components
import CloseButton from "./CloseButton";
import PanelContent from "./PanelContent";

// Style Variables
const ATMS = Variables.navAnimationTime;
const MATMS = Variables.navAnimationTimeMobile;
const MobileNavHeight = Variables.navHeightSmall;

// Styled Elements
const PanelContainer = styled.div`
  position: fixed;
  visibility: hidden;
  bottom: 0;
  z-index: -2;

  &.opened {
    z-index: -1;
  }

  ${media.mobile`
    top: calc(${MobileNavHeight} + ${p => p.offset || 0}px); // TopNav Offset
    width: 100vw;
    left: 0;
    opacity: 0;

    // Closing transition applied when clicking "x"
    transition: visibility ${MATMS} linear, left 1ms linear ${MATMS}, opacity ${MATMS} linear;

    // Delayed transition applied to panel when it's no longer visible after clicking back arrow
    &.nav-opened {
      transition: visibility 1ms linear ${MATMS}, opacity 1ms linear ${MATMS};
    }

    &.expanded {
      left: -100vw;
      transition: left ${MATMS} linear;
    }

    &.opened {
      opacity: 1;

      // First panel should fade in when opening the nav
      &.top-panel {
        transition: left ${MATMS} linear, opacity ${MATMS} linear;
      }
    }

    &.active {
      visibility: visible;
      left: 0;
    }
  `}

  ${responsive.sm`
    top: 0;
    left: -${p => p.width}vw;
    width: ${p => p.width}vw;
    transition: visibility 1ms linear ${ATMS}, left ${ATMS} linear;

    &.nav-opened {
      left: ${p => p.depth * p.width - p.width}vw;
    }

    &.expanded {
      left: ${p => p.depth * p.width}vw;
      transition: left ${ATMS} linear;
    }

    &.opened {
      visibility: visible;
    }
  `}
`;

const PanelBackground = styled.div`
  height: 100%;
  width: 100%;
  background-color: transparent;

  ${responsive.sm`
    border-right: 1px solid ${Color.ritualGrey};
    background-color: white;
  `}
`;

const PanelCloseButton = styled.div`
  position: absolute;
  visibility: hidden;
  top: 20px;
  right: 20px;

  ${responsive.sm`
    top: 25px;
    right: 30px;
    opacity: 0;
    z-index: 2;
    transition: visibility 1ms linear ${ATMS}, opacity ${ATMS} linear;

    &.active {
      opacity: 1;
      visibility: visible;
      transition: opacity ${ATMS} linear;
    }
  `}
`;

const Panel = props => {
  const { panelData, panelCount, depth, handleNavClick } = props;
  const { key } = panelData;

  const width = 100 / panelCount;

  const opened = navigation.isPanelOpened(key, depth);
  const expanded = navigation.isPanelExpanded(key, depth);
  const active = navigation.isPanelActive(key, depth);
  const topPanel = navigation.isTopPanel(key, depth);

  const navOpened = navigation.isOpen;
  const offset = navigation.offset;

  const panelClassname = classNames({
    opened,
    active,
    expanded,
    "top-panel": topPanel,
    "nav-opened": navOpened,
  });

  return (
    <PanelContainer
      width={width}
      depth={depth}
      offset={offset}
      className={panelClassname}
    >
      <PanelBackground className={classNames({ active })}>
        <PanelCloseButton className={classNames({ active })}>
          <CloseButton />
        </PanelCloseButton>
        <PanelContent
          panelData={panelData}
          panelCount={panelCount}
          depth={depth}
          handleNavClick={handleNavClick}
        />
      </PanelBackground>
    </PanelContainer>
  );
};

export default Panel;
