// Store
import { createSelector } from "reselect";
import { getStore } from "../createStore";
import observeStore from "../observeStore";

// Selectors
import subscriptionSelectors from "../subscription/selectors";

// Actions
import { updateBannerType } from "./actions";

// Utils
import { BannerType } from "../../utils/bannerType";

let clearObserver = null;

// We watch for a change in hasActiveSubscription
const selector = createSelector(
  subscriptionSelectors.hasActiveSubscription,
  state => state.bannerType,
  (hasActiveSubscription, bannerType) => {
    return { hasActiveSubscription, bannerType };
  },
);

function start() {
  clearObserver = observeStore(selector, handleChange);
}

function stop() {
  clearObserver && clearObserver();
}

function handleChange(selectedState) {
  const { hasActiveSubscription, bannerType } = selectedState;
  // If user has subscriptions and no banner type is set, we set to BUNDLE_EXISTING
  if (hasActiveSubscription && !bannerType) {
    getStore().dispatch(updateBannerType(BannerType.BUNDLE_EXISTING));
  }
}

export default {
  start,
  stop,
};
