const API_URL = process.env.GATSBY_API_URL + `/api/v1`;

class FetchInstance {
  constructor() {
    this.accessToken = "";
  }

  fetchInternal(url, options = {}, retryCount = 0, retryInterval = 5000) {
    options = this._addDefaultsToHeaders(options);
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/${url}`, options)
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              resolve(data);
            });
            return;
          }

          const status = response.status;
          if (!retryCount || this._isClientError(status)) {
            // If we should no longer retry the request, or we encounter a 4XX
            // error, reject with the response data. If the data cannt be
            // parsed, reject with the response.
            try {
              response.json().then(data =>
                reject({
                  status: response.status,
                  ...data,
                }),
              );
            } catch (e) {
              reject(response);
            }
          } else if (this._isServerError(status)) {
            // If we encounter a 5XX error and are still retrying, re-attempt the
            // fetch after with some delay.
            setTimeout(() => {
              this.fetchInternal(url, options, retryCount - 1, retryInterval)
                .then(data => resolve(data))
                .catch(error => reject(error));
            }, retryInterval);
          } else {
            // Reject with the response data. If the data cannt be parsed,
            // reject with the response.
            try {
              response.json().then(data =>
                reject({
                  status: response.status,
                  ...data,
                }),
              );
            } catch (e) {
              reject(response);
            }
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  setAccessToken(accessToken) {
    this.accessToken = accessToken;
  }

  _addDefaultsToHeaders(options) {
    const defaults = {
      "Content-Type": "application/json",
    };

    if (this.accessToken) {
      defaults["Authorization"] = `Bearer ${this.accessToken}`;
    }

    const updatedOptions = { ...options };
    updatedOptions.headers = { ...defaults, ...options.headers };

    return updatedOptions;
  }

  _isClientError(status) {
    return status >= 400 && status < 500;
  }

  _isServerError(status) {
    return status >= 500;
  }
}

let fetchInstance = new FetchInstance();

export function setAccessToken(accessToken) {
  fetchInstance.setAccessToken(accessToken);
}

export function reset() {
  fetchInstance = new FetchInstance();
}

export default function(url, options, retryCount, retryInterval) {
  return fetchInstance.fetchInternal(url, options, retryCount, retryInterval);
}
