import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import whatInput from "what-input";

import { currentPageData } from "../utils/currentPageData";
import { variation } from "../utils/launchDarkly";

// Add global styles
import "../styles/index.scss";

// Shared Components across site
import Navigation from "../components/global/Navigation";
import NavigationV3 from "../components/global/NavigationV3";
import Banners from "../components/banners/Banners";
import Footer from "../components/global/Footer";
import ScrollDepth from "../components/segment/ScrollDepth";
import SegmentConsentManager from "../components/segment/SegmentConsentManager";

// Store
import { connect } from "react-redux";
import { getStore } from "../store/createStore";

// Actions
import { appLoaded } from "../store/app/actions";

// Selectors
import cartProductSelectors from "../store/cart-product/selectors";

const SiteRoot = styled.div.attrs({ className: "content-hub-page" })`
  padding-top: ${p => {
    // Account for offset and 4px loading bar at top of page
    return p.topOffset ? `${p.topOffset - 4}px` : "0";
  }} !important;
  overflow-x: hidden;

  &:focus {
    outline: none;
  }
`;

const pageQuery = graphql`
  query contentHubLayoutQuery {
    allContentfulProductCategory {
      nodes {
        id
        categoryName
        shopLandingReference {
          slug
        }
        products {
          pageTitle
          slug
          id
          name {
            name
          }
        }
      }
    }
  }
`;

const ritualLayout = class RitualLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      banner: true,
      nav3: false,
    };

    this.updateState = this.updateState.bind(this);
    this.updatePageData = this.updatePageData.bind(this);

    this._bufferedOffset = null;
    this._isMounted = false;
  }

  // Triggers on first load
  componentDidMount() {
    this._isMounted = true;
    if (typeof window !== "undefined" && "performance" in window) {
      performance.mark("application-loaded");
    }

    const nav3 = variation("nav-3");

    this.setState({
      nav3,
    });

    getStore().dispatch(appLoaded());
  }

  componentDidUpdate() {
    console.log("State or prop change");
  }

  componentWillUnmount() {
    window.clearInterval(this.cyberMondayInterval);
    this._isMounted = false;
  }

  setOffset(height = 0) {
    if (!this._isMounted) {
      this._bufferedOffset = height;
      return;
    }

    this._bufferedOffset = null;
    this.setState({
      offset: height,
    });
  }

  updateState(incomingState) {
    const updatedState = {
      ...this.state,
      ...incomingState,
    };

    this.setState(updatedState);
  }

  updatePageData(data) {
    if (data.label) {
      currentPageData.label = data.label;
    }
  }

  renderNavigation() {
    const { location } = this.props;
    const { banner, nav3 } = this.state;

    if (nav3) {
      return (
        <NavigationV3>
          <Banners hideBanner={!banner} location={location} />
        </NavigationV3>
      );
    }

    return (
      <Navigation>
        <Banners hideBanner={!banner} location={location} />
      </Navigation>
    );
  }

  render() {
    const {
      children,
      location,
      cartQuantity,
      offset,
      showUSPNotice,
    } = this.props;

    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, {
        updateLayoutState: this.updateState,
        updatePageData: this.updatePageData,
        offset,
      }),
    );

    // Strip all "/" from the beginning and end of the path string.
    const strippedPath = this.props.location.pathname.replace(/^\/+|\/+$/g, "");
    const cartHasProducts = cartQuantity > 0;

    // Hiding nav on certain pages
    const pagesWithoutNav = ["build-a-bundle"];
    if (cartHasProducts) {
      pagesWithoutNav.push("cart");
    }
    const hideNav = pagesWithoutNav.includes(strippedPath);

    // Hiding footer on certain pages
    const pagesWithoutFooter = ["refer50plus", "cart", "build-a-bundle"];
    const hideFooter = pagesWithoutFooter.includes(strippedPath);
    const displaySlimFooter = children.props.pageContext.slimFooter || false;

    return (
      <StaticQuery
        query={pageQuery}
        render={data => {
          return (
            <>
              <ScrollDepth pathname={location.pathname} />
              {!hideNav && this.renderNavigation()}

              <SiteRoot topOffset={offset} id="main">
                {childrenWithProps}
              </SiteRoot>

              {!hideFooter && (
                <Footer
                  location={location}
                  displaySlimFooter={displaySlimFooter}
                  showUSPNotice={showUSPNotice}
                  categories={data.allContentfulProductCategory.nodes}
                />
              )}
              <div id="modal-root"></div>
              <div id="zoom-root"></div>
              <SegmentConsentManager />
            </>
          );
        }}
      />
    );
  }
};

const mapStateToProps = state => {
  const { navigation } = state;
  return {
    offset: navigation.offset,
    showUSPNotice: state.navigation.showFooterUSPNotice,
    cartQuantity: cartProductSelectors.activeCartProductQuantity(state),
  };
};

const componentWithState = connect(mapStateToProps)(ritualLayout);

export default componentWithState;
