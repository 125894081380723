import $ from "jquery";
import React from "react";
import styled from "styled-components";
import { responsive, Font, atMost } from "../utils/style";
import { Icons } from "../utils/svg";

import { connect } from "react-redux";
import { updateNavigation } from "../store/navigation/actions";

export const Wrapper = styled.section`
  position: relative;
  overflow: hidden;
  text-align: center;
  font-size: 13px;
  line-height: 17px;
  ${Font.circular}
  display: ${p => (p.displayBanner ? "block" : "none")};
  background: #142B6F;
  color: #FFF;
  margin-bottom: ${p => (p.scrolled ? "0" : "-4px")};
`;

const Container = styled.div`
  position: relative;
  padding: 0 40px;
  height: auto !important;

  padding: 0 36px 0px 15px;
  text-align: left;

  ${responsive.sm`
    padding-left: 24px;
  `}

  ${responsive.md`
    text-align: center;
    padding: 0px 48px;
  `}
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;

  ${responsive.sm`
    padding-right: 24px;
  `}

  ${responsive.lg`
    padding-right: 40px;
  `}
`;

const CloseButton = styled.a`
  height: 16px;
  width: 16px;
  position: relative;
  opacity: 0.56;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const CloseIcon = styled(Icons.Close)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  g {
    stroke: white;
  }
`;

export class MessageBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      navOpen: false,
    };

    this.wrapperElement = null;
    this.timeout = null;
  }

  closeBanner(e) {
    e.stopPropagation();
    this.setState({
      open: false,
    });
    if (this.props.isSessionStorageAvailable) {
      window.sessionStorage.removeItem("r_banner-query");
    }
    this.updateHeight(0);
    $(window).trigger("messageBanner.close");
  }

  updateHeight(forceHeight = null) {
    if (forceHeight !== null) {
      this.props.dispatchUpdateNavigation({ offset: forceHeight });
    } else {
      let heightToSet =
        this.wrapperElement && this.state.open
          ? $(this.wrapperElement).outerHeight()
          : 0;
      this.props.dispatchUpdateNavigation({ offset: heightToSet });
    }
  }

  displayBanner() {
    const navOpen = this.props.navOpen;
    const open = this.state.open;
    const isMobile = atMost.mobile();

    let displayBanner;

    if (open) {
      displayBanner = navOpen && isMobile ? false : true;
    } else {
      displayBanner = false;
    }

    return displayBanner;
  }

  componentDidMount() {
    $(window).on("resize.banner", () => {
      this.updateHeight();
    });
    this.updateHeight();
    // Hack to adjust height based on font load
    this.timeout = setTimeout(() => this.updateHeight(), 600);

    $(window).trigger(
      "messageBanner.open",
      $(this.wrapperElement).outerHeight(),
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.updateHeight();
    }
  }

  componentWillUnmount() {
    $(window).off("resize.banner");

    this.updateHeight(0);

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { children, scrolled, lightenCloseButton } = this.props;
    const { open } = this.state;

    return (
      <Wrapper
        open={open}
        displayBanner={this.displayBanner()}
        ref={r => (this.wrapperElement = r)}
        scrolled={scrolled}
      >
        <Container>{children}</Container>
        <CloseButtonContainer>
          <CloseButton
            onClick={this.closeBanner.bind(this)}
            href="#"
            className={lightenCloseButton ? "lighten" : ""}
          >
            <CloseIcon />
          </CloseButton>
        </CloseButtonContainer>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  const { navigation } = state;
  return {
    navOpen: navigation.open,
    scrolled: navigation.scrolled,
  };
};

export default connect(mapStateToProps, {
  dispatchUpdateNavigation: updateNavigation,
})(MessageBanner);
