import metrics from "../../utils/metrics";

// Action Types
export const UPDATE_NAVIGATION = "UPDATE_NAVIGATION";
export const PAGE_TRANSITION = "PAGE_TRANSITION";
export const UPDATE_FOOTER_USP = "UPDATE_FOOTER_USP";
export const UPDATE_NAVIGATION_PANELS = "UPDATE_NAVIGATION_PANELS";

import selectors from "./selectors";

export function updateNavigation(navigation) {
  if (typeof navigation.open !== "undefined") {
    const event = navigation.open ? "Nav Opened" : "Nav Closed";
    metrics.track(event);
  }

  return function(dispatch) {
    dispatch({
      type: UPDATE_NAVIGATION,
      navigation,
    });
  };
}

export function closeNavPanel(depth) {
  return (dispatch, getState) => {
    const panels = [...selectors.panels(getState())];

    // If we're closing the top level panel, and it is currently open, report
    // the "Nav Closed" event.
    if (depth === 0 && panels[0]) metrics.track("Nav Closed");

    // Close the current panel and all panels of a higher depth. This ensures
    // that any secondary or tertiary panels are properly closed when the layer
    // above them is closed.
    for (let i = depth; i < panels.length; i++) {
      panels[i] = null;
    }

    dispatch({
      type: UPDATE_NAVIGATION_PANELS,
      payload: {
        panels,
      },
    });
  };
}

export function openNavPanel(depth, key) {
  return (dispatch, getState) => {
    const panels = [...selectors.panels(getState())];

    // If we're opening the top level panel, and it is currently closed, report
    // the "Nav Opened" event.
    if (depth === 0 && !panels[0]) {
      metrics.track("Nav Opened");
    }

    panels[depth] = key;

    // Close all panels of a higher depth than the currently opened panel. This
    // ensures that any secondary or tertiary panels are closed when switching
    // nav contexts.
    for (let i = depth + 1; i < panels.length; i++) {
      panels[i] = null;
    }

    dispatch({
      type: UPDATE_NAVIGATION_PANELS,
      payload: {
        panels,
      },
    });
  };
}

export function updatePreviousUrl(url) {
  return function(dispatch) {
    dispatch({
      type: PAGE_TRANSITION,
      previousUrl: url,
    });
  };
}

export function updateFooterUSPNotice(showFooterUSPNotice) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_FOOTER_USP,
      showFooterUSPNotice,
    });
  };
}
