// Action Types
import {
  INITIALIZE_CONTENTFUL_PRODUCT_DATA,
  INITIALIZE_PRODUCT_DATA,
  INITIALIZE_PLAN_DATA,
} from "./actions";

export default function reducer(state = {}, action) {
  switch (action.type) {
    case INITIALIZE_CONTENTFUL_PRODUCT_DATA:
    case INITIALIZE_PRODUCT_DATA:
    case INITIALIZE_PLAN_DATA:
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
}
