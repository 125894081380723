import Subscription, { ModelType } from "./model";

import normalizedActions from "../utils/normalized/actions";
import fetch from "../utils/normalized/fetch";

export function fetchActiveSubscriptions() {
  return dispatch => {
    return fetch(
      "subscriptions?filter[by_active]=true",
      {},
      dispatch,
      ModelType,
    ).catch(error => {
      console.error(error);
    });
  };
}

export function addSubscriptionFromNormalizedData(data, associations) {
  return dispatch => {
    let subscription = new Subscription();
    subscription.deserialize(data, associations);
    dispatch(addSubscription(subscription));
  };
}

export function addSubscription(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().subscriptions,
      ModelType,
      data,
      associations,
    );
  };
}
