import { createSelector } from "reselect";
import productSelectors from "../product/selectors";

const _byId = state => state.plans.byId;
const _allIds = state => state.plans.allIds;

const allPlans = createSelector(_byId, _allIds, (byId, allIds) =>
  allIds.map(id => byId[id]),
);

const planForId = createSelector(
  allPlans,
  (_, props) => props.id,
  (allPlans, id) => allPlans.find(plan => plan.id === id),
);

const planForProductSku = createSelector(
  productSelectors.productForSku,
  allPlans,
  (product, allPlans) => {
    if (!product) return null;
    return allPlans.find(plan => plan.productId === product.id);
  },
);

const productForPlanId = createSelector(
  planForId,
  productSelectors.allProducts,
  (plan, allProducts) => {
    if (!plan) return null;
    return allProducts.find(product => product.id === plan.productId);
  },
);

export default {
  allPlans,
  planForId,
  planForProductSku,
  productForPlanId,
};
