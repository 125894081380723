import { createSelector } from "reselect";
import locales from "../../../config/i18n";

const i18n = state => state.i18n;

const locale = createSelector(i18n, i18n => locales[i18n.locale].locale);
const siteLang = createSelector(i18n, i18n => locales[i18n.locale].siteLang);
const ogLocale = createSelector(i18n, i18n => locales[i18n.locale].ogLocale);
const currency = createSelector(i18n, i18n => locales[i18n.locale].currency);
const path = createSelector(i18n, i18n => locales[i18n.locale].path);
const activeLocales = createSelector(i18n, i18n => i18n.activeLocales);

export default {
  locale,
  siteLang,
  ogLocale,
  currency,
  path,
  activeLocales,
};
