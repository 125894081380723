import React from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Components
import MagicLink from "../MagicLink";
import Container from "../Container";
import NewsletterForm from "./NewsletterForm";
import FooterLinkColumn from "./footer/FooterLinkColumn";
import SocialList from "./footer/SocialList";
import DisclaimerText from "./footer/DisclaimerText";
import FDAText from "./footer/FDAText";
import USPNotice from "./footer/USPNotice";
import CopyrightText from "./footer/CopyrightText";
import BBBLink from "./footer/BBBLink";
import FooterLogo from "./footer/FooterLogo";
import Text from "../Text";

// Utils
import { Font, Color, rem, media, responsive } from "../../utils/style";

const FooterWrapper = styled.footer.attrs({
  id: "footer",
  className: "global-footer ajs_footer",
})`
  background-color: #ffffff;
  border-top: 1px solid #ccc;
`;

const FooterContainer = styled(Container).attrs({
  id: "footer_container",
})`
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  box-sizing: content-box;
  // Override stupid bootstrap container affecting width + padding, visible on mobile
  width: calc(100% - 40px) !important;

  padding: 0 20px;
  padding-left: max(20px, calc(10px + env(safe-area-inset-left)));
  padding-right: max(20px, calc(10px + env(safe-area-inset-right)));
`;

const FooterRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  padding: 32px 0;

  ${responsive.sm`
    padding: 56px 0;
    flex-direction: row;
  `}

  &.footer-top {
    padding: 56px 0;
    ${responsive.sm`
      padding: 80px 0;
    `}
  }

  &.footer-shop-links {
    border-top: 4px solid ${Color.ritualBlue};
    padding: 0;
    ${responsive.sm`
      padding: 60px 0 56px;
    `}
  }

  &.footer-legal {
    border-top: 1px solid ${Color.ritualBlue};
    padding: 40px 0 56px;
    ${responsive.sm`
      padding: 24px 0 56px;
    `}
    ${responsive.md`
      padding: 24px 0 64px;
    `}
  }

  &#slim_footer_row-0 {
    padding: 56px 0 40px;
    ${responsive.md`
    padding: 66px 0 56px;
    `}
  }
  &#slim_footer_bottom {
    ${responsive.sm`
      padding: 24px 0 56px;
    `}
    ${responsive.md`
      padding: 24px 0 64px;
    `}
  }
`;

const FooterColumn = styled.div`
  position: relative;
  text-align: left;
  display: flex;
  flex: 1 0 50%;

  ${media.mobile`
    flex: 1 0 100%;
  `} &.stack {
    flex-direction: column;
  }

  &.no-mobile {
    display: none;
    ${responsive.sm`
      display: inline-block;
    `}
  }

  &#footer_container_row-2_column-0 {
    ${responsive.lg`
      &#footer_container_row-2_column-0 {
        align-items: baseline;
      }
  `}
  }

  &#slim_footer_row-0_column-0 {
    order: 2;
    justify-content: space-between;
    ${responsive.sm`
    order: 1;
    `}
  }
  &#slim_footer_row-0_column-1 {
    order: 1;
    ${responsive.sm`
    order: 2;
    `};
  }
`;

const LogoColumn = styled(FooterColumn)`
  flex: 1 0 25%;
`;

const FooterTitle = styled.h3.attrs({
  id: "footer_container_row-0_column-0_title",
})`
  ${Font.circular}
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  font-weight: 500;
  color: ${Color.ritualBlue};
  letter-spacing: -0.2px;
  max-width: 300px;
  margin: 0 0 24px 0;

  ${responsive.sm`
    max-width: 280px;
    font-size: ${rem(26)};
    line-height: ${rem(36)};
    letter-spacing: -0.3px;
    margin: 0;
  `}
  ${responsive.md`
    max-width: 294px;
    margin-bottom: 32px;
  `}

  ${responsive.lg`
    max-width: 300px;
  `}

  &.slim_footer-title {
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    letter-spacing: 0px;
    margin: 0 0 24px 0;
    ${responsive.md`
      font-size: ${rem(22)};
      line-height: ${rem(32)};
      letter-spacing: -0.2px;
      margin: 0 0 32px 0;
    `}

  }

  &.footer_container_title-authenticated {
    margin: 0;
    ${responsive.sm`
      margin: 0;
    `}

  }

`;

export const SiteLink = styled.li`
  flex: 0 0 50%;
  margin-bottom: 16px;

  ${p => `order: ${p.mobilePosition};`}

  ${responsive.sm`
    flex: 0 0 33%;
    ${p => `order: ${p.desktopPosition};`}
  `};

  &.disabled-footer-link {
    pointer-events: none;
  }

  a {
    ${Font.circular};
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    font-weight: 500;
    color: ${Color.ritualBlue};
    transition: opacity 200ms ease-in-out;

    ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(22)};
  `};

    &:hover {
      opacity: 0.56;
    }
  }
`;

export default class Footer extends React.Component {
  renderFooterLinks(linkList) {
    return linkList.map((link, index) => {
      return (
        <SiteLink
          className={
            this.props.location.pathname === link.path
              ? "disabled-footer-link"
              : ""
          }
          key={`nav-link-${index}`}
          role="listitem"
        >
          {!link.disabled && (
            <MagicLink to={link.path} href={link.href}>
              {link.linkText}
            </MagicLink>
          )}
        </SiteLink>
      );
    });
  }

  generateShopLinks() {
    let links = [];

    links.push({
      path: `/vitamins-for-women`,
      linkText: intl.t("nav.links.shop-vitamins-women", "Women"),
    });
    links.push({
      path: `/vitamins-for-men`,
      linkText: intl.t("nav.links.shop-vitamins-men", "Men"),
    });
    links.push({
      path: `/vitamins-for-kids`,
      linkText: intl.t("nav.links.shop-vitamins-kids", "Kids"),
    });
    links.push({
      path: "/shop-bundles",
      linkText: intl.t("nav.links.shop-bundles", "Bundles"),
    });
    links.push({
      path: "/gift",
      linkText: intl.t("nav.links.shop-gift", "Gift Cards"),
    });

    return links;
  }

  render() {
    const { displaySlimFooter, showUSPNotice } = this.props;
    const authenticated = !!this.props.authenticated;

    const shopLinks = this.generateShopLinks();

    const aboutLinks = [
      {
        path: "/ingredients",
        linkText: intl.t("nav.links.ingredients", "Ingredients"),
      },
      {
        path: "/approach",
        linkText: intl.t("nav.links.approach", "Who We Are"),
      },
      {
        path: "/clinical-study",
        linkText: intl.t("nav.links.clinical-study", "Our Clinical Study"),
      },
      {
        path: "/articles",
        linkText: intl.t("nav.links.articles", "The Journal"),
      },
      {
        path: "/reviews",
        linkText: intl.t("nav.links.reviews", "Reviews"),
      },
    ];

    const moreLinks = [
      {
        href: "//start.ritual.com/careers",
        linkText: intl.t("nav.links.careers", "Careers"),
      },
      {
        path: "/press",
        linkText: intl.t("nav.links.press", "Press"),
      },
      {
        href: "//help.ritual.com",
        linkText: intl.t("nav.links.help", "Help"),
      },
    ];

    return (
      <FooterWrapper
        role="contentinfo"
        className={displaySlimFooter ? "slim_footer" : ""}
      >
        <FooterContainer>
          <h2 className="sr-only">Footer</h2>
          {displaySlimFooter ? (
            <FooterRow className="footer-top" id="slim_footer_row-0">
              <FooterColumn className="stack" id="slim_footer_row-0_column-0">
                <FooterLogo
                  id="footer_container_row-0_column-0_logo"
                  className="no-mobile slim_footer-logo"
                />
                <SocialList id="footer_container_row-0_column-0_social-list"></SocialList>
              </FooterColumn>
              <FooterColumn className="stack" id="slim_footer_row-0_column-1">
                <FooterTitle className="slim_footer-title">
                  <Text
                    id="footer.title-slim"
                    defaultMessage="We have high standards for emails too."
                  />
                </FooterTitle>
                <NewsletterForm />
              </FooterColumn>
            </FooterRow>
          ) : (
            <>
              {authenticated ? (
                <FooterRow className="footer-top" id="footer_container_row-0">
                  <FooterColumn id="footer_container_row-0_column-0">
                    <FooterTitle className="footer_container_title-authenticated">
                      <Text
                        id="footer.title-authenticated"
                        defaultMessage="Stay connected with us"
                      />
                    </FooterTitle>
                  </FooterColumn>
                  <FooterColumn
                    id="footer_container_row-0_column-1"
                    className="stack"
                  >
                    <SocialList id="footer_container_row-0_column-0_social-list-authenticated"></SocialList>
                  </FooterColumn>
                </FooterRow>
              ) : (
                <FooterRow className="footer-top" id="footer_container_row-0">
                  <FooterColumn id="footer_container_row-0_column-0">
                    <FooterTitle>
                      <Text
                        id="footer.title"
                        defaultMessage="We have high standards for emails too."
                      />
                    </FooterTitle>
                  </FooterColumn>
                  <FooterColumn
                    id="footer_container_row-0_column-1"
                    className="stack"
                  >
                    <NewsletterForm />
                    <SocialList id="footer_container_row-0_column-0_social-list"></SocialList>
                  </FooterColumn>
                </FooterRow>
              )}
              <FooterRow
                className="footer-shop-links"
                id="footer_container_row-1"
              >
                <LogoColumn
                  id="footer_container_row-1_column-0"
                  className="no-mobile"
                >
                  <FooterLogo
                    id="footer_container_row-1_column-0_logo"
                    className="no-mobile"
                  />
                </LogoColumn>
                <FooterLinkColumn
                  header={intl.t("footer.link-col-shop", "Shop")}
                  links={this.renderFooterLinks(shopLinks)}
                />
                <FooterLinkColumn
                  header={intl.t("footer.link-col-about", "About")}
                  links={this.renderFooterLinks(aboutLinks)}
                />
                <FooterLinkColumn
                  header={intl.t("footer.link-col-more", "More")}
                  links={this.renderFooterLinks(moreLinks)}
                />
              </FooterRow>
            </>
          )}

          <FooterRow
            id={`${displaySlimFooter ? "slim_footer_bottom" : "footer_bottom"}`}
            className="footer-legal"
          >
            <FooterColumn id="footer_container_row-2_column-0">
              <BBBLink />
              <CopyrightText />
            </FooterColumn>
            <FooterColumn
              id="footer_container_row-2_column-1"
              className="stack"
            >
              <DisclaimerText />
              {showUSPNotice && <USPNotice />}
              <FDAText />
            </FooterColumn>
          </FooterRow>
        </FooterContainer>
      </FooterWrapper>
    );
  }
}
