import React from "react";
import { StaticQuery, graphql } from "gatsby";

// Actions
import { initializeContentfulProductData } from "../../store/staticData/actions";
import { addPlansFromGraphQL } from "../../store/plan/actions";
import { addProductsFromGraphQL } from "../../store/product/actions";

const componentQuery = graphql`
  query initializeStoreQuery {
    allContentfulProduct {
      nodes {
        node_locale
        name {
          name
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        stockStatus
        sku
        slug
        price
        headerDescription
        productValueProps {
          content
        }
        shortDescription
        summary
        supplementFacts {
          nutrients {
            label
            dosage
            dv
          }
          ingredients {
            label
            dosage
            dv
          }
          notations {
            label
          }
          otherIngredients
          notationReferences
          attributions {
            label
          }
          simpleIngredients {
            name
            dosage
          }
        }
        heroFeaturedImages {
          title
          fluid(maxWidth: 280, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        alternateThumbnail {
          fixed(width: 204, height: 204, quality: 90) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          description
        }
        socialImage {
          file {
            url
          }
        }
      }
    }
    allProduct {
      nodes {
        id
        attributes
        relationships
      }
    }
    allPlan {
      nodes {
        id
        attributes
        relationships
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={componentQuery}
    render={data => {
      const { children, store } = props;
      const contentfulProductData = data.allContentfulProduct.nodes;
      const productData = data.allProduct.nodes;
      const planData = data.allPlan.nodes;

      store.dispatch(initializeContentfulProductData(contentfulProductData));
      store.dispatch(addProductsFromGraphQL(productData));
      store.dispatch(addPlansFromGraphQL(planData));

      return <>{children}</>;
    }}
  />
);
