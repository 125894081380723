// RitualLogos
import RitualLogo from "../../static/svg/logo.svg";
import BundlesLogo from "../../static/svg/icon-bundles-badge.svg";
// Icons
import StarFull from "../../static/svg/icon-star.svg";
import StarHalfYellow from "../../static/svg/icon-star-half-yellow.svg";
import StarThirdYellow from "../../static/svg/icon-star-third-yellow.svg";
import StarThreeQuartersYellow from "../../static/svg/icon-star-three-quarters-yellow.svg";
import CaretLeft from "../../static/svg/icon-caret-left.svg";
import CaretRight from "../../static/svg/icon-caret-right.svg";
import CaretDown from "../../static/svg/icon-caret-down.svg";
import Close from "../../static/svg/icon-close.svg";
import ArrowRight from "../../static/svg/arrow-right.svg";
import ArrowLeft from "../../static/svg/arrow-left.svg";
import ArrowLeftLarge from "../../static/svg/arrow-left-large.svg";
import ArrowRightLarge from "../../static/svg/arrow-right-large.svg";
import ArrowRoundedLeft from "../../static/svg/arrow-rounded-left.svg";
import ArrowRoundedRight from "../../static/svg/arrow-rounded-right.svg";
import ArrowRoundedUp from "../../static/svg/arrow-rounded-up.svg";
import Vegan from "../../static/svg/icon-vegan.svg";
import Gluten from "../../static/svg/icon-gluten.svg";
import Non from "../../static/svg/icon-non.svg";
import Fillers from "../../static/svg/icon-fillers.svg";
import Flag from "../../static/svg/icon-flag.svg";
import Clinical from "../../static/svg/icon-clinical.svg";
import Balloon from "../../static/svg/icon-balloon.svg";
import Recurring from "../../static/svg/icon-recurring.svg";
import Clock from "../../static/svg/icon-clock.svg";
import CircleStar from "../../static/svg/icon-circle-star.svg";
import Cancel from "../../static/svg/icon-cancel.svg";
import Account from "../../static/svg/icon-account.svg";
import Nonausea from "../../static/svg/icon-no-nausea.svg";
import Upvote from "../../static/svg/icon-yes.svg";
import Downvote from "../../static/svg/icon-no.svg";
import Check from "../../static/svg/icon-check.svg";
import CheckRounded from "../../static/svg/icon-good.svg";
import CheckYellow from "../../static/svg/icon-check-yellow.svg";
import Download from "../../static/svg/icon-download.svg";
import Play from "../../static/svg/icon-play.svg";
import Quote from "../../static/svg/icon-quote.svg";
import Study from "../../static/svg/icon-study.svg";
import Plus from "../../static/svg/icon-plus.svg";
import Minus from "../../static/svg/icon-minus.svg";
import Bad from "../../static/svg/icon-bad.svg";
import Info from "../../static/svg/icon-info.svg";
import Lock from "../../static/svg/icon-lock.svg";
import CaretDownRounded from "../../static/svg/caret-down-rounded.svg";
import CaretUpRounded from "../../static/svg/caret-up-rounded.svg";
import Hamburger from "../../static/svg/icon_hamburger.svg";
import BundlePackage from "../../static/svg/icon-bundle-package.svg";
import BundlePlan from "../../static/svg/icon-bundle-plan.svg";
import BundleDual from "../../static/svg/icon-bundle-dual.svg";
import Bottle from "../../static/svg/icon-bottle.svg";
import ArrowCurvedLg from "../../static/svg/arrow-curve-yellow-lg.svg";
import ArrowCurvedSm from "../../static/svg/arrow-curve-yellow-sm.svg";
import Peace from "../../static/svg/icon-peace.svg";
import Remove from "../../static/svg/icon-remove.svg";
import Email from "../../static/svg/icon-email.svg";
// Social
import Facebook from "../../static/svg/icon_facebook.svg";
import Instagram from "../../static/svg/icon_instagram.svg";
import Pinterest from "../../static/svg/icon_pinterest.svg";
import Twitter from "../../static/svg/icon_twitter.svg";
import YouTube from "../../static/svg/icon-youtube.svg";
// PressLogos
import NewYorkTimes from "../../static/svg/nyt.svg";
import Wired from "../../static/svg/wired.svg";
import Vogue from "../../static/svg/vogue.svg";
import Cnn from "../../static/svg/cnn.svg";
import Shape from "../../static/svg/shape.svg";
import Health from "../../static/svg/health.svg";
import Forbes from "../../static/svg/forbes.svg";
import Byrdie from "../../static/svg/byrdie.svg";
import Popsugar from "../../static/svg/popsugar.svg";
import CNBC from "../../static/svg/cnbc.svg";
// Health Logos
import LessIsMore from "../../static/svg/icon-less-is-more.svg";
import BioIdentical from "../../static/svg/icon-bio-identical.svg";
// Stickers
import Healthyish from "../../static/svg/sticker-healthyish.svg";
import Labelreader from "../../static/svg/sticker-label-reader.svg";
import Obsessed from "../../static/svg/sticker-obsessed.svg";
import Essentialforwomen from "../../static/svg/sticker-essential-for-women.svg";
import Essentialforwomen50 from "../../static/svg/sticker-essential-for-women.svg";
import Essentialprenatal from "../../static/svg/sticker-essential-prenatal.svg";
import Feeling100 from "../../static/svg/sticker-feeling-100.svg";
import Skeptic from "../../static/svg/sticker-skeptic.svg";
import Trophy from "../../static/svg/sticker-trophy.svg";
// Benefits
import Skin from "../../static/svg/icon-skin.svg";
import Bone from "../../static/svg/icon-bone.svg";
import Heart from "../../static/svg/icon-heart.svg";
import Immune from "../../static/svg/icon-immune.svg";
import Cognition from "../../static/svg/icon-cognition.svg";
import Brain from "../../static/svg/icon-brain.svg";
import Nerve from "../../static/svg/icon-nerve.svg";
import Energy from "../../static/svg/icon-energy.svg";
import Joint from "../../static/svg/icon-joint.svg";
import Hormone from "../../static/svg/icon-hormone.svg";
import Antioxidant from "../../static/svg/icon-antioxidant.svg";
import Aging from "../../static/svg/icon-aging.svg";
import Mood from "../../static/svg/icon-mood.svg";
import Calmness from "../../static/svg/icon-calmness.svg";
import Dna from "../../static/svg/icon-dna.svg";
import Blood from "../../static/svg/icon-blood.svg";
import Gene from "../../static/svg/icon-gene.svg";
import Thyroid from "../../static/svg/icon-thyroid.svg";
import Nervoussystem from "../../static/svg/icon-nervous-system.svg";
import Muscle from "../../static/svg/icon-muscle.svg";
import Vision from "../../static/svg/icon-vision.svg";
import Traceable from "../../static/svg/icon-traceable.svg";
// Shop Landing (used in Icons list)
import Delivered from "../../static/svg/icon-delivered.svg";
import Freeshipping from "../../static/svg/icon-freeshipping.svg";
import Goodstuff from "../../static/svg/icon-goodstuff.svg";
import Changedelivery from "../../static/svg/icon-changedelivery.svg";
import Cancellation from "../../static/svg/icon-cancellation.svg";
import Guarantee from "../../static/svg/icon-guarantee.svg";
// Clinical
import University from "../../static/svg/icon-university.svg";
import Blind from "../../static/svg/icon-blind.svg";
import Placebo from "../../static/svg/icon-placebo.svg";
import Weeks from "../../static/svg/icon-weeks.svg";
import Women from "../../static/svg/icon-women.svg";
import PlusNoBG from "../../static/svg/icon-plus-no-bg.svg";
// Payments
import ApplePay from "../../static/svg/icon-payment-apple-pay.svg";
import GooglePay from "../../static/svg/icon-payment-google-pay.svg";
import PayPal from "../../static/svg/icon-payment-pay-pal.svg";
import Visa from "../../static/svg/icon-payment-visa.svg";
import MasterCard from "../../static/svg/icon-payment-master-card.svg";
import Amex from "../../static/svg/icon-payment-amex.svg";
import Discover from "../../static/svg/icon-payment-discover.svg";
// Cart
import CartBagFull from "../../static/svg/icon-cart-full.svg";
import CartBagEmpty from "../../static/svg/icon-cart-empty.svg";
// BBB
import BBBLogo from "../../static/svg/BBB-Logo.svg";
// Flavors
import FlavorMint from "../../static/svg/flavor-mint.svg";
import FlavorCitrus from "../../static/svg/flavor-citrus.svg";
import FlavorCitrusBerry from "../../static/svg/flavor-citrus-berry.svg";
// Misc
import PoliciesBadge from "../../static/svg/policies-badge.svg";
// Value Props
import Tested from "../../static/svg/icon-tested.svg";
import Sugar from "../../static/svg/icon-sugar.svg";
import Flavor from "../../static/svg/icon-flavor.svg";

export const RitualLogos = {
  RitualLogo,
  BundlesLogo,
};

export const BBBLogos = {
  BBBLogo,
};

export const Icons = {
  StarFull,
  StarHalfYellow,
  StarThirdYellow,
  StarThreeQuartersYellow,
  CaretLeft,
  CaretRight,
  CaretDown,
  Close,
  ArrowRight,
  ArrowLeft,
  ArrowLeftLarge,
  ArrowRightLarge,
  ArrowRoundedLeft,
  ArrowRoundedRight,
  ArrowRoundedUp,
  Vegan,
  Gluten,
  Non,
  Fillers,
  Flag,
  Clinical,
  Balloon,
  Recurring,
  Clock,
  CircleStar,
  Cancel,
  Account,
  Nonausea,
  Gene,
  Upvote,
  Downvote,
  Check,
  CheckRounded,
  CheckYellow,
  Download,
  Play,
  Quote,
  Study,
  Plus,
  Minus,
  Bad,
  Delivered,
  Freeshipping,
  Goodstuff,
  Changedelivery,
  Cancellation,
  Guarantee,
  Info,
  PlusNoBG,
  Lock,
  CaretDownRounded,
  CaretUpRounded,
  Hamburger,
  BundlePackage,
  BundlePlan,
  BundleDual,
  Bottle,
  ArrowCurvedLg,
  ArrowCurvedSm,
  University,
  Traceable,
  Peace,
  FlavorMint,
  FlavorCitrus,
  FlavorCitrusBerry,
  PoliciesBadge,
  Remove,
  Tested,
  Sugar,
  Flavor,
  Email,
};

export const Social = {
  Facebook,
  Instagram,
  Pinterest,
  Twitter,
  YouTube,
};

export const PressLogos = {
  nyt: NewYorkTimes,
  wired: Wired,
  vogue: Vogue,
  cnn: Cnn,
  shape: Shape,
  health: Health,
  forbes: Forbes,
  byrdie: Byrdie,
  popsugar: Popsugar,
  cnbc: CNBC,
};

export const HealthIcons = {
  LessIsMore,
  BioIdentical,
};

export const Stickers = {
  Healthyish,
  Labelreader,
  Obsessed,
  Essentialforwomen,
  Essentialforwomen50,
  Essentialprenatal,
  Feeling100,
  Skeptic,
  Trophy,
};

export const BenefitsIcons = {
  Skin,
  Bone,
  Heart,
  Immune,
  Cognition,
  Brain,
  Nerve,
  Energy,
  Joint,
  Hormone,
  Antioxidant,
  Aging,
  Mood,
  Calmness,
  Dna,
  Blood,
  Gene,
  Thyroid,
  Nervoussystem,
  Muscle,
  Vision,
};

export const ClinicalIcons = {
  University,
  Blind,
  Placebo,
  Weeks,
  Women,
};

export const PaymentIcons = {
  ApplePay,
  GooglePay,
  PayPal,
  Visa,
  MasterCard,
  Amex,
  Discover,
};

export const CartIcons = {
  CartBagFull,
  CartBagEmpty,
};

export const FlavorIcons = {
  FlavorMint,
  FlavorCitrus,
  FlavorCitrusBerry,
};
