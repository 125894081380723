import CartProduct, { ModelType } from "./model";
import cartProductSelectors from "./selectors";

import normalizedActions from "../utils/normalized/actions";

export function removeCartProduct(id) {
  return dispatch => {
    return normalizedActions.removeModel(dispatch, ModelType, id);
  };
}

export function removeCartProducts(associations) {
  return (dispatch, getState) => {
    normalizedActions.removeModels(
      dispatch,
      getState().cartProducts,
      ModelType,
      associations,
    );
  };
}

export function addNormalizedCartProduct(data, associations) {
  return dispatch => {
    const cartProduct = new CartProduct();
    cartProduct.deserialize(data);
    dispatch(addCartProduct(cartProduct, associations));
  };
}

export function addCartProduct(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().cartProducts,
      ModelType,
      data,
      associations,
    );
  };
}
