import React from "react";
import styled from "styled-components";
import { Icons } from "../../../../utils/svg";

// Utils
import { rem, responsive } from "../../../../utils/style";

// Components
import Text from "../../../Text";

// Redux
import { useSelector } from "react-redux";
import subscriptionSelectors from "../../../../store/subscription/selectors";

const Messaging = styled.p`
  position: relative;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
  margin-bottom: 32px;
  max-width: 280px;

  ${responsive.md`
    max-width: 224px;
  `};

  ${responsive.lg`
    max-width: 270px;
    margin-bottom: 40px;
  `};

  svg {
    display: none;

    ${responsive.md`
      display: block;
      position: absolute;
      width: 21px;
      height: 48px;
      left: -32px;
      top: -32px;
      transform: scaleY(-1); /* CSS hack to flip image */
    `};
  }
`;

const BundleMessaging = () => {
  const hasActiveSubscription = useSelector(
    subscriptionSelectors.hasActiveSubscription,
  );

  if (!hasActiveSubscription) return null;

  return (
    <Messaging>
      <Text
        id="nav.side.bundle-message"
        defaultMessage="Turn your Ritual into a bundle and get a one-time discount"
      />
      <Icons.ArrowCurvedLg />
    </Messaging>
  );
};

export default BundleMessaging;
