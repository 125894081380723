import React from "react";
import styled from "styled-components";
import { Social } from "../../../utils/svg";
import { responsive, media, Color } from "../../../utils/style";
import intl from "../../../services/intl";

const SocialList = styled.ul`
  flex: 1 0 content;
  display: flex;
  padding: 0;
  margin: 24px 0 0;
  list-style: none;
  order: 2;
  &#footer_container_row-0_column-0_social-list-authenticated {
    margin: 0;
    ${responsive.sm`
      margin: 0;
    `}
  }

  ${responsive.sm`
    margin: 40px 0 0;
    order: 1
  `};

  li {
    position: relative;
    flex: 1 0 content;
    margin: 0 32px 0 0;

    &:last-child {
      margin: 0;
    }

    a {
      position: relative;
      display: block;
      width: 24px;
      height: 24px;

      svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;

        path {
          fill: ${Color.ritualBlue} !important;
          transition: opacity 200ms ease-in-out;
        }
      }

      &:hover {
        svg {
          path {
            opacity: 0.56;
          }
        }
      }
    }
  }
`;

const SocialListComponent = ({ id }) => {
  const list = [
    {
      href: "https://www.facebook.com/ritual",
      hrefLang: "x-default",
      target: "_blank",
      rel: "noopener noreferrer",
      title: intl.t("footer.social.facebook", "Ritual on Facebook"),
      icon: "Facebook",
    },
    {
      href: "https://www.instagram.com/ritual",
      hrefLang: "x-default",
      target: "_blank",
      rel: "noopener noreferrer",
      title: intl.t("footer.social.instagram", "Ritual on Instagram"),
      icon: "Instagram",
    },
    {
      href: "https://www.pinterest.com/MyRitual",
      hrefLang: "x-default",
      target: "_blank",
      rel: "noopener noreferrer",
      title: intl.t("footer.social.pinterest", "Ritual on Pinterest"),
      icon: "Pinterest",
    },
    {
      href: "https://twitter.com/ritual",
      hrefLang: "x-default",
      target: "_blank",
      rel: "noopener noreferrer",
      title: intl.t("footer.social.twitter", "Ritual on Twitter"),
      icon: "Twitter",
    },
    {
      href: "https://www.youtube.com/channel/UCDwWe38QlX_tFczjUC9gl6A",
      hrefLang: "x-default",
      target: "_blank",
      rel: "noopener noreferrer",
      title: intl.t("footer.social.youtube", "Ritual on YouTube"),
      icon: "YouTube",
    },
  ];
  return (
    <SocialList id={id}>
      {list.map(link => {
        let SocialSVG;
        switch (link.icon) {
          case "Facebook":
            SocialSVG = <Social.Facebook />;
            break;
          case "Instagram":
            SocialSVG = <Social.Instagram />;
            break;
          case "Pinterest":
            SocialSVG = <Social.Pinterest />;
            break;
          case "Twitter":
            SocialSVG = <Social.Twitter />;
            break;
          case "YouTube":
            SocialSVG = <Social.YouTube />;
            break;
          default:
            break;
        }
        return (
          <li key={link.href}>
            <a
              href={link.href}
              hrefLang={link.hrefLang}
              target={link.target}
              rel={link.rel}
              title={link.title}
            >
              {SocialSVG}
            </a>
          </li>
        );
      })}
    </SocialList>
  );
};

export default SocialListComponent;
