import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "PROMOTION";

export default class Promotion extends Model {
  // Attributes
  name: string = "";
  type: string = "";
  displayName: string = "";
  currency: string = "";
  amountOff: number = 0;
  percentOff: number = 0;
  creditAmount: number = 0;

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);
  }
}
